import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { useDynamicSelector } from "@services/redux";
import { get_disciplines_query } from "@services/graphql/master_data";

const RegisterDiscipline = ({ discipline_ids }) => {
  const { t } = useTranslation();
  const [disciplines, set_disciplines] = useState([]);
  let get_discipline_list = useAPIRequest(
    "get_disciplines",
    get_disciplines_query
  );
  const { items: discipline_list } = useDynamicSelector("get_disciplines");
  useEffect(() => {
    get_discipline_list({}, true);
  }, []);
  useEffect(() => {
    if (discipline_ids?.length && discipline_list?.length) {
      set_disciplines(
        discipline_list?.filter((x) => discipline_ids.includes(x.id))
      );
    }
  }, [discipline_list, discipline_ids]);
  return (
    <div>
      <Form.Item
        name={["player", "discipline_ids"]}
        label={t("discipline")}
        rules={[{ required: true, message: t("discipline_required") }]}
      >
        <Select
          options={disciplines}
          fieldNames={{ label: "name", value: "id" }}
          filterOption={(input, option) =>
            option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
          allowClear
          maxCount={1}
          // mode="multiple"
        />
      </Form.Item>
    </div>
  );
};

export default RegisterDiscipline;
