import { Button, Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PlayerRegisterSuccessModal = ({ set_is_modal_open, is_modal_open }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleOk = () => {
    set_is_modal_open(false);
    navigate("/cmtrophy/officer/player");
  };

  return (
    <Modal
      open={is_modal_open}
      onOk={handleOk}
      footer={null}
      width={800}
      closeIcon={false}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          fontSize: "16px",
          color: "green",
        }}
      >
        {t("registered_successfully")}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          fontWeight: "bold",
          padding: "10px",
          fontSize: "20px",
        }}
      >
        {t("Important Notification")}
      </div>
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <span>{t("register_success_content_1")}</span>
        <span>{t("register_success_content_2")}</span>
        <span>{t("register_success_content_3")}</span>
        <span>{t("register_success_content_4")}</span>
        <span>{t("register_success_content_5")}</span>
        <span>{t("register_success_content_6")}</span>
        <span>{t("register_success_content_7")}</span>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          padding: "6px",
          marginRight: "10px",
        }}
      >
        <Button onClick={handleOk} type="primary">
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default PlayerRegisterSuccessModal;
