export const ProjectName = "CM Trophy 2024";

export const colorCode = [
  "linear-gradient(226deg, rgb(67, 229, 149) 0%, rgb(61, 178, 183) 82%)",
  "linear-gradient(48deg, rgba(204, 131, 217, 0.84) 0%, rgb(149, 38, 203) 74%)",
  "linear-gradient(48deg, rgba(3, 219, 241, 0.67) 0%, rgba(63, 81, 181, 0.92) 74%)",
  "linear-gradient(224deg, rgb(247, 151, 30), rgb(255, 210, 0))",
  "linear-gradient(220deg, rgb(234, 83, 88) 12%, rgb(250, 230, 183) 100%)",
];

export const event_round_options = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
];

export const gender_options = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const participant_options = [
  {
    label: "Participant",
    value: true,
  },
  {
    label: "Not Participant",
    value: false,
  },
];
