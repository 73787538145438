import { remove_item } from "@helpers/functions";
import OfficerLoginForm from "@views/components/login/officer_login_form";
import React from "react";

const OfficerLogin = () => {
  remove_item("mobile");
  return (
    <div className="login-page-main-container">
      <OfficerLoginForm />
    </div>
  );
};

export default OfficerLogin;
