import { gql } from "@apollo/client";

export const get_otp_for_forgot_password_query = gql`

query get_otp_for_forgot_password(
$mobile: String!
){get_otp_for_forgot_password(mobile:$mobile){
  id
  status
  error{
    status
    message
  }
}}

`

export const forgot_password_mutation = gql`

mutation forgot_password($
    mobile: String!
    $otp: String!
    $password: String!
    ){forgot_password(mobile:$mobile otp:$otp password:$password){
      id
      status
      error{
        status
        message
      }
    }}
      `