import React from "react";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const RegisterName = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name={["name"]}
        label={t("name")}
        rules={[{ required: true, message: t("name_required") }]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};

export default RegisterName;
