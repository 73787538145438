import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { useDynamicSelector } from "@services/redux";
import { get_differently_abled_list_query } from "@services/graphql/master_data";

const RegisterDifferentlyAbled = () => {
  const { t } = useTranslation();
  let get_differently_abled_list = useAPIRequest(
    "get_differently_abled_list",
    get_differently_abled_list_query
  );
  const { items: differently_abled_list } = useDynamicSelector(
    "get_differently_abled_list"
  );
  useEffect(() => {
    get_differently_abled_list({}, true);
  }, []);
  return (
    <div>
      <Form.Item
        name={["player", "differently_abled_id"]}
        label={t("differently_abled")}
        rules={[{ required: true, message: t("differently_abled_required") }]}
      >
        <Select
          options={differently_abled_list}
          fieldNames={{ label: "name", value: "id" }}
          filterOption={(input, option) =>
            option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        />
      </Form.Item>
    </div>
  );
};

export default RegisterDifferentlyAbled;
