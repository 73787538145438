import React, { useEffect, useState } from "react";
import { Col, Form } from "antd";
import RegisterDifferentlyAbled from "./register_differently_abled";
import RegisterEvent from "./register_event";
import RegisterDiscipline from "./register_discipline";
import RegisterCategory from "./register_category";
import RegisterAadharNo from "./register_aadhar_no";
import RegisterGender from "./register_gender";
import { useDynamicSelector } from "@services/redux";
import { useAPIRequest } from "@helpers/hooks";
import { get_category_event_query } from "@services/graphql/master_data";
import { useTranslation } from "react-i18next";

const Events = ({
  category_id,
  gender,
  discipline,
  differently_abled,
  set_events,
}) => {
  const { t } = useTranslation();
  const [discipline_ids, set_discipline_ids] = useState([]);
  const [event_ids, set_event_ids] = useState([]);
  const [category_ids, set_category_ids] = useState([]);
  let get_category_list = useAPIRequest(
    "get_category_events",
    get_category_event_query
  );

  const { items: category_event_list } = useDynamicSelector(
    "get_category_events"
  );

  useEffect(() => {
    get_category_list({}, true);
  }, []);

  useEffect(() => {
    if (category_event_list?.length) {
      if (gender) {
        let ids = [];
        category_event_list.forEach((x) => {
          if (x.gender === gender) ids.push(x.category_id);
        });
        set_category_ids(ids);
      } else {
        set_category_ids([]);
      }
      if (gender && category_id === "differently_abled") {
        if (differently_abled) {
          let ids = [];
          category_event_list.forEach((x) => {
            if (
              x.gender === gender &&
              x.category_id === category_id &&
              x.differently_abled_id === differently_abled
            )
              ids.push(x.discipline_id);
          });
          set_discipline_ids(ids);
        } else set_discipline_ids([]);
      } else if (gender && category_id) {
        let ids = [];
        category_event_list.forEach((x) => {
          if (x.gender === gender && x.category_id === category_id)
            ids.push(x.discipline_id);
        });
        set_discipline_ids(ids);
      } else {
        set_discipline_ids([]);
      }

      if (gender && differently_abled && discipline?.length) {
        let ids = [];
        category_event_list.forEach((x) => {
          if (
            x.gender === gender &&
            x.differently_abled_id === differently_abled &&
            discipline?.includes(x.discipline_id)
          )
            ids.push(x.event_id);
        });
        set_event_ids(ids);
      } else if (gender && category_id && discipline?.length) {
        let ids = [];
        category_event_list.forEach((x) => {
          if (
            x.gender === gender &&
            x.category_id === category_id &&
            discipline?.includes(x.discipline_id)
          )
            ids.push(x.event_id);
        });
        set_event_ids(ids);
      } else {
        set_event_ids([]);
      }
    }
  }, [category_id, gender, discipline, differently_abled, category_event_list]);

  return (
    <>
      <Col xs={24} sm={12}>
        <RegisterGender />
      </Col>
      <Col xs={24} sm={12}>
        <RegisterAadharNo />
      </Col>
      <Col xs={24} sm={12}>
        <RegisterCategory category_ids={category_ids} />
      </Col>
      {category_id === "differently_abled" && (
        <>
          <Col xs={24} sm={12}>
            <RegisterDifferentlyAbled />
          </Col>
          {/* <Col xs={0} sm={12}></Col> */}
        </>
      )}
      <Col xs={24} sm={12}>
        <RegisterDiscipline discipline_ids={discipline_ids} />
      </Col>
      <Col xs={24} sm={12}>
        <Form.Item
          name={["player", "event_ids"]}
          label={t("event")}
          rules={[{ required: true, message: t("event_required") }]}
        >
          <RegisterEvent
            event_ids={event_ids}
            category_id={category_id}
            discipline_ids={discipline_ids}
            discipline={discipline}
            set_events={set_events}
          />
        </Form.Item>
      </Col>
    </>
  );
};

export default Events;
