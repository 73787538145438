import React from "react";
import TopHeader from "./header/top_header";
import SecondContainer from "./header/second_container";

const OfficerHeader = () => {
  return (
    <div>
      <TopHeader />
      <SecondContainer />
    </div>
  );
};

export default OfficerHeader;
