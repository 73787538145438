import { remove_item } from "@helpers/functions";
import PlayerLoginForm from "@views/components/login/player_login_form";
import React from "react";

const PlayerLogin = () => {
  remove_item("mobile");
  return (
    <div className="login-page-main-container">
      <PlayerLoginForm />
    </div>
  );
};

export default PlayerLogin;
