import React, { useEffect, useState } from "react";
import { Modal, Pagination, Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import { useAPIRequest } from "@helpers/hooks";
import {
  delete_event_schedule,
  get_event_schedule_query,
} from "@services/graphql";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { retrieve_item } from "@helpers/functions";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const EventScheduleTable = ({
  set_modal_type,
  set_is_event_schedule_modal_open,
  set_selected_schedule_event_id,
  set_page_limit,
  set_page_number,
  page_number,
  page_limit,
  eventRegisteredPlayers,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session_id = retrieve_item("session_id");
  const navigate = useNavigate();
  const [delete_schedule_event, set_delete_schedule_event] = useState({});
  const [delete_schedule_event_modal, set_delete_schedule_event_modal] =
    useState(false);
  const {
    items: scheduled_event_list,
    loading: scheduled_event_list_loading,
    pagination,
  } = useDynamicSelector("getEventSchedules");
  const { status: delete_status, loading: delete_loading } = useDynamicSelector(
    "deleteEventSchedule"
  );

  const get_one_event_schedule_request = useAPIRequest(
    "getEventSchedule",
    get_event_schedule_query
  );
  const delete_one_event_schedule_request = useAPIRequest(
    "deleteEventSchedule",
    delete_event_schedule
  );

  const get_one_event_schedule = (id) => {
    get_one_event_schedule_request(
      {
        json: {
          id: id,
        },
      },
      true
    );
  };

  const handle_edit_click = (id) => {
    set_selected_schedule_event_id(id);
    set_modal_type("edit");
    set_is_event_schedule_modal_open(true);
    get_one_event_schedule(id);
  };
  const handle_delete_click = (value) => {
    set_delete_schedule_event(value);
    set_delete_schedule_event_modal(true);
  };
  const handle_close = () => {
    set_delete_schedule_event({});
    set_delete_schedule_event_modal(false);
  };
  const handleWinnersIndividual = (
    value,
    event_schedule_line_item_id,
    event_id
  ) => {
    console.log(
      "handleWinnersIndividual",
      value,
      event_schedule_line_item_id,
      event_id
    );

    navigate(
      `/cmtrophy/officer/schedules-individual/${value?.id}/${event_schedule_line_item_id}/${event_id}/individual`,
      {
        state: [value],
      }
    );
  };

  const handleWinnersTeam = (value, event_schedule_line_item_id, event_id) => {
    console.log(
      "handleWinnersTeam",
      value,
      event_schedule_line_item_id,
      event_id
    );
    navigate(
      `/cmtrophy/officer/schedules/${value.id}/${event_schedule_line_item_id}/${event_id}`,
      {
        state: [value],
      }
    );
  };
  const handle_delete_confirmation = () => {
    delete_one_event_schedule_request({
      json: { id: delete_schedule_event?.id },
    });
  };

  useEffect(() => {
    if (delete_status === "success") {
      toast.success(t("event_schedule_delete_successfully"));
      handle_close();
      dispatch(dynamic_clear("deleteEventSchedule"));
    }
  }, [delete_status]);

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return (page_number - 1) * page_limit + index + 1;
      },
    },
    {
      title: t("Schedule Name"),
      dataIndex: "name",
      key: "name",
      align: "left",
      width: 250,
    },
    {
      title: t("Date"),
      dataIndex: "date_time",
      key: "date_time",
      align: "left",
      render: (text) => moment(parseInt(text)).format("DD-MM-YYYY - hh.mm A"),
      width: 250,
    },
    {
      title: t("Place"),
      dataIndex: ["event_venue", "name"],
      key: "event_venue",
      align: "left",
      width: 250,
    },
    {
      title: t("Round"),
      dataIndex: ["round", "name"],
      key: "round",
      align: "left",
      width: 90,
    },
    {
      title: t("Level"),
      dataIndex: ["level", "name"],
      key: "level",
      align: "left",
      width: 90,
    },
    {
      title: t("Discipline"),
      key: "discipline_name",
      align: "left",
      width: 150,
      render: (_, record) => {
        console.log("uniqueArray", record.event_schedule_line_items);

        const disciplines = record.event_schedule_line_items?.map(
          (item) => item.event?.discipline?.name
        );

        return disciplines ? disciplines.join(", ") : "";
      },
    },
    {
      title: t("Events"),
      key: "events",
      align: "left",
      width: 150,
      render: (_, record) => {
        const events = record.event_schedule_line_items?.map(
          (item) => item.event?.name
        );

        return events ? (
          <span>
            {record.event_schedule_line_items.map((item, index) => (
              <Tag
                color="blue"
                key={index}
                style={{ cursor: "pointer" }}
                // onClick={() =>
                //   item?.event?.type === "individual"
                //     ? handleWinnersIndividual(record, item?.id, item?.event.id)
                //     : handleWinnersTeam(record, item.id, item?.event?.id)
                // }
              >
                {item?.event?.name}
              </Tag>
            ))}
          </span>
        ) : (
          ""
        );
      },
    },
    {
      title: t("Actions"),
      key: "actions",
      align: "center",
      width: 100,

      render: (_, record) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <AiOutlineEdit
            size={20}
            color="#158d94"
            title={t("View Event Venue")}
            style={{ marginRight: 8, cursor: "pointer" }}
            onClick={() => handle_edit_click(record?.id)}
          />
          <AiOutlineDelete
            size={20}
            color="red"
            title={t("Delete Event Venue")}
            onClick={() => handle_delete_click(record)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    },
  ];

  const handle_event_click = (val) => {
    console.log("handleEventClick", val);
  };

  const columns_user = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return (page_number - 1) * page_limit + index + 1;
      },
    },
    {
      title: t("Schedule Name"),
      dataIndex: "name",
      key: "name",
      align: "left",
      width: 250,
    },
    {
      title: t("Date"),
      dataIndex: "date_time",
      key: "date_time",
      align: "left",
      render: (text) => moment(parseInt(text)).format("DD-MM-YYYY - hh.mm A"),
      width: 250,
    },
    {
      title: t("Place"),
      dataIndex: ["event_venue", "name"],
      key: "event_venue",
      align: "left",
      width: 250,
    },
    {
      title: t("Round"),
      dataIndex: ["round", "name"],
      key: "round",
      align: "left",
      width: 90,
    },
    {
      title: t("Level"),
      dataIndex: ["level", "name"],
      key: "level",
      align: "left",
      width: 90,
    },
    {
      title: t("Discipline"),
      key: "discipline_name",
      align: "left",
      width: 150,
      render: (_, record) => {
        const disciplines = record.event_schedule_line_items?.map(
          (item) => item.event?.discipline?.name
        );
        return disciplines ? disciplines.join(", ") : "";
      },
    },
    {
      title: t("Events"),
      key: "events",
      align: "left",
      width: 150,
      render: (_, record) => {
        const events = record.event_schedule_line_items?.map(
          (item) => item.event?.name
        );

        return events ? (
          <span>
            {events.map((event, index) => (
              <Tag color="blue" key={index}>
                {event}
              </Tag>
            ))}
          </span>
        ) : (
          ""
        );
      },
    },
  ];

  const fetchData = (val, value) => {
    set_page_number(val);
    set_page_limit(value);
  };

  return (
    <>
      <Table
        columns={session_id ? columns : columns_user}
        dataSource={scheduled_event_list}
        pagination={false}
        onChange={(pagination) => {
          set_page_number(pagination.current);
          set_page_limit(pagination.pageSize);
        }}
        rowClassName={(record, index) => (index % 2 === 0 ? "even-row" : "")}
        className="custom-table-header"
        bordered
        loading={scheduled_event_list_loading}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Pagination
          total={pagination?.total_count}
          showSizeChanger={true}
          current={page_number}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </div>
      <Modal
        title={t("event_schedule_delete_confirmation")}
        onCancel={handle_close}
        onOk={handle_delete_confirmation}
        okButtonProps={{ loading: delete_loading }}
        open={delete_schedule_event_modal}
      >
        <span>
          Do you want to delete the <b></b> Event schedule ?
        </span>
      </Modal>
    </>
  );
};

export default EventScheduleTable;
