import { useAPIRequest } from "@helpers/hooks";
import {
  get_report_request_query,
  query_get_all_report_request,
} from "@services/graphql/report";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { handle_player_report } from "@views/components/report/report_function";
import { Button, Col, Row, Spin, Table, Tag } from "antd";
import { startCase } from "lodash";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { useTranslation } from "react-i18next";
import { RiFileExcel2Fill } from "react-icons/ri";
import { useDispatch } from "react-redux";
import * as XLSX from "xlsx";

const Report = () => {
  const { t } = useTranslation();
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const [record_download_id, set_record_download_id] = useState(false);
  const get_player_details = useDynamicSelector("get_report_request");
  const { loading: get_player_details_loading } =
    useDynamicSelector("get_report_request");
  const get_report_request = useAPIRequest(
    "get_report_request",
    get_report_request_query
  );
  const get_reports_request = useAPIRequest(
    "get_all_report_requests",
    query_get_all_report_request
  );
  const { items: get_report_list, loading: get_report_list_loading } =
    useDynamicSelector("get_all_report_requests");

  const handle_click = (record) => {
    set_record_download_id(record?.id);
    get_report_request({ id: record?.id }, true);
  };

  const handleDownloadReport = (type, fn, dl, excelData) => {
    var elt = document.getElementById("team-report");
    var wb = XLSX.utils.table_to_book(elt, { sheet: "survey data" });

    var ws = wb.Sheets[wb.SheetNames[0]];

    for (var cell in ws) {
      if (ws.hasOwnProperty(cell)) {
        if (cell[0] === "!" || cell[0] === "_") continue;

        ws[cell].s = {
          alignment: { horizontal: "center" },
        };

        excelData.forEach((report) => {
          report.answers.forEach((x) => {
            if (ws[cell].v === x.unique_id) {
              ws[cell].t = "s";
              ws[cell].v = "'" + x.unique_id.toFixed(2).toString();
            }
          });
        });
      }
    }

    return dl
      ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
      : XLSX.writeFile(wb, fn || `Report.` + (type || "xlsx"));
  };

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Team_report",
    sheet: "Users",
    excelData: [],
    handleDownload: handleDownloadReport,
  });
  let report_keys = {
    get_all_players_report: "Player wise report",
    get_all_team_report: "Team wise report",
  };

  const columns = [
    {
      title: t("date"),
      dataIndex: "requested_datetime",
      key: "name",
      align: "left",
      render: (value) => moment(Number(value)).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: t("type"),
      dataIndex: "report_name",
      key: "report_name",
      render: (value) => report_keys[value],
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (value) => (
        <Tag
          style={{
            backgroundColor: value === "completed" ? "green" : "#C7253E",
            color: "white",
          }}
        >
          {startCase(value === "completed" ? value : "pending")}
        </Tag>
      ),
    },
    {
      title: t("actions"),
      dataIndex: "",
      key: "status",
      render: (record) =>
        record_download_id === record?.id && get_player_details_loading ? (
          <Spin />
        ) : record.status === "completed" ? (
          <RiFileExcel2Fill
            onClick={() => handle_click(record)}
            style={{ cursor: "pointer", color: "green" }}
            size={20}
          />
        ) : null,
    },
  ];

  useEffect(() => {
    get_reports_request({}, true);
  }, []);

  useEffect(() => {
    if (get_player_details?.response) {
      window.open(get_player_details?.response, "_blank");
      dispatch(dynamic_clear("get_report_request"));
    }
  }, [get_player_details]);

  const handle_refresh = () => {
    get_reports_request({}, true);
  };

  return (
    <div style={{ padding: "20px" }}>
      <div>
        <h2 className="registered_player_title">{t("reports")}</h2>
      </div>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Row justify={"end"}>
          <Col>
            <Button
              onClick={handle_refresh}
              loading={get_report_list_loading}
              type="primary"
            >
              {t("refresh")}
            </Button>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={
            Array.isArray(get_report_list)
              ? [...get_report_list].sort(
                  (a, b) =>
                    Number(b?.requested_datetime) -
                    Number(a?.requested_datetime)
                )
              : []
          }
          loading={get_report_list_loading}
          className="custom-table"
        />
      </div>
      {/* <Row justify={"center"} style={{ marginTop: "30px" }}>
        <Col>
          <CategoryWiseReport />
        </Col>
      </Row> */}
      {/* <div ref={tableRef} style={{ display: "none" }}>
        <table
          id="team-report"
          bordered
          className="table-style"
          style={{
            border: "1px solid black",
            borderCollapse: "collapse",
            width: "100%",
            overflow: "auto",
          }}
        >
          <thead className="table-style">
            <tr className="table-style">
              <th className="table-style">Sl.no</th>
              <th className="table-style">Team Name</th>
              <th className="table-style">Team Code</th>
              <th className="table-style">Gender</th>
              <th className="table-style">Event</th>
              <th className="table-style">District</th>
              <th className="table-style">Category</th>
            </tr>
          </thead>
          <tbody className="table-style">
            {get_player_details?.report_name === "get_all_team_report" &&
              [...JSON.parse(get_player_details?.response)]?.map(
                (team, index) => (
                  <React.Fragment key={team?.team_code || `team-${index}`}>
                    {index !== 0 && <tr></tr>}

                    <tr className="table-style">
                      <td
                        className="table-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="table-style"
                        style={{ fontWeight: "bold" }}
                      >
                        {team?.name}
                      </td>
                      <td className="table-style">{team?.team_code}</td>
                      <td className="table-style">{startCase(team?.gender)}</td>
                      <td className="table-style">{team?.event?.name}</td>
                      <td className="table-style">{team?.district?.name}</td>
                      <td className="table-style">{team?.category?.name}</td>
                    </tr>
                    <tr className="table-style">
                      <th className="table-style"></th>
                      <th className="table-style">Sl.no</th>
                      <th className="table-style">Player Name</th>
                      <th className="table-style">Player Code</th>
                      <th className="table-style">Aadhar No</th>
                      <th className="table-style">Mobile</th>
                      <th className="table-style">DOB</th>
                    </tr>
                    {team?.players?.map((player, playerIndex) => (
                      <tr className="table-style" key={player?.id}>
                        <td className="table-style"></td>
                        <td className="table-style">{playerIndex + 1}</td>
                        <td className="table-style">{player?.name}</td>
                        <td className="table-style">{player?.player_code}</td>
                        <td className="table-style">{player?.aadhar_no}</td>
                        <td className="table-style">{player?.mobile}</td>
                        <td className="table-style">{player?.dob}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                )
              )}
          </tbody>
        </table>
      </div> */}
    </div>
  );
};

export default Report;
