import { gql } from "@apollo/client";

export const get_districts_query = gql`
  query get_districts {
    get_districts {
      items {
        id
        name
        code
        zone_id
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_disciplines_query = gql`
  query get_disciplines {
    get_disciplines {
      items {
        id
        name
        code
        discipline_type
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_categories_query = gql`
  query get_categories {
    get_categories {
      items {
        id
        name
        code
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_category_event_query = gql`
  query get_category_events {
    get_category_events {
      items {
        id
        discipline_id
        event_id
        category_id
        differently_abled_id
        gender
        min_allowed_participants
        max_allowed_participants
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_differently_abled_list_query = gql`
  query get_differently_abled_list {
    get_differently_abled_list {
      items {
        id
        name
        code
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_events_query = gql`
  query get_events(
    $discipline_id: String
    $gender: String
    $differently_abled_id: String
    $category_id: String
  ) {
    get_events(
      discipline_id: $discipline_id
      gender: $gender
      category_id: $category_id
      differently_abled_id: $differently_abled_id
    ) {
      items {
        id
        name
        code
        discipline_id
        type
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_category_team_filter_query = gql`
  query get_categories {
    get_category_team_filter: get_categories {
      items {
        id
        name
        code
      }
      error {
        message
        status
      }
    }
  }
`;
