import { useAPIRequest } from "@helpers/hooks";
import { get_events_schedule_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleEventsFormItem = ({ form }) => {
  const { Option } = Select;

  const schedule_discipline_id = Form.useWatch("discipline", form);
  const schedule_gender = Form.useWatch("gender", form);
  const schedule_category_id = Form.useWatch("category", form);
  const schedule_differently_abled = Form.useWatch("differently_abled", form);

  const { items: event_schedule_list } = useDynamicSelector(
    "get_events_schedule"
  );
  console.log("event_schedule_list", event_schedule_list);

  console.log("schedule_discipline_id", schedule_gender, schedule_category_id);
  const get_events_schedule_request = useAPIRequest(
    "get_events_schedule",
    get_events_schedule_query
  );

  useEffect(() => {
    if (schedule_discipline_id && schedule_gender && schedule_category_id) {
      get_events_schedule_request(
        {
          discipline_id: schedule_discipline_id,
          gender: schedule_gender,
          category_id: schedule_category_id,
          differently_abled_id: schedule_differently_abled,
        },
        true
      );
    }
  }, [
    schedule_discipline_id,
    schedule_gender,
    schedule_category_id,
    schedule_differently_abled,
  ]);
  const { t } = useTranslation();
  return (
    <Col span={12}>
      <Form.Item
        name="events"
        label={t("விளையாட்டு போட்டிகள் / Events")}
        rules={[{ required: true, message: t("Please enter events") }]}
      >
        <Select placeholder={t("Select category")} mode="multiple" allowClear>
          {event_schedule_list?.map((event, index) => (
            <Option value={event?.id} key={index}>
              {event?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleEventsFormItem;
