import { Col, Form, Select } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EventScheduleGenderFormItem = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  return (
    <Col span={12}>
      <Form.Item
        name="gender"
        label={t("gender")}
        rules={[{ required: true, message: t("Please select gender") }]}
      >
        <Select placeholder={t("Select gender")} allowClear>
          <Option value="male">{t("Male")}</Option>
          <Option value="female">{t("Female")}</Option>
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleGenderFormItem;
