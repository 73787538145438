import { remove_item } from "@helpers/functions";
import { dynamic_clear } from "@services/redux";
import HomeAward from "@views/components/home/home_award";
import HomeMinisterContent from "@views/components/home/home_minister_content";
import HomeSlider from "@views/components/home/home_slider";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(dynamic_clear("get_registered_user"));
  }, []);

  return (
    <div>
      <HomeSlider />
      {/* <HomeInformation /> */}
      <HomeMinisterContent />
      <HomeAward />
    </div>
  );
};

export default Home;
