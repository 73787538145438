import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { store_item } from "@helpers/functions";
import toast from "react-hot-toast";
import { officer_login_query } from "@services/graphql";

const OfficerLoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let login_request = useAPIRequest("officer_login", officer_login_query);
  const { status, error, loading, ...rest } =
    useDynamicSelector("officer_login");
  const onFinish = (values) => {
    login_request(
      {
        username: String(values?.username),
        password: String(values?.password),
      },
      true
    );
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if (rest?.session_id) {
      store_item("login_data", rest);
      store_item("name", rest.name);
      store_item("session_id", rest.session_id);
      store_item("role", rest.roles?.[0]?.name);
      store_item("officer_id", rest.officer?.id);
      store_item("zone_id", rest.officer?.zone_id);
      store_item("district_id", rest.officer?.district_id);

      navigate("/cmtrophy/officer/dashboard");
      dispatch(dynamic_clear("login"));
    } else if (error?.message) {
      toast.error(error?.message);
      dispatch(dynamic_clear("login"));
    }
  }, [rest, navigate, error]);

  return (
    <div className="login-container">
      <div className="login-box">
        <h4>அதிகாரி உள்நுழைவு</h4>
        <h4 className="login-title"> Officer Login</h4>
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label="பயனர் பெயர் / Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="கடவுச்சொல் / Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-button"
              loading={loading}
            >
              உள்நுழைய / Login
            </Button>
          </Form.Item>
        </Form>
        {/* <div className="forgot-password">
          <a onClick={handle_redirect}>
            கடவுச்சொல்லை மறந்துவிட்டீர்களா? / Forgot Password?
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default OfficerLoginForm;
