import React from "react";
import { Select } from "antd";
import { useDynamicSelector } from "@services/redux";

const UpdateRegisterEvent = ({
  event_ids,
  category_id,
  onChange,
  discipline_ids,
  discipline,
  set_events,
}) => {
  const { items: event_list } = useDynamicSelector("get_events");
  const { min_allowed_participants, max_allowed_participants } =
    useDynamicSelector("get_min_max_partcipants_count");

  const handle_event_select = (selected_event_ids) => {
    // Filter the selected events based on the selected IDs
    const selected_events = event_list?.filter((event) =>
      selected_event_ids?.includes(event?.id)
    );
    // Update the state with selected events
    set_events(selected_events);
    // Notify the parent component of the selection change
    onChange(selected_event_ids);
  };

  return max_allowed_participants === "1" &&
    !discipline_ids.includes("athletics") ? (
    <Select
      options={event_list?.map((event) => ({
        label: event.name,
        value: event.id,
      }))}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      allowClear
      maxTagCount={1}
      onChange={handle_event_select} // Handle the selection change
      value={event_ids} // Prefill selected event IDs
    />
  ) : (
    <Select
      options={event_list?.map((event) => ({
        label: event.name,
        value: event.id,
      }))}
      filterOption={(input, option) =>
        option.label.toLowerCase().includes(input.toLowerCase())
      }
      allowClear
      maxCount={
        (category_id === "school" || category_id === "college") &&
        (discipline_ids.includes("swimming") ||
          discipline_ids.includes("athletics"))
          ? 2
          : undefined
      }
      mode="multiple" // Allow multiple selection
      onChange={handle_event_select} // Handle the selection change
      value={event_ids} // Prefill selected event IDs
    />
  );
};

export default UpdateRegisterEvent;
