import { useAPIRequest } from "@helpers/hooks";
import { get_rounds_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleRoundFormItem = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const { items: round_list } = useDynamicSelector("getRounds");

  const get_rounds_request = useAPIRequest("getRounds", get_rounds_query);

  useEffect(() => {
    get_rounds_request({}, true);
  }, []);

  return (
    <Col span={12}>
      <Form.Item
        name="round"
        label={t("சுற்று / Round")}
        rules={[{ required: true, message: t("Please enter round") }]}
      >
        <Select placeholder={t("Select category")} allowClear>
          {round_list?.map((round, index) => (
            <Option value={round?.id} key={index}>
              {round?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleRoundFormItem;
