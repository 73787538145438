import { gql } from "@apollo/client";

export const create_individual_performance_mutation = gql`
  mutation createIndividualParticipations(
    $json: individual_participations_input
  ) {
    createIndividualParticipations(json: $json) {
      id
      status
      error {
        status
        message
      }
      already_added_players {
        player_id
        event_schedule_id
      }
    }
  }
`;

export const update_performance_mutation = gql`
  mutation updateIndividualParticipation(
    $json: update_individual_participation_input
  ) {
    updateIndividualParticipation(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const deletePerformanceMutation = gql`
  mutation deleteIndividualParticipation(
    $json: get_individual_participation_input
  ) {
    deleteIndividualParticipation(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const deletePerformanceBulkMutation = gql`
  mutation deleteIndividualParticipations(
    $json: delete_individual_participation_input
  ) {
    deleteIndividualParticipations(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
export const get_individual_fixture_query = gql`
  query getIndividualParticipations(
    $json: get_all_individual_participation_input
  ) {
    getIndividualParticipations(json: $json) {
      items {
        id
        event_schedule_line_item_id
        position_id
        player_id
        is_send_to_next_level
        position {
          id
          name
          sort_order
        }
        player {
          id
          player_code
          name
        }
        individual_participation_parameters {
          id
          individual_participation_id
          event_parameter_id
          value
          event_parameter {
            id
            name
            type
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_individual_participation_query = gql`
  query getIndividualParticipation($json: get_individual_participation_input) {
    getIndividualParticipation(json: $json) {
      id
      event_schedule_line_item_id
      position_id
      player_id
      position {
        id
        name
        sort_order
      }
      player {
        id
        player_code
        name
      }
      individual_participation_parameters {
        id
        individual_participation_id
        event_parameter_id
        value
        event_parameter {
          id
          name
          type
        }
      }
    }
  }
`;

export const send_to_next_level_mutation = gql`
  mutation sendIndividualParticipationToNextLevel(
    $json: send_to_next_level_input
  ) {
    sendIndividualParticipationToNextLevel(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const send_individual_bulk_participation_to_next_level = gql`
  mutation sendBulkIndividualParticipationToNextLevel(
    $json: send_bulk_pariticipations_to_next_level_input
  ) {
    sendBulkIndividualParticipationToNextLevel(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_event_schedule_players_query = gql`
  query getEventSchedulePlayers($json: get_all_player_input) {
    getEventSchedulePlayers(json: $json) {
      items {
        id
        player_code
        name
        mobile
        dob
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_all_positions_query = gql`
  query getPositions($json: get_all_position_input) {
    getPositions(json: $json) {
      items {
        id
        name
        is_deleted
      }
    }
  }
`;

export const get_event_parameters_query = gql`
  query getEventParameters($json: get_all_event_parameter_input) {
    getEventParameters(json: $json) {
      items {
        id
        event_id
        name
        type
        event {
          id
          name
          type
          discipline {
            id
            name
          }
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_filtered_schedules_query = gql`
  query getFilteredtEventSchedules($json: get_all_event_schedule_input) {
    getFilteredtEventSchedules(json: $json) {
      items {
        id
        name
        level {
          id
          name
        }
        event_schedule_line_items {
          id
          event_id
          event {
            id
            discipline_id
            name
          }
          event_schedule_id
        }
        event_venue_id
        round {
          id
          name
        }
      }
    }
  }
`;

export const delete_individual_participation_to_next_level_mutation = gql`
  mutation deleteIndividualParticipationToNextLevel(
    $individual_participation_id: String!
    $player_id: String!
    $event_schedule_line_item_id: String!
  ) {
    deleteIndividualParticipationToNextLevel(
      individual_participation_id: $individual_participation_id
      player_id: $player_id
      event_schedule_line_item_id: $event_schedule_line_item_id
    ) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
