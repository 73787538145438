import { gql } from "@apollo/client";

export const get_event_registered_players_query = gql`
  query getEventRegisteredPlayers(
    $event_id: String!
    $gender: String!
    $category_id: String!
    $type: String!
    $differently_abled_id: String
  ) {
    get_registered_players: getEventRegisteredPlayers(
      event_id: $event_id
      gender: $gender
      category_id: $category_id
      type: $type
      differently_abled_id: $differently_abled_id
    ) {
      team_list
      player_ids_list
    }
  }
`;
