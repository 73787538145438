import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { Button, Col, Pagination, Row, Table, Tag } from "antd";
import { BiSolidCheckCircle } from "react-icons/bi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineEdit } from "react-icons/ai";
import Loader from "../common/loader";
import { useNavigate } from "react-router-dom";
import { startCase } from "lodash";
import { mutation_update_many_teams_participation } from "@services/graphql";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";

const TeamListTable = ({
  set_page_limit,
  page_limit,
  page_number,
  set_page_number,
  get_team_list,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selected_teams_id, set_selected_teams_id] = useState([]);
  const mutation_update_team_request = useAPIRequest(
    "updateManyTeamsParticipation",
    mutation_update_many_teams_participation
  );
  const {
    items: team_list,
    loading: team_list_loading,
    pagination,
  } = useDynamicSelector("getTeams");
  const {
    status: participant_approve_status,
    loading: participant_approve_loading,
    error: participant_approve_error,
  } = useDynamicSelector("updateManyTeamsParticipation");


  const fetchData = (page, limit) => {
    set_page_number(page);
    set_page_limit(limit);
  };

  const handle_update_click = (id) => {
    // console.log("val", val);
    navigate(`/cmtrophy/officer/team/${id}`);
    // set_team_id(val);
  };
  const handle_row_change = (record) => {
    set_selected_teams_id(record);
  };
  const handle_approve = () => {
    mutation_update_team_request({
      ids_list: selected_teams_id,
      is_participated: true,
    });
  };
    const handle_reject = () => {
      mutation_update_team_request({
        ids_list: selected_teams_id,
        is_participated: false,
      });
    };

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) =>
        (page_number - 1) * page_limit + index + 1,
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      render: (name) => (name ? name : "-"),
    },
    // {
    //   title: "Date of Birth",
    //   dataIndex: "dob",
    //   key: "dob",
    //   render: (dob) =>
    //     dob && moment(dob).isValid() ? moment(dob).format("DD-MM-YYYY") : "-",
    // },
    {
      title: "District",
      dataIndex: "district",
      key: "district",
      render: (district) => (district ? district.name : "-"),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (category) => (category ? category.name : "-"),
    },
    {
      title: "Discipline",
      dataIndex: "discipline",
      key: "discipline",
      render: (discipline) => (discipline ? discipline.name : "-"),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender) => startCase(gender ? gender : "-"),
    },
    {
      title: "Team Code",
      dataIndex: "team_code",
      key: "team_code",
      render: (team_code) => (team_code ? team_code : "-"),
    },
    {
      title: "Status",
      dataIndex: "is_participated",
      key: "is_participated",
      render: (record) =>
        record ? (
          <Tag style={{ backgroundColor: "green", color: "white" }}>
            Participated
          </Tag>
        ) : (
          <Tag style={{ backgroundColor: "#C7253E", color: "white" }}>
            Not Participated
          </Tag>
        ),
    },
    {
      title: "Action",
      dataIndex: "",
      key: "action",
      render: (record) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <AiOutlineEdit
            onClick={() => handle_update_click(record?.id)}
            style={{
              cursor: "pointer",
            }}
            size={20}
          />
          {/* <BiSolidCheckCircle
            onClick={() => handle_mark_participant(record)}
            style={{
              cursor: "pointer",
            }}
            size={20}
          /> */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (participant_approve_error) {
      dispatch(dynamic_clear("updateManyTeamsParticipation"));
    } else if (participant_approve_status === "success") {
      toast.success(t("participant_marked_successfully"));
      get_team_list();
      set_selected_teams_id([]);
      dispatch(dynamic_clear("updateManyTeamsParticipation"));
    }
  }, [participant_approve_error, participant_approve_status]);

  return (
    <>
      <Row justify={"end"} gutter={10}>
        <Col>
          <Button
            type={"primary"}
            disabled={selected_teams_id?.length > 0 ? false : true}
            onClick={handle_approve}
          >
            {t("participant")}
          </Button>
        </Col>
        <Col>
          <Button
            disabled={selected_teams_id?.length > 0 ? false : true}
            onClick={handle_reject}
            danger
          >
            {t("not_participant")}
          </Button>
        </Col>
      </Row>
      <Table
        rowClassName={(record, index) => (index % 2 === 0 ? "even-row" : "")}
        className="custom-table-header"
        columns={columns}
        dataSource={team_list}
        pagination={false}
        bordered={true}
        loading={team_list_loading ? <Loader /> : ""}
        rowSelection={{
          type: "checkbox",
          onChange: handle_row_change,
          selectedRowKeys: selected_teams_id,
        }}
        rowKey={"id"}
        style={{ marginTop: "20px" }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <Pagination
          total={pagination?.total_count}
          showSizeChanger
          current={page_number}
          pageSize={page_limit}
          onChange={fetchData}
        />
      </div>
    </>
  );
};

export default TeamListTable;
