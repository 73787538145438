import { useSelector, useDispatch } from "react-redux";
import {
  createSendIndividualNextLevelSelector,
  getOnePerformanceSelector,
  getFilteredSchedulesListSelector,
  dynamic_clear,
  useDynamicSelector,
} from "@services/redux";

import { Form, Button, Typography, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_filtered_schedules_query,
  get_individual_fixture_query,
} from "@services/graphql/performance";
import { send_to_next_level_mutation } from "@services/graphql/event_schedule_team";

const SendTONextLevelDetails = (props) => {
  const {
    initialValues,
    event_id,
    schedule,
    close,
    player_id,
    isSingle,
    event_schedule_line_item_id,
    event_schedule_id,
    rerender,
    eventRegisteredPlayers,
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [actionItem, setActionItem] = useState({});
  const [eventScheduleLineItem, setEventScheduleLineItems] = useState([]);
  const [eventSchedule, setEventSchedule] = useState("");

  const {
    loading: createLoading,
    error,
    status,
  } = useDynamicSelector("sendGroupParticipationToNextLevel");

  const { items: schedules } = useDynamicSelector("getFilteredtEventSchedules");

  const { item: player } = useDynamicSelector("getIndividualParticipation");

  const get_filtered_schedules_request = useAPIRequest(
    "getFilteredtEventSchedules",
    get_filtered_schedules_query
  );
  const get_individual_fixture_request = useAPIRequest(
    "getIndividualParticipations",
    get_individual_fixture_query
  );

  const send_to_next_level_request = useAPIRequest(
    "sendGroupParticipationToNextLevel",
    send_to_next_level_mutation
  );

  useEffect(() => {
    get_filtered_schedules_request({
      json: {
        event_id: event_id,
        event_schedule_id: event_schedule_id,
      },
    });
  }, [event_id, rerender]);

  useEffect(() => {
    let filteredArray = schedules
      ?.find((x) => x.id === eventSchedule)
      ?.event_schedule_line_items?.filter((y) => {
        return y.event_id === event_id;
      });
    setEventScheduleLineItems(filteredArray);
  }, [eventSchedule]);

  useEffect(() => {
    if (error === "Failure") {
      toast.error("Something went wrong!");

      close();
      dispatch(dynamic_clear("sendIndividualParticipationToNextLevel"));
    } else if (status === "success") {
      toast.success("Sent to Next level successfully");

      close();
      eventRegisteredPlayers();
      get_individual_fixture_request({
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_number: 1,
          page_limit: 10,
          search_string: "",
        },
      });

      dispatch(dynamic_clear("sendIndividualParticipationToNextLevel"));
    }
  }, [error, status]);

  const handleSubmit = (values) => {
    delete values.event_schedule_id;
    values.player_id = initialValues?.player?.id || player_id;
    values.event_schedule_line_item_id = eventScheduleLineItem[0]?.id;
    values.individual_participation_id = initialValues?.id;
    send_to_next_level_request({
      json: values,
    });
  };

  const onValueChange = (values, allValues) => {
    setEventSchedule(allValues.event_schedule_id);
  };

  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [rerender]);

  return (
    <>
      {!player_id?.length && (
        <div>
          <div style={{ marginBottom: "10px" }}>
            <Typography.Text strong>{t("Player Name: ")}</Typography.Text>
            <Typography.Text
              style={{
                fontWeight: "bold",
              }}
            >
              {initialValues?.player?.name}
            </Typography.Text>
          </div>
          <div style={{ marginBottom: "10px" }}>
            <Typography.Text strong>{t("Player Code: ")}</Typography.Text>
            <Typography.Text
              style={{
                fontWeight: "bold",
              }}
            >
              {initialValues?.player?.player_code}
            </Typography.Text>
          </div>
        </div>
      )}

      <Typography.Text
        style={{
          fontWeight: "bold",
        }}
      >
        {player?.player?.name}
      </Typography.Text>
      <div style={{ overflowX: "hidden", marginTop: 20 }}>
        <div style={{ margin: "auto" }}>
          <Form
            form={form}
            initialValues={actionItem}
            onValuesChange={onValueChange}
            onFinish={handleSubmit}
            layout="vertical"
          >
            <div style={{ marginBottom: "24px" }}>
              <Form.Item
                name="event_schedule_id"
                label={
                  <>
                    <Typography.Text
                      style={{
                        fontFamily: "Marutham",
                        fontSize: 16,
                      }}
                    >
                      அட்டவணை பெயர்
                    </Typography.Text>
                    <Typography.Text
                      style={{ fontFamily: "Roboto", fontSize: 16 }}
                    >
                      &nbsp; / Schedule Name
                    </Typography.Text>
                  </>
                }
                rules={[{ required: true, message: " அட்டவணை பெயர் தேவை" }]}
              >
                <Select
                  options={schedules}
                  fieldNames={{ label: "name", value: "id" }}
                  placeholder={t("Select Schedule")}
                />
              </Form.Item>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="primary"
                loading={createLoading}
                htmlType="submit"
                style={{ backgroundColor: "#161680" }}
              >
                {t("Submit")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SendTONextLevelDetails;
