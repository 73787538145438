import { retrieve_item } from "@helpers/functions";
import { Button, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const   AddEventSchedule = ({
  set_is_event_schedule_modal_open,
  set_modal_type,
  set_search_value,
  search_value,
}) => {
  const { t } = useTranslation();
  const { Search } = Input;
  const session_id = retrieve_item("session_id");

  const on_search = (value) => {
    set_search_value(value);
  };

  const handle_schedule_add_click = () => {
    set_is_event_schedule_modal_open(true);
    set_modal_type("add");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        gap: "15px",
        width: "100%",
        marginBottom: "10px",
      }}
    >
      {session_id && (
        <Button
          className="add_event_venue_btn"
          onClick={handle_schedule_add_click}
        >
          {t("add_event_schedule")}
        </Button>
      )}

      <Search
        className="search-input-player-list"
        placeholder="Search"
        onSearch={on_search}
        value={search_value}
        onChange={(e) => set_search_value(e.target.value)}
        allowClear
        style={{
          width: 200,
        }}
      />
    </div>
  );
};

export default AddEventSchedule;
