import { useDynamicSelector } from "@services/redux";
import { Tag } from "antd";
import React from "react";

const SecondContainer = () => {
  const { players, teams, loading } = useDynamicSelector("get_registered_user");
  return (
    players?.[0]?.player_code && (
      <div className="player-id-tag-container">
        <Tag className="player-id-tag">
          Player ID - {players?.[0]?.player_code}
        </Tag>
      </div>
    )
  );
};

export default SecondContainer;
