import { gql } from "@apollo/client";

export const create_event_venue_mutation = gql`
  mutation createEventVenue($json: create_event_venue_input) {
    createEventVenue(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
export const update_event_venue_mutation = gql`
  mutation updateEventVenue($json: update_event_venue_input) {
    updateEventVenue(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const delete_event_venue_mutation = gql`
  mutation deleteEventVenue($json: get_event_venue_input) {
    deleteEventVenue(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_event_venues_query = gql`
  query getEventVenues($json: get_all_event_venue_input) {
    getEventVenues(json: $json) {
      items {
        id
        name
        address
      }
      pagination {
        page_number
        page_limit
        total_count
      }
      error {
        status
        message
      }
    }
  }
`;

export const get_event_venue_query = gql`
  query getEventVenue($json: get_event_venue_input) {
    getEventVenue(json: $json) {
      id
      name
      address
      district_id
    }
  }
`;
