import React from "react";
import { Form, Radio } from "antd";
import { useTranslation } from "react-i18next";

const RegisterGender = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name={["player", "gender"]}
        label={t("gender")}
        rules={[{ required: true, message: t("gender_required") }]}
      >
        <Radio.Group>
          <Radio value="male"> Male </Radio>
          <Radio value="female"> Female </Radio>
        </Radio.Group>
      </Form.Item>
    </div>
  );
};

export default RegisterGender;
