import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const RegisterDOB = ({ onChange }) => {
  const [age, setAge] = useState(null);
  const disabledDate = (d) => {
    return d.isAfter("2014-12-31");
  };
  const calculateAge = (dob) => {
    onChange(dob);
    if (dob) {
      const today = dayjs();
      const birthDate = dayjs(dob);
      const age = today.diff(birthDate, "year");
      setAge(age);
    } else {
      setAge(null);
    }
  };

  return (
    <>
      <DatePicker
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
        defaultPickerValue={dayjs(moment("2014-12-31"))}
        onChange={calculateAge}
      />
      <p style={{ padding: "10px", fontWeight: "bolder" }}>
        {age !== null ? `Age: ${age}` : ""}
      </p>
    </>
  );
};

export default RegisterDOB;
