import { useAPIRequest } from "@helpers/hooks";
import { event_differently_abled_list_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleDifferentlyAbled = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const { items: differently_abled_list } = useDynamicSelector(
    "event_differently_abled_list"
  );

  const event_differently_abled_list_request = useAPIRequest(
    "event_differently_abled_list",
    event_differently_abled_list_query
  );

  useEffect(() => {
    event_differently_abled_list_request({}, true);
  }, []);

  return (
    <Col span={12}>
      <Form.Item
        name="differently_abled"
        label={t("differently_abled")}
        rules={[{ required: true, message: t("Please enter events") }]}
      >
        <Select placeholder={t("Select category")} allowClear>
          {differently_abled_list?.map((different_abled, index) => (
            <Option value={different_abled?.id} key={index}>
              {different_abled?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleDifferentlyAbled;
