import React, { useEffect, useState } from "react";
import { Form, Input, TimePicker, Button, Row, Col, DatePicker } from "antd";
import { useTranslation } from "react-i18next";
import EventScheduleVenueFormItem from "./event_schedule_venue_form_item";
import EventScheduleCategoryForItem from "./event_schedule_category_form_item";
import EventScheduleGenderFormItem from "./event_schedule_gender_form_item";
import EventScheduleEventsFormItem from "./event_schedule_events_form_item";
import EventScheduleRoundFormItem from "./event_schedule_round_form_item";
import EventScheduleRemarksFormItem from "./event_schedule_remarks_form_item";
import EventScheduleDisciplineFormItem from "./event_schedule_discipline_form_item";
import EventScheduleLevelFormItem from "./event_schedule_level_form_item";
import { useAPIRequest } from "@helpers/hooks";
import {
  create_event_schedule_mutation,
  update_event_schedule_mutation,
} from "@services/graphql";
import moment from "moment";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import EventScheduleDifferentlyAbled from "./event_schedule_differently_abled";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);

const format = "hh:mm a";
const dateFormat = "DD/MM/YYYY";
const EventScheduleForm = ({
  set_is_event_schedule_modal_open,
  modal_type,
  selected_schedule_event_id,
  form,
  get_event_schedules,
  setStartDate,
  startDate,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [differently_able_id, set_differently_able_id] = useState("");
  const {
    status: create_event_schedule_status,
    error: create_event_schedule_error,
    loading: create_event_schedule_loading,
  } = useDynamicSelector("createEventSchedule");

  const {
    status: update_event_schedule_status,
    error: update_event_schedule_error,
    loading: update_event_schedule_loading,
  } = useDynamicSelector("updateEventSchedule");

  const data = useDynamicSelector("getEventSchedule");

  const create_event_schedule_request = useAPIRequest(
    "createEventSchedule",
    create_event_schedule_mutation
  );

  const update_event_schedule_request = useAPIRequest(
    "updateEventSchedule",
    update_event_schedule_mutation
  );

  useEffect(() => {
    if (create_event_schedule_status === "success") {
      set_is_event_schedule_modal_open(false);
      toast.success("Event Schedule Created Successfully");
      dispatch(dynamic_clear("createEventSchedule"));
      form.resetFields();
      get_event_schedules();
    } else if (update_event_schedule_status === "success") {
      set_is_event_schedule_modal_open(false);
      toast.success("Update Schedule Created Successfully");
      dispatch(dynamic_clear("updateEventSchedule"));
      form.resetFields();
      get_event_schedules();
    } else if (update_event_schedule_error) {
      toast.error(update_event_schedule_error?.message);
      dispatch(dynamic_clear("updateEventSchedule"));
    }
  }, [
    create_event_schedule_status,
    create_event_schedule_error,
    update_event_schedule_error,
    update_event_schedule_status,
  ]);

  useEffect(() => {
    if (modal_type === "edit" && data) {
      const dateTime = moment(parseInt(data.date_time));
      const date = dateTime.format(dateFormat);
      const time = dateTime.format(format);
      // Extract discipline and events from the first item in the event_schedule_line_items array
      const first_line_item = data.event_schedule_line_items?.[0] || {};
      const discipline_id = first_line_item.event?.discipline?.id;
      const event_id = first_line_item.event?.id;

      form.setFieldsValue({
        schedule_name: data.name,
        date: data?.date_time ? dayjs(Number(data?.date_time)) : undefined,
        time: data.date_time ? dayjs(Number(data.date_time)) : undefined,
        venue: data.event_venue_id,
        gender: data.gender,
        round: data.round?.id,
        remarks: data.remarks,
        category: data.category?.id,
        level: data.level?.id,
        discipline: discipline_id,
        events: data.event_schedule_line_items?.map((item) => item.event?.id),
      });
    }
  }, [modal_type, data]);

  const create_event_schedule = (values) => {
    // let date = moment(values?.date).format("DD/MM/YYYY");
    // let time = moment(values?.time).format("HH:mm:ss");
    // let date_time = moment(date + " " + time, "DD/MM/YYYY HH:mm").toISOString();
    create_event_schedule_request(
      {
        json: {
          data: {
            name: values?.schedule_name,
            event_venue_id: values?.venue,
            // date_time: date_time,
            date_time: `${moment(values?.date?.$d).format(
              "YYYY-MM-DD"
            )}T${moment(values?.time?.$d).format("HH:mm:ssZ")}`,
            gender: values?.gender,
            round_id: values?.round,
            remarks: values?.remarks,
            category_id: values?.category,
            level_id: values?.level,
            event_schedule_line_items: values.events.map((event_id) => ({
              event_id,
            })),
          },
        },
      },
      true
    );
  };

  const update_event_schedule = (values) => {
    // Handle updated events - merge existing and newly added events
    const updated_event_schedule_line_items = data?.event_schedule_line_items
      ?.map((item, index) => {
        // Check if event exists in form values and map correctly
        const event_id = values?.events?.[index] || "";
        if (event_id)
          return {
            id: item?.id || "", // Existing id or empty string
            event_id: event_id, // Use event_id from form values (if available)
            event_schedule_id: item?.event_schedule_id || "", // Existing event_schedule_id
          };
      })
      ?.filter((list) => list?.id);

    // Ensure newly added events are appended to the array
    const new_event_schedule_line_items = values?.events
      ?.slice(data?.event_schedule_line_items?.length)
      .map((event_id) => ({
        id: "",
        event_id: event_id,
        event_schedule_id: selected_schedule_event_id,
      }));

    // Combine updated and new events
    const combined_event_schedule_line_items = [
      ...updated_event_schedule_line_items,
      ...new_event_schedule_line_items,
    ];
    let variables = {
      json: {
        id: selected_schedule_event_id,
        data: {
          name: values?.schedule_name,
          event_venue_id: values?.venue,
          date_time: `${moment(values?.date?.$d).format("YYYY-MM-DD")}T${moment(
            values?.time?.$d
          ).format("HH:mm:ssZ")}`,
          gender: values?.gender,
          round_id: values?.round,
          remarks: values?.remarks,
          category_id: values?.category,
          level_id: values?.level,
          event_schedule_line_items: combined_event_schedule_line_items,
        },
      },
    };

    update_event_schedule_request(variables, true);
  };

  const onFinish = (values) => {
    {
      modal_type === "add"
        ? create_event_schedule(values)
        : update_event_schedule(values);
    }
  };

  const onValuesChange = (value, values) => {
    if (value?.category) {
      form.setFieldsValue({
        ...values,
        events: [],
        discipline: undefined,
        gender: undefined,
      });
    } else if (value?.gender) {
      form.setFieldsValue({
        ...values,
        events: [],
        discipline: undefined,
      });
    } else if (value?.discipline) {
      form.setFieldsValue({
        events: [],
      });
    }
  };
  const handle_date_picker_change = (value) => {};
  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 800, margin: "0 auto" }}
      form={form}
      onValuesChange={onValuesChange}
    >
      <Form.Item
        name="schedule_name"
        label={t("schedule_name")}
        rules={[{ required: true, message: t("Please enter schedule name") }]}
      >
        <Input placeholder={t("Enter schedule name")} />
      </Form.Item>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name="date"
            label={t("date")}
            rules={[{ required: true, message: t("Please select date") }]}
          >
            <DatePicker />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="time"
            label={t("time")}
            rules={[{ required: true, message: t("Please select time") }]}
          >
            <TimePicker
              format={format}
              use12Hours
              onChange={handle_date_picker_change}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <EventScheduleVenueFormItem />
        <EventScheduleCategoryForItem
          set_differently_able_id={set_differently_able_id}
          form={form}
        />
      </Row>

      {differently_able_id === "differently_abled" && (
        <Row gutter={24}>
          <EventScheduleDifferentlyAbled />
        </Row>
      )}

      <Row gutter={24}>
        <EventScheduleGenderFormItem />
        <EventScheduleDisciplineFormItem form={form} />
      </Row>

      <Row gutter={24}>
        <EventScheduleEventsFormItem form={form} />
        <EventScheduleLevelFormItem />
      </Row>
      <EventScheduleRoundFormItem />

      <EventScheduleRemarksFormItem />
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          {t("சமர்ப்பிக்க / Submit")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EventScheduleForm;
