import { useDispatch } from "react-redux";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  Col,
  Row,
  Typography,
  Spin,
  Space,
  Input,
  Form,
  Checkbox,
} from "antd";
import { useAPIRequest } from "@helpers/hooks";
import { update_state_team_mutation } from "@services/graphql/event_schedule_team";
import toast from "react-hot-toast";

const { Text } = Typography;

const SendToStateLevel = ({
  initialValues,
  close,
  event_schedule_line_item_id,
  eventRegisteredPlayers,
  actionItem,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { items: players = [] } = useDynamicSelector("get_players");
  const { item: team, loading: team_loading } = useDynamicSelector("getTeams");
  const {
    loading: updateLoading,
    error,
    status,
  } = useDynamicSelector("update_state_team_mutation");

  const [selectedPlayers, setSelectedPlayers] = useState([]);

  const update_state_team_mutation_request = useAPIRequest(
    "updateStateTeam",
    update_state_team_mutation
  );

  useEffect(() => {
    if (error) {
      toast.error(error.message);
      close();
    } else if (status === "Success") {
      toast.success(t("team_moved_state_success"));
      eventRegisteredPlayers();
      close();
    }
    dispatch(dynamic_clear("update_state_team_mutation"));
  }, [error, status, close, eventRegisteredPlayers, dispatch, t]);

  const handlePlayerChange = (selectedPlayerIds) => {
    setSelectedPlayers(selectedPlayerIds);
  };

  const handle_submit = (values) => {
    update_state_team_mutation_request(
      {
        id: actionItem?.team_id,
        team_name: values.team_name,
        players: selectedPlayers, // Use selected player IDs
        event_schedule_line_item_id,
      },
      true
    );
  };

  return (
    <>
      {team_loading ? (
        <div style={{ textAlign: "center", paddingTop: "5px" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div style={{ marginTop: "20px" }}>
          <Row gutter={24} style={{ marginBottom: "20px" }}>
            <Col span={8}>
              <Text strong style={{ fontSize: 16 }}>
                {`${actionItem?.team?.name} - ${actionItem?.team?.team_code}`}
              </Text>
            </Col>
          </Row>

          <Form onFinish={handle_submit} initialValues={initialValues}>
            <Row gutter={24} style={{ marginBottom: "20px" }}>
              <Col span={16}>
                <Text>{t("team_name")}</Text>
                <Form.Item
                  name="team_name"
                  rules={[{ required: true, message: t("team_name_required") }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item name="players">
              <Row gutter={16}>
                {players.length > 0 ? (
                  players.map((player) => (
                    <Col span={8} key={player.id}>
                      <Card
                        style={{
                          cursor: "pointer",
                          background: "#fff",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Checkbox
                          value={player.id}
                          onChange={(e) => {
                            const { checked } = e.target;
                            setSelectedPlayers((prevSelected) =>
                              checked
                                ? [...prevSelected, player.id]
                                : prevSelected.filter((id) => id !== player.id)
                            );
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Text>{player.name}</Text>
                            <Text>{player.player_code}</Text>
                          </div>
                        </Checkbox>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <Text>{t("no_players_available")}</Text>
                )}
              </Row>
            </Form.Item>

            <Space
              style={{
                marginTop: "20px",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button htmlType="submit" loading={updateLoading} type="primary">
                {t("submit")}
              </Button>
              <Button onClick={close} type="danger">
                {t("close")}
              </Button>
            </Space>
          </Form>
        </div>
      )}
    </>
  );
};

export default SendToStateLevel;
