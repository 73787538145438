import { Form, Input } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const EventScheduleRemarksFormItem = () => {
  const { t } = useTranslation();
  return (
    <Form.Item name="remarks" label={t("கருத்துக்கள் / Remarks")}>
      <Input.TextArea placeholder={t("Enter remarks")} />
    </Form.Item>
  );
};

export default EventScheduleRemarksFormItem;
