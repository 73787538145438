import React, { useEffect, useState } from "react";
import { Form, Row, Col, Button } from "antd";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useDynamicSelector, dynamic_clear } from "@services/redux";
import {
  get_events_query,
  get_min_max_partcipants_count_query,
  get_player_query,
  update_player_mutation,
} from "@services/graphql";
import BasicDetails from "@views/components/register-form/basic_registration";
import Address from "@views/components/register-form/address";
import UpdateEvents from "./update_events";
import { useAPIRequest } from "@helpers/hooks";
import { get_uuid } from "@helpers/functions";
import moment from "moment";
import toast from "react-hot-toast";
import dayjs from "dayjs";
import UpdateAadharUrl from "./update_aadhar_url";
import UpdateTeamPlayerList from "../player_list/update_team_player_list";

const UpdateForm = ({
  update_player_id,
  set_is_update_modal_open,
  get_player_list,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [events, set_events] = useState([]);
  const [gender, set_gender] = useState("");
  const [event_ids, set_event_ids] = useState([]);
  const [discipline, set_discipline] = useState("");
  const [category_id, set_category_id] = useState("");
  const [is_mandatory, set_is_mandatory] = useState(true);
  const [differently_abled, set_differently_abled] = useState("");

  const player_data = useDynamicSelector("get_player");
  const type = player_data?.events?.[0]?.type || "individual";

  const { max_allowed_participants } = useDynamicSelector(
    "get_min_max_partcipants_count"
  );
  console.log("events", events);

  const {
    status: update_status,
    error: update_error,
    loading: update_loading,
  } = useDynamicSelector("updatePlayer");

  const get_event_list = useAPIRequest("get_events", get_events_query);
  const get_player_request = useAPIRequest("get_player", get_player_query);
  const update_player_request = useAPIRequest(
    "updatePlayer",
    update_player_mutation
  );

  const get_min_max_partcipants_count_request = useAPIRequest(
    "get_min_max_partcipants_count",
    get_min_max_partcipants_count_query
  );

  const get_min_max_partcipants_count_list = () => {
    get_min_max_partcipants_count_request(
      {
        json: {
          gender: gender,
          discipline_id: Array.isArray(discipline)
            ? discipline.join(",")
            : discipline,
          // event_id: Array.isArray(event_ids) ? event_ids.join(",") : event_ids,
          category_id: category_id,
        },
      },
      true
    );
  };

  useEffect(() => {
    if (discipline) {
      get_min_max_partcipants_count_list();
    }
  }, [discipline]);
  useEffect(() => {
    if (update_status === "success") {
      toast.success("Player Details Updated Successfully");
      set_is_update_modal_open(false);
      dispatch(dynamic_clear("updatePlayer"));
      dispatch(dynamic_clear("get_min_max_partcipants_count"));
      get_player_list();
    } else if (update_error) {
      toast.error(update_error?.message);
      dispatch(dynamic_clear("updatePlayer"));
    }
  }, [update_status, update_error]);

  useEffect(() => {
    dispatch(dynamic_clear("verify_otp"));
  }, []);

  useEffect(() => {
    if (update_player_id) {
      get_player_request({ id: update_player_id }, true);
    }
  }, [update_player_id]);

  const initialFileList = player_data?.aadhar_url
    ? [
        {
          uid: get_uuid(),
          name: "Aadhaar.pdf",
          url: player_data.aadhar_url,
          status: "done",
        },
      ]
    : [];

  useEffect(() => {
    if (player_data) {
      const values = {
        name: player_data.name,
        mobile: player_data.mobile,
        city: player_data.city,
        district_id: player_data.district_id,
        department: player_data.department,
        player: {
          dob: dayjs(player_data.dob),
          discipline_ids: player_data.disciplines?.map((d) => d.id),
          event_ids: player_data.events?.map((e) => e.id),
          aadhar_no: player_data.aadhar_no,
          gender: player_data.gender,
          address1: player_data.address1,
          category_id: player_data.category?.id,
          differently_abled_id: player_data.differently_abled_id,
          aadhar_url: player_data.aadhar_url
            ? [
                {
                  name: "View",
                  url: player_data.aadhar_url,
                  uid: get_uuid(),
                },
              ]
            : [],
        },
      };

      form.setFieldsValue(values);

      set_gender(player_data.gender);
      set_discipline(player_data.disciplines?.map((d) => d.id));
      set_category_id(player_data.category?.id || "");
      set_event_ids(player_data.events?.map((e) => e.id));
      set_differently_abled(player_data.differently_abled_id);
    }
  }, [player_data]);

  useEffect(() => {
    set_is_mandatory(category_id !== "school" && category_id !== "college");
  }, [category_id]);

  useEffect(() => {
    if (discipline && gender && category_id) {
      get_event_list(
        {
          discipline_id: Array.isArray(discipline)
            ? discipline.join(",")
            : discipline,
          gender: gender,
          category_id: category_id,
          differently_abled_id: differently_abled,
        },
        true
      );
    }
  }, [discipline, gender, category_id, differently_abled]);

  const handle_submit = (values) => {
    values.player_data = values?.player_data?.filter((list) => list?.name);
    console.log("handle_submit", values);

    update_player(values);
  };

  const handle_on_change = (val) => {
    if (val?.player?.category_id) {
      set_category_id(val?.player?.category_id);
      if (val?.player?.category_id !== "differently_abled") {
        set_differently_abled("");
        form.setFieldsValue({
          player: {
            differently_abled_id: null,
          },
        });
      }
    }
    if (val?.player?.gender) {
      set_gender(val?.player?.gender);
    }
    if (val?.player?.discipline_ids) {
      set_discipline(val?.player?.discipline_ids);
    }
    if (val?.player?.event_ids) {
      set_event_ids(val?.player?.event_ids);
    }
    if (val?.player?.differently_abled_id) {
      set_differently_abled(val?.player?.differently_abled_id);
    }
  };

  const update_player = (values) => {
    const player_details = {
      name: values?.name,
      mobile: values?.mobile?.toString(),
      district_id: values?.district_id,
      gender: values?.player?.gender,
      address1: values?.player?.address1,
      category_id: values?.player?.category_id,
      aadhar_no: values?.player?.aadhar_no?.toString(),
      dob: moment(new Date(values?.player?.dob)).format("YYYY-MM-DD"),
      event_ids: values?.player?.event_ids,
      discipline_ids: values?.player?.discipline_ids,
      aadhar_url: values?.player?.aadhar_url?.file?.url,
      differently_abled_id: values?.player?.differently_abled_id,
      additional_player_list: values?.player_data,
    };
    update_player_request({ id: update_player_id, data: player_details }, true);
  };

  return (
    <div className="registration-form-container">
      {type && (
        <>
          <h1 style={{ textAlign: "center" }}>
            {t("Update Player Registration")}
          </h1>
          <Form
            layout="vertical"
            style={{ marginTop: "40px" }}
            initialValues={player_data || {}}
            onValuesChange={handle_on_change}
            onFinish={handle_submit}
            form={form}
          >
            <Row gutter={16}>
              <BasicDetails
                type={type}
                form={form}
                is_mandatory={is_mandatory}
                update_player_id={update_player_id}
              />

              <UpdateEvents
                category_id={category_id}
                gender={gender}
                discipline={discipline}
                differently_abled={differently_abled}
                set_events={set_events}
                event_ids={event_ids}
              />

              <Address
                category_id={category_id}
                district_id={player_data?.district_id}
              />

              <Col span={24}>
                <Row gutter={16} align={"middle"}>
                  <Col>
                    <h2>{t("Attachments")} </h2>
                  </Col>
                  <Col>
                    <h5 style={{ color: "red" }}>( {t("attachment_size")} )</h5>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12}>
                <UpdateAadharUrl initialFileList={initialFileList} />
              </Col>
              {(player_data?.additional_players?.length ||
                (max_allowed_participants > 1 &&
                  (events?.[0]?.name === "Doubles" ||
                    events?.[1]?.name === "Doubles" ||
                    events?.[0]?.type === "team"))) && (
                <UpdateTeamPlayerList form={form} />
              )}
            </Row>

            <Row justify={"end"} gutter={30} align={"bottom"}>
              <Col>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={update_loading}
                >
                  {t("Submit")}
                </Button>
              </Col>
            </Row>
          </Form>
        </>
      )}
    </div>
  );
};

export default UpdateForm;
