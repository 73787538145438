// src/pages/RedirectPage.js
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Redirector = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/cmtrophy");
  }, [navigate]);

  return (
    <div>
      <h2>Redirecting...</h2>
    </div>
  );
};

export default Redirector;
