import React, { useEffect, useState } from "react";
import { Form, Input, Button } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import { forgot_password_mutation, get_otp_for_forgot_password_query } from "@services/graphql/forgot_password";

const VerifyOtp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [timer, set_timer] = useState(15 * 60); // Timer state in seconds, initially set to 15 minutes
  let verify_otp_request = useAPIRequest("forgot_password", forgot_password_mutation);
  let resend_otp_request = useAPIRequest("get_otp_for_forgot_password", get_otp_for_forgot_password_query);
  const { status: verify_otp, loading, ...rest } = useDynamicSelector("forgot_password");

  const onFinish = (values) => {
    verify_otp_request(
      {
        mobile: String(state?.mobile),
        otp: String(values?.otp),
        password: String(values?.password),
      },
      true
    );
  };

  const onFinishFailed = (errorInfo) => {};

  const handleResendOtp = () => {
    resend_otp_request(
      {
        mobile: String(state?.mobile),
      },
      true
    );
    set_timer(15 * 60); // Reset timer to 15 minutes (in seconds)
  };

  useEffect(() => {
    if (verify_otp === "success") {
      navigate("/cmtrophy/");
      dispatch(dynamic_clear("forgot_password"));
    }
  }, [verify_otp, navigate, dispatch]);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        set_timer((prev_timer) => prev_timer - 1);
      }, 1000);
    } else if (timer === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const validate_password = (_, value) => {
    if (!value || value.length < 8) {
      return Promise.reject(new Error(t("password_too_short")));
    }
    return Promise.resolve();
  };

  const validate_confirm_password = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(t("passwords_do_not_match")));
    },
  });

  const format_time = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remaining_seconds = seconds % 60;
    return `${minutes}:${remaining_seconds < 10 ? "0" : ""}${remaining_seconds}`;
  };

  return (
    <div className="login-container">
      <div className="player-login-box">
        <h3 className="verify-otp-title">{t("verify_otp")}</h3>
        <Form
          name="verify-otp-form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label={t("otp")}
            name="otp"
            rules={[{ required: true, message: t("OTP தேவை") }]}
          >
            <Input.OTP length={4} />
          </Form.Item>
          <p style={{ textAlign: 'center' }}>
            <Button type="link" onClick={handleResendOtp} disabled={timer > 0}>
              {t("resend_otp")}
            </Button>
          </p>
          {timer > 0 && (
            <p style={{ textAlign: 'center', color: 'red' }}>
              {t("You can resend OTP in")} {format_time(timer)}
            </p>
          )}

          <Form.Item
            label={t("set_password")}
            name="password"
            rules={[
              { required: true, message: t("கடவுச்சொல்லை உள்ளிடவும் / Please input your password") },
              { validator: validate_password },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={t("கடவுச்சொல்லை உறுதிப்படுத்தவும் / Confirm Password")}
            name="confirm_password"
            dependencies={["password"]}
            rules={[
              { required: true, message: t("Conform_password") },
              validate_confirm_password,
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="verify-otp-button"
              loading={loading}
            >
              {t("சமர்ப்பிக்கவும் / Submit")}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default VerifyOtp;
