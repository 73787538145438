import React, { useState } from "react";
import { Table, Input, DatePicker, Button, Form } from "antd";
import dayjs from "dayjs";
import { useDynamicSelector } from "@services/redux";

const AddTeamPlayer = () => {
  const [form] = Form.useForm();
  const { min_allowed_participants, max_allowed_participants } =
    useDynamicSelector("get_min_max_partcipants_count");

  const initialData = Array.from(
    { length: max_allowed_participants - 1 },
    (_, i) => ({
      key: i.toString(),
      s_no: i + 1,
      player_name: "",
      dob: null,
      age: "",
      mobile_no: "",
      address: "",
      aadhar_no: "",
    })
  );

  const [dataSource, setDataSource] = useState(initialData);

  const handleFieldChange = (key, field, value) => {
    const newData = dataSource.map((item) => {
      if (item.key === key) {
        item[field] = value;
        if (field === "dob") {
          item.age = dayjs().diff(dayjs(value), "year");
        }
      }
      return item;
    });
    setDataSource(newData);
  };

  const columns = [
    { title: "S.No", dataIndex: "s_no", key: "s_no" },
    {
      title: "Player Name",
      dataIndex: "player_name",
      key: "player_name",
      render: (_, record, index) => (
        <Form.Item
          name={`player_name_${index}`}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Player Name is required",
            },
          ]}
        >
          <Input
            onChange={(e) =>
              handleFieldChange(record.key, "player_name", e.target.value)
            }
          />
        </Form.Item>
      ),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      render: (_, record, index) => (
        <Form.Item
          name={`dob_${index}`}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "DOB is required",
            },
          ]}
        >
          <DatePicker
            format="DD-MM-YYYY"
            onChange={(date) => handleFieldChange(record.key, "dob", date)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      render: (_, record) => <Input value={record.age} disabled />,
    },
    {
      title: "Mobile No",
      dataIndex: "mobile_no",
      key: "mobile_no",
      render: (_, record, index) => (
        <Form.Item
          name={`mobile_no_${index}`}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Mobile No is required",
            },
            {
              validator: (_, value) => {
                if (!value || /^[0-9]{10}$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Mobile No must be exactly 10 digits")
                );
              },
            },
          ]}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={10}
            onChange={(e) =>
              handleFieldChange(record.key, "mobile_no", e.target.value)
            }
          />
        </Form.Item>
      ),
    },

    {
      title: "Aadhar No",
      dataIndex: "aadhar_no",
      key: "aadhar_no",
      render: (_, record, index) => (
        <Form.Item
          name={`aadhar_no_${index}`}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Aadhar Number is required",
            },
            {
              pattern: /^[0-9]{12}$/,
              message: "Aadhar Number must be exactly 12 digits",
            },
          ]}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={12}
            onChange={(e) =>
              handleFieldChange(record.key, "aadhar_no", e.target.value)
            }
          />
        </Form.Item>
      ),
    },

    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record, index) => (
        <Form.Item
          name={`address_${index}`}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Address is required",
            },
          ]}
        >
          <Input
            onChange={(e) =>
              handleFieldChange(record.key, "address", e.target.value)
            }
          />
        </Form.Item>
      ),
    },
  ];

  return (
    <div>
      <h2>Add Team Player</h2>
      <Table
        className="player-list-table"
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default AddTeamPlayer;
