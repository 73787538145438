import { Button, Input } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import VenueAddModal from "./venue_add_modal";

const AddEventVenue = ({
  set_event_modal_type,
  set_is_modal_open,
  set_search_value,
  search_value,
}) => {
  const { t } = useTranslation();

  const { Search } = Input;
  const on_search = (value) => {
    set_search_value(value);
  };

  const handle_event_add_click = () => {
    set_is_modal_open(true);
    set_event_modal_type("add");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "row",
        gap: "15px",
        width: "100%",
        marginBottom: "10px",
      }}
    >
      <Button className="add_event_venue_btn" onClick={handle_event_add_click}>
        {t("add_event_venue")}
      </Button>
      <Search
        className="search-input-player-list"
        placeholder="Search"
        onSearch={on_search}
        value={search_value}
        onChange={(e) => set_search_value(e.target.value)}
        allowClear
        style={{
          width: 200,
        }}
      />
    </div>
  );
};

export default AddEventVenue;
