import React, { useEffect, useState } from "react";
import { Button, Table, Pagination, Modal, Col, Row, Divider } from "antd";
import { useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import {
  get_individual_fixture_query,
  get_performances_query,
} from "@services/graphql/performance";
import { useAPIRequest } from "@helpers/hooks";
import ParticipantsDetails from "./participant_details";
import { CiCircleRemove } from "react-icons/ci";
import { FcPositiveDynamic } from "react-icons/fc";
import { AiOutlineSetting } from "react-icons/ai";
import { retrieve_item } from "@helpers/functions";
import { useNavigate } from "react-router-dom";
import PerformanceDetails from "./performance_details";
import SendTONextLevelDetails from "./next_level_details";
import RemoveFromThisLevel from "./remove_from_this_level";

const TableListForIndividual = (props) => {
  const {
    event_schedule_line_item_id,
    discipline_id,
    event_id,
    schedule,
    id,
    eventRegisteredPlayers,
  } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [isSingle, setIsSingle] = useState(false);

  const [next_level_rerender, setNextRerender] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [performanceModalOpen, setPerformanceModalOpen] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [participantsModal, setParticipantsModal] = useState(false);
  const [stateLevel, setStateLevel] = useState(false);
  const [removeBulkModal, setRemoveBulkModal] = useState(false);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState();
  const [rerender, setRerender] = useState(false);

  const [nextLevel, setNextLevel] = useState(false);
  const [removeLevel, setRemoveLevel] = useState(false);

  const roles = retrieve_item("role");

  const { loading, items, total_items } = useDynamicSelector(
    "getIndividualParticipations"
  );
  console.log("items", items);

  const { player_ids_list } = useDynamicSelector("get_registered_players");

  const get_individual_fixture_request = useAPIRequest(
    "getIndividualParticipations",
    get_individual_fixture_query
  );

  useEffect(() => {
    get_individual_fixture_request({});
  }, []);

  const handleParticipantsModalClose = () => {
    setParticipantsModal(false);
  };

  const handleAddParticipants = () => {
    setRerender(!rerender);
    setParticipantsModal(true);
  };

  const handleRemoveBulkModalOpen = () => {
    setRemoveBulkModal(true);
  };

  const handleNextLevelModalOpen = (item, is_single) => {
    setRerender(!rerender);
    setNextLevel(true);
    setActionItem(item);
    setIsSingle(is_single);
  };
  const handleAdd = (item) => {
    setRerender(!rerender);

    setActionItem(item);
    setModalOpen(true);
  };
  const handleRemoveLevelModalOpen = (item) => {
    setRerender(!rerender);

    setRemoveLevel(true);
    setActionItem(item);
  };

  const handleRemoveLevelModalClose = () => {
    setRemoveLevel(false);
  };

  const handlePerformanceModalOpen = (item) => {
    setPerformanceModalOpen(true);

    setRerender(!rerender);

    setActionItem(item);
    setModalOpen(true);
  };

  const handlePerformanceModalClose = () => {
    setPerformanceModalOpen(false);
  };

  const handleNextLevelModalClose = () => {
    setNextLevel(false);
  };

  const handleRemoveBulkModalClose = () => {
    setRemoveBulkModal(false);
  };

  const formatPlayerCode = (value) => {
    return value?.player?.player_code;
  };

  const formatPlayerName = (value) => {
    return value.player?.name;
  };
  const formatPosition = (value) => {
    return value?.position?.name;
  };

  const formatParameterList = (value) => {
    let temp = "";
    temp = value?.individual_participation_parameters?.map((x, i) => {
      return `${x.event_parameter?.name} - ${x.value}`;
    });
    return <>{temp?.join(", ")}</>;
  };

  const columns = [
    {
      title: t("Player Name"),
      dataIndex: "",
      key: "name",
      render: formatPlayerName,
      flex: 1,
    },
    {
      title: t("Player Code"),
      dataIndex: "",
      key: "code",
      render: formatPlayerCode,
      flex: 1,
    },
    {
      title: t("Position"),
      dataIndex: "",
      key: "position",
      render: formatPosition,
      flex: 1,
    },
    {
      title: t("Performance"),
      dataIndex: "",
      key: "performance",
      render: formatParameterList,
      flex: 1,
    },
    {
      title: t("Actions"),
      dataIndex: "",
      key: "actions",
      align: "center",
      width: 200,
      render: (record) => {
        console.log("roles", roles);

        return (
          <Row gutter={16}>
            <Col flex={1} style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="link"
                onClick={() => handlePerformanceModalOpen(record)}
                icon={<AiOutlineSetting color="#158d94" size="20" />}
              />
            </Col>
            <Col>
              <Button
                type="link"
                onClick={() => handleNextLevelModalOpen(record, true)}
                disabled={
                  (roles?.[0]?.name === "District" &&
                    player_ids_list?.length === 1) ||
                  (roles?.[0]?.name === "Zone" && player_ids_list?.length === 3)
                }
                icon={<FcPositiveDynamic color="#158d94" size="20" />}
              />
            </Col>
            {/* {roles?.[0]?.name === "Admin" && ( */}
            <Col flex={1} style={{ display: "flex", alignItems: "center" }}>
              <Button
                type="link"
                onClick={() => handleRemoveLevelModalOpen(record)}
                icon={<CiCircleRemove color="#dc3545" size="20" />}
              />
            </Col>
            {/* )} */}
          </Row>
        );
      },
    },
  ];
  const fetchData = (page, pageSize) => {
    setCurrent(page);
    setPageLimit(pageSize);
  };

  return (
    <>
      <div style={{ padding: "20px", width: "100%" }}>
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "12px" }}
        >
          <Button type="primary" onClick={handleAddParticipants}>
            {t("Add Participants")}
          </Button>
          {roles?.[0]?.name === "Admin" && (
            <Button
              danger
              onClick={handleRemoveBulkModalOpen}
              disabled={!alreadySelectedRows?.length}
            >
              {t("Remove From this Level")}
            </Button>
          )}
          <Button type="default" onClick={() => navigate(-1)}>
            {t("Back")}
          </Button>
        </div>

        <div
          style={{
            marginTop: 10,
          }}
        >
          <Table
            rowKey="team_id"
            loading={loading}
            dataSource={items}
            columns={columns}
            pagination={false}
            rowSelection={{
              selectedRowKeys: alreadySelectedRows,
              onChange: (selectedRowKeys) => {
                setAlreadySelectedRows(selectedRowKeys);
              },
              getCheckboxProps: (record) => ({
                disabled: record.is_send_to_next_level,
              }),
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Pagination
              total={total_items}
              showSizeChanger
              current={current}
              onChange={fetchData}
            />
          </div>
        </div>
      </div>
      <Modal
        title={actionItem ? t("Add Participants") : t("Add Participants")}
        visible={participantsModal}
        onCancel={handleParticipantsModalClose}
        footer={null} // We use null here to avoid having default footer buttons
        width={1000} // Adjusting the width to 1000px
      >
        <ParticipantsDetails
          label={actionItem ? t("Update") : t("Submit")}
          close={handleParticipantsModalClose}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          initialValues={actionItem}
          event_id={event_id}
          schedule={schedule}
          player_id={alreadySelectedRows}
          participantList={items}
          event_schedule_id={id}
          rerender={rerender}
        />
      </Modal>
      <Modal
        title={
          <>
            <span
              style={{
                fontSize: 20,
              }}
            >
              {t("Performance")}
            </span>
            <Divider />
          </>
        }
        visible={performanceModalOpen}
        onCancel={handlePerformanceModalClose}
        footer={null} // We use null here to avoid having default footer buttons
        width={600} // Adjusting the width to 1000px
      >
        <PerformanceDetails
          label={actionItem.id ? t("update") : t("add")}
          close={handlePerformanceModalClose}
          initialValues={actionItem}
          event_schedule_line_item_id={event_schedule_line_item_id}
          rerender={rerender}
        />
      </Modal>

      <Modal
        title={
          <>
            <span
              style={{
                fontSize: 20,
              }}
            >
              {t("Send to Next Level")}
            </span>
            <Divider />
          </>
        }
        visible={nextLevel}
        onCancel={handleNextLevelModalClose}
        footer={null} // We use null here to avoid having default footer buttons
        width={600} // Adjusting the width to 1000px
      >
        <SendTONextLevelDetails
          label={actionItem ? t("update") : t("Submit")}
          close={handleNextLevelModalClose}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          initialValues={actionItem}
          event_id={event_id}
          schedule={schedule}
          player_id={alreadySelectedRows}
          isSingle={isSingle}
          event_schedule_id={id}
          rerender={rerender}
          eventRegisteredPlayers={eventRegisteredPlayers}
        />
      </Modal>

      <Modal
        title={
          <>
            <span
              style={{
                fontSize: 20,
              }}
            >
              {t("Remove From this Level")}
            </span>
            <Divider />
          </>
        }
        visible={removeLevel}
        onCancel={handleRemoveLevelModalClose}
        footer={null} // We use null here to avoid having default footer buttons
        width={600} // Adjusting the width to 1000px
      >
        <RemoveFromThisLevel
          label={actionItem ? t("update") : t("Submit")}
          close={handleRemoveLevelModalClose}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          initialValues={actionItem}
          event_id={event_id}
          schedule={schedule}
          player_id={alreadySelectedRows}
          rerender={rerender}
        />
      </Modal>
    </>
  );
};

export default TableListForIndividual;
