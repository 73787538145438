import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { BsTwitterX } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import {
  goverment_of_tamil_nadu_link,
  ministry_of_Youth_Affairs_and_sports_link,
  sports_authority_of_India_link,
  tamil_nadu_physical_education_and_sports_university_link,
  TWITTER_LINK,
  VERTACE_LINK,
  YOUTUBE_LINK,
} from "@helpers/link_constant";
const Footer = () => {
  return (
    <footer className="footer bg-dark text-white pt-4 pb-4">
      <div className="container">
        <div className="row">
          <div className="col-md-4  text-md-left mb-4 mb-md-0 d-flex  flex-column justify-content-center ">
            <img
              src="https://sdat.tn.gov.in/static/media/SDAT%20New%20English%20Logo.2109c9b437628e4b77e4.png"
              alt="Logo"
              className="img-fluid footer-logo mb-2 w-25"
            />
            <p>Sports Development Authority of Tamil Nadu</p>
            <p>
              Jawaharlal Nehru Stadium,
              <br />
              Raja Muthiah Road, Periyamet,
              <br />
              Chennai - 600003
              <br />
              Tamil Nadu
            </p>
            <p>
              Phone: 9514000777
              <br />
              Email: sdat@tn.gov.in
              <br />© Copyright 2024
            </p>
          </div>
          <div className="col-md-4 text-start text-md-left mb-4 mb-md-0 d-flex flex-column gap-4">
            <h5 className="important-link-header">IMPORTANT LINKS</h5>
            <ul className="important-link-container">
              <li>
                <IoIosArrowForward />
                <a
                  href={ministry_of_Youth_Affairs_and_sports_link}
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ministry of Youth Affairs and Sports
                </a>
              </li>
              <li>
                <IoIosArrowForward />{" "}
                <a
                  href={sports_authority_of_India_link}
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sports Authority of India
                </a>
              </li>
              <li>
                <IoIosArrowForward />{" "}
                <a
                  href={goverment_of_tamil_nadu_link}
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Government of Tamil Nadu
                </a>
              </li>
              <li>
                <IoIosArrowForward />{" "}
                <a
                  href={
                    tamil_nadu_physical_education_and_sports_university_link
                  }
                  className="text-white"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Tamil Nadu Physical Education and Sports University
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4  text-md-left align-items-center d-flex flex-column gap-4">
            <h5 className="important-link-header"> Social Links</h5>
            <div className="d-flex flex-row gap-4 align-items-center">
              <a
                href={TWITTER_LINK}
                className="text-white mr-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsTwitterX size={16} />
              </a>
              <a
                href={YOUTUBE_LINK}
                className="text-white mr-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube size={25} />
              </a>
            </div>
            <p className="mt-2 powered-by-vertace-container">
              Powered By{" "}
              <a
                href={VERTACE_LINK}
                className="text-white"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    "https://blr1.vultrobjects.com/v-agrigl-assets/vertace_logo.png"
                  }
                  width={"80px"}
                  alt=""
                />
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
