import React, { useState } from "react";
import { Modal, Button, Form } from "antd";
import EventScheduleForm from "./event_schedule_form";

const EventScheduleModal = ({
  set_is_event_schedule_modal_open,
  is_event_schedule_modal_open,
  modal_type,
  selected_schedule_event_id,
  get_event_schedules,
}) => {
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(new Date());

  const showModal = () => {
    set_is_event_schedule_modal_open(true);
  };

  const handleCancel = () => {
    set_is_event_schedule_modal_open(false);
    form.resetFields();
    setStartDate("");
  };

  return (
    <>
      <Modal
        title={
          modal_type === "add" ? "Add Event Schedule" : "Edit Event Schedule"
        }
        open={is_event_schedule_modal_open}
        onCancel={handleCancel}
        footer={null}
        width={700}
      >
        <EventScheduleForm
          set_is_event_schedule_modal_open={set_is_event_schedule_modal_open}
          modal_type={modal_type}
          selected_schedule_event_id={selected_schedule_event_id}
          form={form}
          get_event_schedules={get_event_schedules}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      </Modal>
    </>
  );
};

export default EventScheduleModal;
