import { useAPIRequest } from "@helpers/hooks";
import { get_categories_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleCategoryForItem = ({ set_differently_able_id, form }) => {
  const { t } = useTranslation();
  const { Option } = Select;

  const { items: category_list } = useDynamicSelector("get_categories");

  const get_categories_request = useAPIRequest(
    "get_categories",
    get_categories_query
  );

  useEffect(() => {
    get_categories_request({}, true);
  }, []);

  const onchange = (value) => {
    set_differently_able_id(value);
  };

  return (
    <Col span={12}>
      <Form.Item
        name="category"
        label={t("வகை / Category")}
        rules={[{ required: true, message: t("Please select category") }]}
      >
        <Select
          placeholder={t("Select category")}
          onChange={onchange}
          allowClear
        >
          {category_list?.map((category, index) => (
            <Option value={category?.id} key={index}>
              {category?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleCategoryForItem;
