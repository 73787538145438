import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Table, Pagination, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import { FcPositiveDynamic } from "react-icons/fc";

import ParticipantsDetails from "./add_team_details";
import { useAPIRequest } from "@helpers/hooks";
import { get_event_registered_players_query } from "@services/graphql/event_register";
import {
  get_disciplines_query,
  get_one_event_query,
  get_players_query,
} from "@services/graphql";
import { get_participated_team_query } from "@services/graphql/event_schedule_team";
import { retrieve_item } from "@helpers/functions";
import RemoveFromThisLevel from "./remove_from_this_schedule";
import SendToStateLevel from "../sent_to_state_level/sent_to_state_level";
import { MdOutlineMoveUp } from "react-icons/md";
import SentToNextLevel from "../sent_to_state_level/sent_to_next_level";

const AddedTeamListForFixture = (props) => {
  const dispatch = useDispatch();
  const {
    event_id,
    event_schedule_line_item_id,
    discipline_id,
    id,
    schedule_name,
    event_schedule,
  } = props;

  const navigate = useNavigate();
  const roles = retrieve_item("name");

  const { loading, items, total_items } = useDynamicSelector(
    "getEventScheduleAddedTeams"
  );
  const { team_list } = useDynamicSelector("get_registered_players");

  const { item: event } = useDynamicSelector("get_event");
  const { status: updateSuccess } = useDynamicSelector(
    "updateIndividualParticipation"
  );

  const { t } = useTranslation();
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [next_level_rerender, setNextRerender] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [actionItem, setActionItem] = useState({});
  const [participantsModal, setParticipantsModal] = useState(false);
  const [stateLevel, setStateLevel] = useState(false);
  const [removeBulkModal, setRemoveBulkModal] = useState(false);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState();
  const [rerender, setRerender] = useState(false);
  const [send_to_next_level, set_send_to_next_level] = useState(false);

  const event_registered_players_request = useAPIRequest(
    "getEventRegisteredPlayers",
    get_event_registered_players_query
  );
  const get_disciplines_request = useAPIRequest(
    "get_disciplines",
    get_disciplines_query
  );
  const get_event = useAPIRequest("get_event", get_one_event_query);
  const get_participated_team_request = useAPIRequest(
    "getEventScheduleAddedTeams",
    get_participated_team_query
  );
  const get_player_query_request = useAPIRequest(
    "get_players",
    get_players_query
  );
  useEffect(() => {
    event_registered_players_request(
      {
        event_id: event_id,
        gender: event_schedule?.[0]?.gender,
        category_id: event_schedule?.[0]?.category?.id,
        type: event_schedule?.[0]?.event_schedule_line_items?.[0]?.event.type,
        gender: event_schedule?.[0]?.gender,
        differently_abled_id: event_schedule?.[0]?.differently_abled_id || "",
      },
      true
    );
    get_disciplines_request({}, true);
  }, [dispatch, event_id, event_schedule]);
  console.log("actionItem", actionItem?.team_id);

  useEffect(() => {
    if (team_list?.length) {
      setAlreadySelectedRows(team_list);
    }
  }, [team_list]);

  useEffect(() => {
    if (event_id) {
      get_event({ id: event_id }, true);
    }
  }, [dispatch, event_id]);

  useEffect(() => {
    if (event_schedule_line_item_id) {
      get_participated_team_request(
        {
          json: {
            event_schedule_line_item_id: event_schedule_line_item_id,
            page_number: current,
            page_limit: pageLimit,
            search_string: "",
          },
        },
        true
      );
    }
  }, [dispatch, event_schedule_line_item_id, current, pageLimit]);

  const handleAddParticipants = () => {
    setRerender(!rerender);
    setParticipantsModal(true);
  };

  const handleRemoveBulkModalOpen = () => {
    setRemoveBulkModal(true);
  };

  const handleRemoveBulkModalClose = () => {
    setRemoveBulkModal(false);
  };

  const handleNextLevelModalOpen = (record) => {
    set_send_to_next_level(true);
    setActionItem({
      team_id: record.team_id,
      team: record.team,
    });
  };

  const handleStateLevelModalOpen = (record) => {
    console.log("record222", record);

    setStateLevel(true);
    setNextRerender(!next_level_rerender);
    setActionItem({
      team_id: record.team_id,
      team: record.team,
    });

    get_player_query_request(
      {
        event_id: event_id,
        team_id: record.team_id,
        category_id: event_schedule?.[0]?.category?.id,
        gender: event_schedule?.[0]?.gender,
        page_number: 1,
        page_limit: 50,
      },
      true
    );
  };

  const handleStateLevelModalClose = () => {
    setStateLevel(false);
    set_send_to_next_level(false);
  };

  const columns = [
    {
      title: t("Team Name"),
      dataIndex: "team",
      key: "team_name",
      render: (record) => record.name,
    },
    {
      title: t("Team Code"),
      dataIndex: "team",
      key: "team_code",
      render: (record) => record.code,
    },
    {
      title: t("Actions"),
      dataIndex: "",
      key: "actions",
      render: (record) => (
        <div style={{ gap: "20px", display: "flex" }}>
          <Button
            icon={<FcPositiveDynamic />}
            onClick={() => handleStateLevelModalOpen(record)}
            // disabled={
            //   (roles?.[0]?.name === "District" && team_list?.length === 1) ||
            //   (roles?.[0]?.name === "Zone" && team_list?.length === 3)
            // }
          />
          <Button
            icon={<MdOutlineMoveUp color="#0d6659" />}
            onClick={() => handleNextLevelModalOpen(record)}
            // disabled={
            //   (roles?.[0]?.name === "District" && team_list?.length === 1) ||
            //   (roles?.[0]?.name === "Zone" && team_list?.length === 3)
            // }
          />
        </div>
      ),
    },
  ];

  const fetchData = (page, pageSize) => {
    get_participated_team_request(
      {
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_number: page,
          page_limit: pageSize,
        },
      },
      true
    );
    setCurrent(page);
    setPageLimit(pageSize);
  };

  return (
    <>
      <div style={{ padding: "20px", width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginBottom: "10px",
          }}
        >
          <Button type="primary" onClick={handleAddParticipants}>
            {t("Add Team")}
          </Button>
          {roles !== "Admin" && (
            <Button
              danger
              onClick={handleRemoveBulkModalOpen}
              disabled={!alreadySelectedRows?.length}
            >
              {t("Remove From this Level")}
            </Button>
          )}
          <Button type="default" onClick={() => navigate(-1)} danger>
            {t("Back")}
          </Button>
        </div>

        <>
          <Table
            rowKey="team_id"
            loading={loading}
            dataSource={items}
            columns={columns}
            pagination={false}
            rowSelection={{
              selectedRowKeys: alreadySelectedRows,
              onChange: (selectedRowKeys) => {
                setAlreadySelectedRows(selectedRowKeys);
              },
              getCheckboxProps: (record) => ({
                disabled: record.is_send_to_next_level,
              }),
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <Pagination
              total={total_items}
              showSizeChanger
              current={current}
              onChange={fetchData}
            />
          </div>
        </>
      </div>

      <Modal
        open={participantsModal}
        onCancel={() => setParticipantsModal(false)}
        title={t("Add Team")}
        footer={null}
        width={1000}
      >
        <ParticipantsDetails
          closeModal={() => setParticipantsModal(false)}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          event_id={event_id}
          schedule={props?.location?.state}
          player_id={alreadySelectedRows}
          participantList={items}
          event_schedule_id={id}
          actionType="add"
          schedule_name={schedule_name}
          rerender={rerender}
          event_schedule={event_schedule}
        />
      </Modal>

      <Modal
        open={removeBulkModal}
        onCancel={handleRemoveBulkModalClose}
        title={t("Remove From this Level")}
        footer={null}
        width={800}
      >
        <RemoveFromThisLevel
          closeModal={handleRemoveBulkModalClose}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          event_id={event_id}
          team_id={alreadySelectedRows}
          schedule={props?.location?.state}
          schedule_name={schedule_name}
        />
      </Modal>

      <Modal
        open={stateLevel}
        onCancel={handleStateLevelModalClose}
        title={t("Send to State Level")}
        footer={null}
        width={1000}
      >
        <SendToStateLevel
          closeModal={handleStateLevelModalClose}
          discipline_id={discipline_id}
          event_schedule_line_item_id={event_schedule_line_item_id}
          event_id={event_id}
          actionItem={actionItem}
        />
      </Modal>

      <Modal
        open={send_to_next_level}
        onCancel={handleStateLevelModalClose}
        title={t("Send to Next Level")}
        footer={null}
        width={500}
      >
        <SentToNextLevel
          handleStateLevelModalClose={handleStateLevelModalClose}
        />
      </Modal>
    </>
  );
};

export default AddedTeamListForFixture;
