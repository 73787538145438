import React from 'react'

function PlayerInstruction() {
  return (
    <div className="player-instruction-container" style={{
        padding:"30px",
        display: "flex",
        flexDirection:"column",
        width: "100%",
        alignItems: "center",
        justifyContent:"center"
      }}>
        <ul style={{ listStyleType: "none", padding: 0, 
          display: "flex",
          flexDirection:"column",
          textAlign:"justify", 
          fontWeight:"bold",
          gap:"25px"
        }}>
          <li><span style={{ color: 'red' }}>*</span> The captain or team leader should login to the portal.</li>
          <li><span style={{ color: 'red' }}>*</span> The Captain (Player) can add the Team name (mention the District with the team name Eg Ariyalur- Teamname)</li>
          <li><span style={{ color: 'red' }}>*</span> Then click the Add Player button</li>
          <li><span style={{ color: 'red' }}>*</span> The Add Player Pop-up menu will Open. Enter the player's mobile number, then submit.</li>
          <li><span style={{ color: 'red' }}>*</span> The captain should add the maximum number of players for their Team</li>
          <li><span style={{ color: 'red' }}>*</span> Before adding players, all the players should register individually and get the registration ID, including team captain</li>
          <li><span style={{ color: 'red' }}>*</span> Before team formation, players should register in the Same District, Same Category and Same Discipline. Then only the captain can include the players in their team.</li>
          <li><span style={{ color: 'red' }}>*</span> If a player is in another team, then can't add in a new team.</li>
        </ul>
      </div>
  )
}

export default  PlayerInstruction