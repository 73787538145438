import React from "react";

const RegisterTermsContents = () => {
  return (
    <div className="content-container">
    <h1 className="main-title">Tamil Nadu Chief Minister’s Trophy – 2024</h1>
    <h2 className="subtitle">Important Instructions</h2>
    <p className="intro-text">
      தமிழ்நாடு முதலமைச்சர் கோப்பை போட்டியில் கலந்து கொள்வதற்கான பொதுவான முக்கிய அறிவுரைகள்
    </p>

    <h3 className="category-title">School Category / பள்ளி பிரிவு</h3>
    <ol className="category-list">
      <li className="category-item">
        The students are eligible to represent in only the district where they are studying. They will not be permitted to participate in their native district under school category if they are not studying in their native district.
        <br />
        <span className="tamil-content">
          பள்ளி மாணவ மாணவியர்கள் தாங்கள் பயிலும் மாவட்டம் சார்பாக மட்டுமே கலந்து கொள்ள இயலும் மற்றும் வேறு மாவட்டம் சார்பாக கலந்து கொள்ள அனுமதிக்க இயலாது
        </span>
      </li>
      <li className="category-item">
        The students should upload their Bonafide Certificates / Id card while enrolling for participation in Chief Minister’s Trophy.
        <br />
        <span className="tamil-content">
          போட்டிகளுக்காக இணையதளத்தில் விண்ணப்பிக்கும் போது தாங்கள் பயிலும் பள்ளியிலிருந்து Bonafide certificate / ID card பெற்று கட்டாயமாக பதிவேற்றம் செய்திட வேண்டும்
        </span>
      </li>
      <li className="category-item">
        They should also produce the Bonafide certificate while participating in the competitions.
        <br />
        <span className="tamil-content">
          போட்டிகள் நடைபெறும் போது Bonafide certificate / ID card கட்டாயமாக சமர்ப்பித்திடுதல் வேண்டும்
        </span>
      </li>
      <li className="category-item">
        The students should be in the age group of 12 to 19
        <br />
        <span className="tamil-content">
          வயது வரம்பு – 12 வயது முதல் 19 வயது வரை
        </span>
      </li>
    </ol>

    <h3 className="category-title">College Category / கல்லூரி பிரிவு</h3>
    <ol className="category-list">
      <li className="category-item">
        The students are eligible to represent in only the district where they are studying. They will not be permitted to participate in their native district under College category if they are not studying in their native district.
        <br />
        <span className="tamil-content">
          கல்லூரி மாணவ மாணவியர்கள் தாங்கள் பயிலும் மாவட்டம் சார்பாக மட்டுமே கலந்து கொள்ள இயலும் மற்றும் வேறு மாவட்டம் சார்பாக கலந்து கொள்ள அனுமதிக்க இயலாது
        </span>
      </li>
      <li className="category-item">
        The students should upload their Bonafide Certificates / Id card while enrolling for participation in Chief Minister’s Trophy.
        <br />
        <span className="tamil-content">
          போட்டிகளுக்காக இணையதளத்தில் விண்ணப்பிக்கும் போது தாங்கள் பயிலும் பள்ளியிலிருந்து Bonafide certificate / ID card பெற்று கட்டாயமாக பதிவேற்றம் செய்திட வேண்டும்
        </span>
      </li>
      <li className="category-item">
        They should also produce the Bonafide certificate while participating in the competitions.
        <br />
        <span className="tamil-content">
          போட்டிகள் நடைபெறும் போது Bonafide certificate / ID card கட்டாயமாக சமர்ப்பித்திடுதல் வேண்டும்
        </span>
      </li>
      <li className="category-item">
        The students should be in the age group of 17 to 25
        <br />
        <span className="tamil-content">
          வயது வரம்பு – 17 வயது முதல் 25 வயது வரை
        </span>
      </li>
    </ol>

    <h3 className="category-title">Differently Abled Category / மாற்றுத்திறனாளிகள் பிரிவு</h3>
    <ol className="category-list">
      <li className="category-item">
        They should upload their Aadhar Card and the details of their Differently abled certificates.
        <br />
        <span className="tamil-content">
          இப்பிரிவில் பங்கேற்கும் விளையாட்டு வீரர்கள் ஆதார் அட்டையில் குறிப்பிட்டுள்ள மாவட்டத்தில் மட்டுமே போட்டியில் பங்கேற்க இயலும்.
        </span>
      </li>
      <li className="category-item">
        They will be permitted to participate in the district where they are studying or working or residing
        <br />
        <span className="tamil-content">
          இப்பிரிவில் பங்கேற்கும் பள்ளி பயிலும் / கல்லுரி பயிலும் / பணிபுரியும் அல்லது வசிக்கும் மாவட்டத்தின் சார்பில் போட்டிகளில் கலந்து கொள்ளலாம். அதற்கான உரிய அடையாள ஆவணங்கள் / சான்றிதழ்கள் பதிவேற்றம் செய்திடல் வேண்டும். போட்டியின் போது சமர்ப்பித்திடல் வேண்டும்.
        </span>
      </li>
      <li className="category-item">
        No Age limit
        <br />
        <span className="tamil-content">
          வயது வரம்பு கிடையாது
        </span>
      </li>
    </ol>

    <h3 className="category-title">General Category / பொதுப்பிரிவு</h3>
    <ol className="category-list">
      <li className="category-item">
        They should be in the age of 15 to 35 years.
        <br />
        <span className="tamil-content">
          வயது வரம்பு 15 முதல் 35 வயது வரை
        </span>
      </li>
      <li className="category-item">
        They should upload their Aadhar Card. They are eligible to participate in the district in which they possess the Aadhar card.
        <br />
        <span className="tamil-content">
          ஆதார் அட்டை கண்டிப்பாக பதிவேற்றம் செய்திடல் வேண்டும். ஆதார் அட்டை சார்ந்த மாவட்டத்தில் மட்டுமே போட்டியில் பங்கேற்க வேண்டும்.
        </span>
      </li>
      <li className="category-item">
        Registration and participation should be done in only one District.
        <br />
        <span className="tamil-content">
          ஒரு மாவட்டத்தில் மட்டுமே பதிவு செய்து அம் மாவட்டத்தில் மட்டுமே போட்டியில் பங்கேற்க வேண்டும்
        </span>
      </li>
      <li className="category-item">
        SDAT Coaches and Officers are not eligible to participate
        <br />
        <span className="tamil-content">
          தமிழ்நாடு விளையாட்டு மேம்பாட்டு ஆணையத்தில் பணிபுரியும் அலுவலர்கள் மற்றும் பயிற்றுநர்கள் போட்டியில் கலந்து கொள்ள அனுமதி கிடையாது
        </span>
      </li>
      <li className="category-item">
        They should be a native of Tamil Nadu or should have resided in Tamil Nadu for a minimum period of 5 years.
        <br />
        <span className="tamil-content">
          இப்பிரிவில் பங்குபெறுபவர்கள் தமிழ்நாட்டை சார்ந்தவராக இருத்தல் வேண்டும். மற்ற மாநிலங்களை சார்ந்தவராக இருப்பின் குறைந்தபட்சம் 5 ஆண்டுகள் தமிழ்நாட்டில் வசித்தவராக இருத்தல் வேண்டும். உரிய ஆவணங்கள் பதிவேற்றம் செய்திடல் வேண்டும்.
        </span>
      </li>
    </ol>

    <h3 className="category-title">Government Employees / அரசு ஊழியர்கள்</h3>
    <ol className="category-list">
      <li className="category-item">
        They should upload their Employee Card details and should produce their Department / Institution Identity Card.
        <br />
        <span className="tamil-content">
          அரசு ஊழியர்கள் தங்களது அலுவலக அடையாள அட்டையினை கண்டிப்பாக பதிவேற்றம் செய்திடல் வேண்டும் மற்றும் போட்டிகளின் போது சமர்ப்பித்திடல் வேண்டும்.
        </span>
      </li>
      <li className="category-item">
        Government Employees are eligible to participate only in the district in which they are working at the time of conducting the competitions.
        <br />
        <span className="tamil-content">
          அரசு ஊழியர்கள் தாங்கள் பணிபுரியில் மாவட்டம் சார்பாக மட்டுமே பங்குபெற முடியும். பணிபுரியும் மாவட்டம் தவிர வேறு மாவட்டம் சார்பாக பங்கு பெற அனுமதி கிடையாது
        </span>
      </li>
      <li className="category-item">
        Outsourcing employees / Employees working under contract are not eligible to participate.
        <br />
        <span className="tamil-content">
          வெளிமுகமை / ஒப்பந்த ஊழியர்கள் பங்கு பெற அனுமதி கிடையாது
        </span>
      </li>
      <li className="category-item">
        SDAT Coaches and Officers are not eligible to participate
        <br />
        <span className="tamil-content">
          தமிழ்நாடு விளையாட்டு மேம்பாட்டு ஆணையத்தில் பணிபுரியும் அலுவலர்கள் மற்றும் பயிற்றுநர்கள் போட்டியில் கலந்து கொள்ள அனுமதி கிடையாது
        </span>
      </li>
    </ol>
  </div>
  );
};

export default RegisterTermsContents;
