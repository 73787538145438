import { Modal } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UpdateForm from "./update_form";
import { dynamic_clear } from "@services/redux";
import { useDispatch } from "react-redux";

const UpdateModal = ({
  is_modal_open,
  set_is_update_modal_open,
  player_id,
  set_player_id,
  get_player_list,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //   const handleOk = () => {
  //     set_is_update_modal_open(false);
  //     navigate("/cmtrophy/player-login");
  //   };
  const on_cancel = () => {
    dispatch(dynamic_clear("get_min_max_partcipants_count"));
    dispatch(dynamic_clear("get_player"));
    set_is_update_modal_open(false);
  };

  return (
    <Modal
      open={is_modal_open}
      // onCancel={() => set_is_update_modal_open(false)}
      onCancel={on_cancel}
      footer={null}
      width={1200}
    >
      <UpdateForm
        update_player_id={player_id}
        set_player_id={set_player_id}
        set_is_update_modal_open={set_is_update_modal_open}
        get_player_list={get_player_list}
      />
    </Modal>
  );
};

export default UpdateModal;
