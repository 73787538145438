import { TiThMenu } from "react-icons/ti";
import { clear_local_storage, retrieve_item } from "@helpers/functions";
import { Drawer, Dropdown, Menu, Space } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { dynamic_clear } from "@services/redux";

const TopHeader = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modal, set_modal] = useState(false);
  const mobile = retrieve_item("mobile");
  const name = retrieve_item("name");
  const items = [
    {
      key: "logout",
      label: "Logout",
    },
  ];
  const menu_items = [
    {
      key: "home",
      label: t("home"),
    },
    {
      key: "officer_login",
      label: t("officer_login"),
    },
    {
      key: "events",
      label: t("events"),
    },
    {
      key: "player_login",
      label: t("Player Registration"),
    },
    {
      key: "event_schedule",
      label: t("Events Schedule"),
    },
  ];
  const onClick = (e) => {
    if (e.key === "logout") {
      dispatch(dynamic_clear("get_registered_user"));
      localStorage.clear();
      navigate("/cmtrophy/");
    }
  };

  const LoginTitleContainer = ({ tamil_header, english_header, path, url }) => {
    const handle_redirect = () => {
      if (url) {
        window.open(url, "blank");
      } else {
        navigate(path);
      }
    };
    return (
      <div
        className="title-container home-top-login-container"
        onClick={handle_redirect}
      >
        <h5>{tamil_header}</h5>
        <h5>{english_header}</h5>
      </div>
    );
  };

  const LoginTitleContainerPDF = ({ tamil_header, english_header, path }) => {
    const handle_redirect = () => {
      window.open(
        "https://v-sdat-dev.blr1.vultrobjects.com/2024/09/95fc7ae8-e369-42b6-b604-94dd2695c820.pdf",
        "_blank"
      );
    };
    return (
      <div
        className="title-container home-top-login-container"
        onClick={handle_redirect}
      >
        <h5>{tamil_header}</h5>
        <h5>{english_header}</h5>
      </div>
    );
  };

  const handle_modal = () => {
    set_modal(true);
  };

  const handle_close = () => {
    set_modal(false);
  };
  const handle_menu = (value) => {
    switch (value?.key) {
      case "home":
        navigate("/cmtrophy/");
        set_modal(false);
        break;
      case "player_login":
        navigate("/cmtrophy/player-login");
        set_modal(false);
        break;
      case "events":
        window.open(
          "https://v-sdat-dev.blr1.vultrobjects.com/2024/09/95fc7ae8-e369-42b6-b604-94dd2695c820.pdf",
          "_blank"
        );
        break;
      case "event_schedule":
        window.open(
          "https://sdatservices.tn.gov.in/#/cmt-events/cmt-event",
          "blank"
        );
        set_modal(false);
        break;
      case "officer_login":
        navigate("/cmtrophy/officer-login");
        set_modal(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="home-top-main-container">
      <div className="home-left-container">
        <div className="mobile-menu-container">
          <TiThMenu
            color={"white"}
            className="menu-icon"
            onClick={handle_modal}
          />
        </div>
        <img
          src={
            "https://sdat.tn.gov.in/static/media/SDAT%20New%20English%20Logo.2109c9b437628e4b77e4.png"
          }
          alt=""
        />
        <div className="title-container">
          <h3>{"முதலமைச்சர் கோப்பை "}</h3>
          <h3>{"விளையாட்டுப் போட்டிகள் 2024 - 2025"}</h3>
        </div>
      </div>
      <div className="title-container mobile-title">
        <h3>{"முதலமைச்சர் கோப்பை "}</h3>
        <h3>{"விளையாட்டுப் போட்டிகள்"}</h3>
        <h3>{"2024 - 2025"}</h3>
      </div>
      <div className="home-right-container">
        {name ? (
          <Dropdown
            menu={{
              items,
              onClick,
            }}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <h4 style={{ color: "white", cursor: "pointer" }}>{name}</h4>
              </Space>
            </a>
          </Dropdown>
        ) : mobile ? (
          <div className="home-top-login-container">
            <h4>{mobile}</h4>
          </div>
        ) : (
          <>
            <LoginTitleContainer
              english_header={"Home"}
              tamil_header={"முகப்பு"}
              path=""
            />
            <LoginTitleContainerPDF
              english_header={"Event"}
              tamil_header={"விளையாட்டு பிரிவு"}
            />
            <LoginTitleContainer
              english_header={"Officer Login"}
              tamil_header={"அதிகாரி உள்நுழைய"}
              path="/cmtrophy/officer-login"
            />

            <LoginTitleContainer
              english_header={"Event Schedule"}
              tamil_header={"நிகழ்வு அட்டவணை"}
              // path="/cmtrophy/view-event-schedule"
              url="https://sdatservices.tn.gov.in/#/cmt-events/cmt-event"
            />

            {/* <LoginTitleContainer
              english_header={"Player Registration"}
              tamil_header={"விளையாட்டு வீரர் பதிவு"}
              path="/cmtrophy/player-login"
            /> */}
          </>
        )}
        <img
          src={
            "https://sdat.tn.gov.in/static/media/government_logo.a2d46a9a5d1f1cfdd0f5.png"
          }
          alt=""
        />
      </div>
      <Drawer open={modal} onClose={handle_close} placement="left">
        <Menu items={menu_items} onClick={handle_menu} />
      </Drawer>
    </div>
  );
};

export default TopHeader;
