import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

const RegisterEducationSector = () => {
  const { t } = useTranslation();
  const sector_list = [
    {
      id: "school",
      name: "School",
    },
    {
      id: "college",
      name: "College",
    },
  ];
  return (
    <div>
      <Form.Item
        name="education_sector"
        label={t("education_sector")}
        rules={[{ required: true, message: t("education_sector_required") }]}
      >
        <Select
          options={sector_list}
          fieldNames={{ label: "name", value: "id" }}
          filterOption={(input, option) =>
            option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        />
      </Form.Item>
    </div>
  );
};

export default RegisterEducationSector;
