import { colorCode } from "@helpers/constants";
import { useAPIRequest } from "@helpers/hooks";
import { get_dashboard_count_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import Loader from "@views/components/common/loader";
import DashboardCard from "@views/components/officer_dashboard_card/dashboard_card";
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

const OfficerDashboard = () => {
  const [individual_disciplines, set_individual_disciplines] = useState([]);
  const [team_disciplines, set_team_disciplines] = useState([]);
  const { items, loading } = useDynamicSelector("get_dashboard_count");
  let get_dashboard_count = useAPIRequest(
    "get_dashboard_count",
    get_dashboard_count_query
  );

  useEffect(() => {
    get_dashboard_count({}, true);
  }, []);
  const colCount = 4;

  useEffect(() => {
    if (items && items.length > 0) {
      set_individual_disciplines(items?.filter((x) => x.type === "individual"));
      set_team_disciplines(items?.filter((x) => x.type === "team"));
    }
  }, [items]);

  return (
    <>
      {loading ? (
        <div
          style={{
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div className="dashboard-page-main-container">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <h2>Individual Events</h2>
            </Col>
            {individual_disciplines.map((x, i) => (
              <Col key={x?.discipline_name} span={24 / colCount}>
                <DashboardCard discipline={x} bgImage={colorCode[i % 5]} />
              </Col>
            ))}
          </Row>
          <Row gutter={[16, 16]} style={{ marginTop: "30px" }}>
            <Col span={24}>
              <h2>Team Events</h2>
            </Col>
            {team_disciplines.map((x, i) => (
              <Col key={x?.discipline_name} span={24 / colCount}>
                <DashboardCard discipline={x} bgImage={colorCode[i % 5]} />
              </Col>
            ))}
          </Row>
        </div>
      )}
    </>
  );
};

export default OfficerDashboard;
