import React, { useState, useEffect } from "react";
import { useDynamicSelector } from "@services/redux";
import { DatePicker, Form, Input, Table } from "antd";
import dayjs from "dayjs";

const UpdateTeamPlayerList = ({ form }) => {
  const { min_allowed_participants, max_allowed_participants } =
    useDynamicSelector("get_min_max_partcipants_count");
  const player_data = useDynamicSelector("get_player");
  // Calculate current players and remaining slots
  const currentPlayerCount = player_data?.additional_players?.length || 0;
  const remainingSlots = max_allowed_participants - 1 - currentPlayerCount;

  console.log("max_allowed_participants", max_allowed_participants);

  // Create initial player data
  const initialData =
    player_data?.additional_players?.map((player, i) => ({
      key: i.toString(),
      s_no: i + 1,
      player_name: player.name || "",
      dob: player.dob ? dayjs(player.dob) : null,
      age: player.dob ? dayjs().diff(dayjs(player.dob), "year") : "",
      mobile_no: player.mobile || "",
      aadhar_no: player.aadhar_no || "",
      address: player.address1 || "",
      id: player.id || undefined,
    })) || [];

  // Add empty rows for new players based on remaining slots
  const additionalPlayers = Array.from({ length: remainingSlots }).map(
    (_, i) => ({
      id: undefined,
      key: (currentPlayerCount + i).toString(),
      s_no: currentPlayerCount + i + 1,
      player_name: "",
      dob: null,
      age: "",
      mobile_no: "",
      aadhar_no: "",
      address: "",
    })
  );

  // Combine current players and additional empty rows
  console.log("initialData", initialData, additionalPlayers);

  const finalPlayerData = initialData.concat(additionalPlayers);

  const [dataSource, setDataSource] = useState([]);
  console.log("finalPlayerData", max_allowed_participants);

  useEffect(() => {
    if (finalPlayerData.length > 0) {
      setDataSource(finalPlayerData);
      form.setFieldsValue({
        player_data: finalPlayerData.map((player) => ({
          id: player.id,
          name: player.player_name,
          dob: player.dob,
          mobile: player.mobile_no,
          aadhar_no: player.aadhar_no,
          address1: player.address,
        })),
      });
    }
  }, [form, max_allowed_participants]);

  const handleFieldChange = (key, field, value) => {
    const newData = dataSource.map((item) => {
      if (item.key === key) {
        item[field] = value;
        if (field === "dob") {
          item.age = dayjs().diff(dayjs(value), "year");
        }
      }
      return item;
    });
    setDataSource(newData);
  };

  const columns = [
    { title: "S.No", dataIndex: "s_no", key: "s_no" },
    {
      title: "Player Name",
      dataIndex: "",
      key: "player_name",
      render: (_, record, index) => (
        <>
          {console.log("player_name", _, record, index)}
          <Form.Item
            name={["player_data", index, "id"]}
            hidden
            initialValue={record.id}
          >
            <Input defaultValue={record?.id || undefined} />
          </Form.Item>
          <Form.Item
            name={["player_data", index, "name"]}
            rules={[
              {
                required: index < min_allowed_participants,
                message: "Player Name is required",
              },
            ]}
            initialValue={record.player_name}
          >
            <Input
            // onChange={(e) =>
            //   handleFieldChange(record.key, "player_name", e.target.value)
            // }
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: "DOB",
      dataIndex: "dob",
      key: "dob",
      width: 130,
      render: (_, record, index) => (
        <Form.Item
          name={["player_data", index, "dob"]}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "DOB is required",
            },
          ]}
          initialValue={record.dob}
        >
          <DatePicker
            format="DD-MM-YYYY"
            // onChange={(date) => handleFieldChange(record.key, "dob", date)}
          />
        </Form.Item>
      ),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
      width: 70,
      render: (_, record) => <Input value={record.age} disabled />,
    },
    {
      title: "Mobile No",
      dataIndex: "mobile_no",
      key: "mobile_no",
      width: 135,
      render: (_, record, index) => (
        <Form.Item
          name={["player_data", index, "mobile"]}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Mobile No is required",
            },
            {
              validator: (_, value) => {
                if (!value || /^[0-9]{10}$/.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Mobile No must be exactly 10 digits")
                );
              },
            },
          ]}
          initialValue={record.mobile_no}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={10}
            // onChange={(e) =>
            //   handleFieldChange(record.key, "mobile_no", e.target.value)
            // }
          />
        </Form.Item>
      ),
    },
    {
      title: "Aadhar No",
      dataIndex: "aadhar_no",
      key: "aadhar_no",
      width: 150,
      render: (_, record, index) => (
        <Form.Item
          name={["player_data", index, "aadhar_no"]}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Aadhar Number is required",
            },
            {
              pattern: /^[0-9]{12}$/,
              message: "Aadhar Number must be exactly 12 digits",
            },
          ]}
          initialValue={record.aadhar_no}
        >
          <Input
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={12}
            // onChange={(e) =>
            //   handleFieldChange(record.key, "aadhar_no", e.target.value)
            // }
          />
        </Form.Item>
      ),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (_, record, index) => (
        <Form.Item
          name={["player_data", index, "address1"]}
          rules={[
            {
              required: index < min_allowed_participants,
              message: "Address is required",
            },
          ]}
          initialValue={record.address}
        >
          <Input.TextArea
          // onChange={(e) =>
          //   handleFieldChange(record.key, "address", e.target.value)
          // }
          />
        </Form.Item>
      ),
    },
  ];

  return (
    <div>
      <h2>Add Team Player</h2>
      <Table
        className="player-list-table"
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default UpdateTeamPlayerList;
