import { gql } from "@apollo/client";

export const create_team_mutation = gql`
  mutation create_team($data: team_input) {
    create_team(data: $data) {
      id
      status
      error {
        message
        status
      }
    }
  }
`;

export const add_team_player_mutation = gql`
  mutation add_team_player($team_id: String!, $player_code: String!) {
    add_team_player(team_id: $team_id, player_code: $player_code) {
      id
      status
      error {
        message
        status
      }
    }
  }
`;

export const get_team_players_query = gql`
  query get_team_players($id: String!) {
    get_team_players(id: $id) {
      id
      name
      discipline_max_count
      players {
        id
        name
        team_id
        is_captain
      }
    }
  }
`;

export const delete_team_mutation = gql`
  mutation deleteAllPlayerInTeam($team_id: String!) {
    delete_team: deleteAllPlayerInTeam(team_id: $team_id) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const delete_player_mutation = gql`
  mutation deletePlayerInTeam($id: String!, $team_id: String!) {
    delete_player: deletePlayerInTeam(id: $id, team_id: $team_id) {
      id
      status
      error {
        status
      }
    }
  }
`;

export const get_teams_query = gql`
  query getTeams(
    $page_number: Int
    $page_limit: Int
    $search_string: String
    $district_id: String
    $category_id: String
    $discipline_id: String
    $gender: String
    $event_id: String
    $is_participated: Boolean
  ) {
    getTeams(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      district_id: $district_id
      category_id: $category_id
      discipline_id: $discipline_id
      gender: $gender
      event_id: $event_id
      is_participated: $is_participated
    ) {
      items {
        id
        name
        gender
        player_count
        team_code
        is_participated
        district {
          id
          name
        }
        category {
          id
          name
        }
        discipline {
          id
          name
        }
        event {
          id
          name
        }
        error {
          status
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_teams_without_pagination_query = gql`
  query get_all_team_report(
    $search_string: String
    $district_id: String
    $category_id: String
    $discipline_id: String
    $gender: String
    $event_id: String
    $is_participated: Boolean
  ) {
    get_teams_without_pagination: get_all_team_report(
      search_string: $search_string
      district_id: $district_id
      category_id: $category_id
      discipline_id: $discipline_id
      gender: $gender
      event_id: $event_id
      is_participated: $is_participated
    ) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const query_get_team_details = gql`
  query getTeam($id: String!) {
    getTeam(id: $id) {
      id
      name
    }
  }
`;

export const mutation_update_many_teams_participation = gql`
  mutation updateManyTeamsParticipation(
    $ids_list: [String]!
    $is_participated: Boolean!
  ) {
    updateManyTeamsParticipation(
      ids_list: $ids_list
      is_participated: $is_participated
    ) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
