import { gql } from "@apollo/client";

export const get_disciplines_schedule_query = gql`
  query get_filtered_disciplines(
    $gender: String!
    $category_id: String!
    $differently_abled_id: String
  ) {
    get_disciplines_schedule: get_filtered_disciplines(
      gender: $gender
      category_id: $category_id
      differently_abled_id: $differently_abled_id
    ) {
      items {
        id
        name
        error {
          status
          message
        }
      }
    }
  }
`;

export const get_events_schedule_query = gql`
  query get_events(
    $discipline_id: String
    $gender: String
    $differently_abled_id: String
    $category_id: String
  ) {
    get_events_schedule: get_events(
      discipline_id: $discipline_id
      gender: $gender
      category_id: $category_id
      differently_abled_id: $differently_abled_id
    ) {
      items {
        id
        name
        code
        discipline_id
        type
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_rounds_query = gql`
  query {
    getRounds {
      items {
        id
        name
      }
    }
  }
`;

export const get_levels_query = gql`
  query {
    getLevels {
      items {
        id
        name
      }
    }
  }
`;

export const create_event_schedule_mutation = gql`
  mutation createEventSchedule($json: create_event_schedule_input) {
    createEventSchedule(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const update_event_schedule_mutation = gql`
  mutation updateEventSchedule($json: update_event_schedule_input) {
    updateEventSchedule(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_event_schedules_query = gql`
    query getEventSchedules($json: get_all_event_schedule_input) {
      getEventSchedules(json: $json) {
        items {
          id
          name
          date_time
          gender
          differently_abled_id
          level {
            id
            name
          }
          round {
            id
            name
          }
          event_venue {
            id
            name
          }
          category {
            id
            name
          }
          event_schedule_line_items {
            id
            event {
              id
              name
              type
              discipline {
                id
                name
              }
            }
          }

          error {
            status
            message
          }
        }
        pagination {
          page_number
          page_limit
          total_count
        }
      }
    }
`;

export const get_event_schedule_query = gql`
  query getEventSchedule($json: get_event_schedule_input) {
    getEventSchedule(json: $json) {
      id
      name
      remarks
      date_time
      event_venue_id
      gender
      event_venue {
        id
        name
      }
      category {
        id
        name
      }
      level {
        id
        name
      }
      round {
        id
        name
      }
      event_schedule_line_items {
        id
        event_schedule_id
        event {
          id
          name
          discipline {
            id
            name
          }
        }
      }
    }
  }
`;

export const event_differently_abled_list_query = gql`
  query get_differently_abled_list {
    event_differently_abled_list: get_differently_abled_list {
      items {
        id
        name
        code
      }
      error {
        message
        status
      }
    }
  }
`;

export const delete_event_schedule = gql`
  mutation deleteEventSchedule($json: get_event_schedule_input) {
    deleteEventSchedule(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_events_team_filter_query = gql`
  query get_events(
    $discipline_id: String
    $gender: String
    $differently_abled_id: String
    $category_id: String
  ) {
    get_events_team_filter: get_events(
      discipline_id: $discipline_id
      gender: $gender
      category_id: $category_id
      differently_abled_id: $differently_abled_id
    ) {
      items {
        id
        name
        code
        discipline_id
        type
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_disciplines_team_filter_query = gql`
  query get_filtered_disciplines(
    $gender: String!
    $category_id: String!
    $differently_abled_id: String
  ) {
    get_disciplines_team_filter: get_filtered_disciplines(
      gender: $gender
      category_id: $category_id
      differently_abled_id: $differently_abled_id
    ) {
      items {
        id
        name
        error {
          status
          message
        }
      }
    }
  }
`;

export const get_one_event_query = gql`
  query get_event($id: String!) {
    get_event(id: $id) {
      id
      name
      discipline_id
      discipline {
        name
      }
    }
  }
`;
