import { useDynamicSelector, dynamic_clear } from "@services/redux";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Col,
  Form as AntdForm,
  Row,
  Card,
  Typography,
  Button,
  Space,
  Form,
  Input,
} from "antd";
import { useAPIRequest } from "@helpers/hooks";
import {
  delete_fixture_mutation,
  get_participated_team_query,
} from "@services/graphql/event_schedule_team";
import toast from "react-hot-toast";

const { Text, Title } = Typography;

const RemoveFromThisLevel = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [participantList, setParticipantList] = useState([]);
  const [form] = AntdForm.useForm();
  const {
    initialValues,
    onClose,
    close,
    schedule_name,
    team_id,
    event_schedule_line_item_id,
  } = props;
  const { loading, error, status } = useDynamicSelector(
    "deleteGroupParticipation"
  );
  const { items: participants } = useDynamicSelector(
    "getEventScheduleAddedTeams"
  );
  const get_participated_team_request = useAPIRequest(
    "getEventScheduleAddedTeams",
    get_participated_team_query
  );
  const delete_group_participation_team_request = useAPIRequest(
    "deleteGroupParticipation",
    delete_fixture_mutation
  );

  useEffect(() => {
    let filteredArray = participants?.filter((p) => {
      return team_id.some((x) => x === p.id);
    });
    setParticipantList(filteredArray);
  }, [team_id]);

  useEffect(() => {
    if (error) {
      close();
      // showToast({ type: "error", message: error });
      toast.error(error);
      get_participated_team_request(
        {
          json: {
            event_schedule_line_item_id: event_schedule_line_item_id,
            page_number: 1,
            page_limit: 10,
            search_string: "",
          },
        },
        true
      );
      dispatch(dynamic_clear("deleteGroupParticipation"));
    } else if (status === "Success") {
      close();
      dispatch(dynamic_clear("deleteGroupParticipation"));
      toast.success("Removed Successfully");
      get_participated_team_request(
        {
          json: {
            event_schedule_line_item_id: event_schedule_line_item_id,
            page_number: 1,
            page_limit: 10,
            search_string: "",
          },
        },
        true
      );
    }
  }, [status, error]);

  const handleSubmit = (values) => {
    delete_group_participation_team_request(
      {
        json: {
          participations: participantList?.map((x) => x.id),
          remarks: values?.remarks,
        },
      },
      true
    );
  };

  const onvaluechange = () => {};

  return (
    <>
      <Card style={{ width: "100%", padding: "16px" }}>
        <Title level={5}>
          {`Are you sure you want to remove from this ${schedule_name}?`}
        </Title>

        <Card style={{ backgroundColor: "#53c68c", marginTop: "16px" }}>
          <Space direction="vertical">
            {participantList?.map((x) => (
              <Text key={x.id} style={{ color: "white" }}>
                {x.team?.name} - {x.team?.code}
              </Text>
            ))}
          </Space>
        </Card>
      </Card>

      <Form
        form={form}
        initialValues={initialValues}
        onValueChange={onvaluechange}
        id={props.id}
        onFinish={handleSubmit}
      >
        <Row gutter={24} style={{ marginTop: "24px" }}>
          <Col span={24}>
            <Form.Item
              name={"remarks"}
              label={
                <>
                  <text
                    style={{
                      color: "black",
                      fontFamily: "Marutham",
                      fontSize: "16px",
                    }}
                  >
                    கருத்துக்கள்
                  </text>
                  <text
                    style={{
                      color: "black",
                      fontFamily: "Roboto",
                      fontSize: "16px",
                    }}
                  >
                    &nbsp;/ Remarks
                  </text>
                </>
              }
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>

        <Space style={{ marginTop: "20px", justifyContent: "flex-end" }}>
          <Button
            loading={loading}
            style={{ backgroundColor: "#161680", width: "200px" }}
            type="primary"
          >
            {t("submit")}
          </Button>
          <Button onClick={props.close}>{t("Close")}</Button>
        </Space>
      </Form>
    </>
  );
};

export default RemoveFromThisLevel;
