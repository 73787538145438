import { useAPIRequest } from "@helpers/hooks";
import { get_levels_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleLevelFormItem = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const { items: level_list } = useDynamicSelector("getLevels");
  console.log("category_list", level_list);

  const get_levels_request = useAPIRequest("getLevels", get_levels_query);

  useEffect(() => {
    get_levels_request({}, true);
  }, []);

  return (
    <Col span={12}>
      <Form.Item
        name="level"
        label={t("Level")}
        rules={[{ required: true, message: t("Please select category") }]}
      >
        <Select placeholder={t("Select category")} allowClear>
          {level_list?.map((level, index) => (
            <Option value={level?.id} key={index}>
              {level?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleLevelFormItem;
