import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Card,
  Typography,
  Row,
  Col,
  Button,
  Pagination,
  Table,
  message,
} from "antd";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import { get_one_event_query } from "@services/graphql";
import { useAPIRequest } from "@helpers/hooks";
import {
  create_participate_team_mutation,
  get_event_schedule_teams_query,
  get_participated_team_query,
} from "@services/graphql/event_schedule_team";

const { Text, Title } = Typography;

const ParticipantsDetails = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    event_id,
    event_schedule_line_item_id,
    schedule,
    discipline_id,
    closeModal,
    event_schedule_id,
    schedule_name,
    actionType,
    rerender,
    event_schedule,
  } = props;
  const [removeList, setRemoveList] = useState(false);
  const [playersList, setPlayersList] = useState([]);
  const { t } = useTranslation();
  const [eventId, setEventId] = useState([]);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);

  const {
    loading: createLoading,
    status,
    error,
  } = useDynamicSelector("createEventScheduleTeams");
  const {
    items: teams,
    loading,
    total_items,
  } = useDynamicSelector("getEventScheduleTeams");

  const { items: participants } = useDynamicSelector(
    "getEventScheduleAddedTeams"
  );
  const [current, setCurrent] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);

  // const { item: team_list } = useDynamicSelector("");

  const get_participated_team_request = useAPIRequest(
    "getEventScheduleAddedTeams",
    get_participated_team_query
  );
  const create_participate_team_request = useAPIRequest(
    "createEventScheduleTeams",
    create_participate_team_mutation
  );
  const get_all_event_schedule_teams_request = useAPIRequest(
    "getEventScheduleTeams",
    get_event_schedule_teams_query
  );
  const get_event = useAPIRequest("get_event", get_one_event_query);

  useEffect(() => {
    if (error === "Failure") {
      message.error(error);
      closeModal();
      dispatch(dynamic_clear("createEventScheduleTeams"));
    } else if (status === "success") {
      message.success("Participants Added successfully");
      get_participated_team_request(
        {
          json: {
            event_schedule_line_item_id: event_schedule_line_item_id,
            page_number: current,
            page_limit: pageLimit,
            search_string: "",
          },
        },
        true
      );
      closeModal();
      dispatch(dynamic_clear("createEventScheduleTeams"));
    }
  }, [error, status]);

  useEffect(() => {
    let temp = [];
    temp = participants?.map((x) => x?.team?.id);
    setAlreadySelectedRows(temp);
  }, [participants]);

  useEffect(() => {
    if (actionType === "add") {
      setAlreadySelectedRows([]);
    }
  }, [actionType, rerender]);

  useEffect(() => {
    if (event_id) {
      get_event(
        {
          id: event_id,
        },
        true
      );
    }
  }, [event_id, rerender]);

  useEffect(() => {
    if (teams && participants?.length) {
      let temp = [];
      teams?.filter((x) => {
        if (!participants.some((y) => y?.team_id === x.id)) temp.push(x);
      });
      setPlayersList(temp);
    } else {
      setPlayersList(teams);
    }
  }, [participants, teams]);

  useEffect(() => {
    let temp = [];
    if (schedule) {
      temp = schedule?.[0]?.event_schedule_line_items.map((x) => x.event?.id);
    }
    setEventId(temp);
  }, [props]);

  useEffect(() => {
    get_all_event_schedule_teams_request(
      {
        json: {
          event_id: event_id,
          event_schedule_id: event_schedule_id,
          page_number: current,
          page_limit: pageLimit,
        },
      },
      true
    );
  }, [event_schedule_id, rerender]);

  const fetchData = (value, value1) => {
    get_all_event_schedule_teams_request(
      {
        json: {
          event_id: event_id,
          event_schedule_id: event_schedule_id,
          page_number: value,
          page_limit: value1,
        },
      },
      true
    );
    setCurrent(value);
    setPageLimit(value1);
  };

  const columns = [
    {
      title: t("Team Name"),
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: t("Team Code"),
      dataIndex: "team_code",
      key: "code",
    },
  ];

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const handleSubmit = () => {
    create_participate_team_request(
      {
        json: {
          teams: alreadySelectedRows,
          remove_players_and_add_to_new_schedule: removeList,
          event_schedule_line_item_id: event_schedule_line_item_id,
        },
      },
      true
    );
  };
  const LabelValuePair = ({ label, value }) => (
    <Row style={{ marginBottom: "8px" }}>
      <Col span={6}>
        <Text strong>{label}</Text>
      </Col>
      <Col span={1}>:</Col>
      <Col span={15}>
        <Text>{value}</Text>
      </Col>
    </Row>
  );
  console.log(
    "schedule_name",
    schedule_name,
    event_schedule,
    event_schedule?.[0]?.event_schedule_line_items?.[0]?.event?.discipline?.name
  );

  return (
    <Card style={{ width: "100%", padding: 20 }}>
      <div style={{ marginBottom: 20 }}>
        <LabelValuePair label={t("Schedule Name")} value={schedule_name} />
        <LabelValuePair
          label={t("Discipline")}
          value={
            event_schedule?.[0]?.event_schedule_line_items?.[0]?.event
              ?.discipline?.name
          }
        />
        <LabelValuePair
          label={t("Event")}
          value={
            event_schedule?.[0]?.event_schedule_line_items?.[0]?.event?.name
          }
        />
      </div>

      <>
        <Table
          rowKey="id"
          rowSelection={{
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            selections: [
              Table.SELECTION_ALL,
              Table.SELECTION_NONE,
              Table.SELECTION_INVERT,
            ],
          }}
          loading={loading}
          dataSource={teams}
          columns={columns}
          pagination={false}
        />
      </>

      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <Pagination
          total={total_items}
          showSizeChanger={true}
          current={current}
          hideOnSinglePage={false}
          onChange={fetchData}
        />
      </div>

      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 40 }}
      >
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={createLoading}
          style={{ marginRight: 10 }}
        >
          Submit
        </Button>
        <Button
          onClick={() => {
            props.closeModal();
          }}
        >
          {t("Close")}
        </Button>
      </div>
    </Card>
  );
};

export default ParticipantsDetails;
