import React, { useEffect, useState } from "react";
import { useAPIRequest } from "@helpers/hooks";
import { useDynamicSelector } from "@services/redux";
import {
  create_team_mutation,
  get_registered_user_query,
} from "@services/graphql";
import { Button, Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { AiFillDelete, AiOutlineDelete } from "react-icons/ai";
import { retrieve_item } from "@helpers/functions";
import { useNavigate } from "react-router-dom";
import Loader from "@views/components/common/loader";
import PlayerInstruction from "@views/components/player_home/player_instruction";
import { delete_team_mutation } from "@src/services/graphql";
import { dynamic_clear } from "@src/services/redux";
import { useDispatch } from "react-redux";

const PlayerHome = () => {
  const { players, teams, loading } = useDynamicSelector("get_registered_user");
  const { status: delete_team_status, loading: delete_team_loading } =
    useDynamicSelector("delete_team");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, set_open] = useState(false);
  const [team_id, set_team_id] = useState("");
  const [event, set_event] = useState({});
  const [show_instruction, set_show_instruction] = useState(true); // State to control which component to show in the modal
  const { t } = useTranslation();

  let get_registered_user = useAPIRequest(
    "get_registered_user",
    get_registered_user_query
  );
  let delete_team = useAPIRequest("delete_team", delete_team_mutation);

  const delete_all_players_in_team = () => {
    delete_team(
      {
        team_id: team_id,
      },
      true
    );
  };

  const CardComponent = ({ title, onClick }) => {
    return (
      <div
        className="card"
        style={{ cursor: title.type === "individual" ? "" : "pointer" }}
        onClick={() => {
          title.type !== "individual" && onClick();
        }}
      >
        <h3 style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {title.type === "individual" ? title?.name : "Add Team"}
        </h3>
      </div>
    );
  };
  const TeamCardComponent = ({ title }) => {
    const [delete_team_modal_open, set_delete_team_modal_open] =
      useState(false);

    useEffect(() => {
      if (delete_team_status === "success") {
        toast.success("Team Deleted Successfully");
        on_cancel();
        get_registered_user();
        dispatch(dynamic_clear("delete_team"));
      }
    }, [delete_team_status]);

    const on_team_click = () => {
      navigate("/cmtrophy/player/team/" + title?.team?.id);
    };
    const on_click_open_modal = () => {
      set_delete_team_modal_open(true);
      set_team_id(title?.team?.id);
    };
    const on_cancel = () => {
      set_delete_team_modal_open(false);
    };
    return (
      <>
        <div
          className="card"
          style={{ cursor: "pointer" }}
          onClick={on_team_click}
        >
          <h3 style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            {title?.team?.name}
            {players?.[0]?.is_captain && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  on_click_open_modal();
                }}
              >
                <AiFillDelete
                  style={{
                    marginLeft: "10px",
                    color: "red",
                    cursor: "pointer",
                  }}
                />
              </div>
            )}
          </h3>
        </div>
        <Modal
          className="delete_team_modal"
          title={"Confirmation"}
          open={delete_team_modal_open}
          onCancel={on_cancel}
          onOk={delete_all_players_in_team}
          confirmLoading={delete_team_loading}
        >
          <span>
            Are you sure you want to delete this team? This action will also
            remove all players from the team.
          </span>
        </Modal>
      </>
    );
  };
  const handle_open_model = (event) => {
    set_open(true);
    set_event(event);
    set_show_instruction(true); // Reset to show instruction first when modal opens
  };
  const handle_modal_close = () => {
    set_open(false);
  };
  const handle_instruction_ok = () => {
    set_show_instruction(false); // Switch to TeamForm component
  };

  useEffect(() => {
    get_registered_user({}, true);
  }, []);

  let { status } = useDynamicSelector("create_team");

  useEffect(() => {
    if (status === "success") {
      toast.success("Team Created Successfully");
      handle_modal_close();
      get_registered_user({}, true);
    }
  }, [status]);

  return (
    <>
      {loading && (
        <div
          style={{
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
      {!players?.[0]?.team?.id && (
        <>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Player</h2>
          </div>
          <div
            style={{
              minHeight: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="card-container">
              {players?.[0].events?.map((x) => (
                <CardComponent
                  key={x.id}
                  title={x}
                  onClick={() => handle_open_model(x)}
                />
              ))}
            </div>
          </div>
        </>
      )}
      {players?.[0]?.team?.id && (
        <>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>Team</h2>
          </div>
          <div
            style={{
              minHeight: "400px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="card-container">
              {players.map((x) => (
                <TeamCardComponent key={x.id} title={x} />
              ))}
            </div>
          </div>
        </>
      )}
      <Modal
        id="manage_user"
        open={open}
        onCancel={handle_modal_close}
        title={t("add_team")}
        footer={null}
        style={{ top: 20 }}
        destroyOnClose={true}
        // bodyStyle={{ maxHeight: '450px', overflowY: 'auto' }}
      >
        {show_instruction ? (
          <div>
            <PlayerInstruction />
            <div
              className="player-model-btn"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <Button danger onClick={handle_modal_close}>
                {t("close")}
              </Button>
              <Button type="primary" onClick={handle_instruction_ok}>
                {t("agree")}
              </Button>
            </div>
          </div>
        ) : (
          <TeamForm event={event} />
        )}
      </Modal>
    </>
  );
};

const TeamForm = ({ event }) => {
  const { t } = useTranslation();
  const user = retrieve_item("login_data");

  let { loading } = useDynamicSelector("create_team");
  let create_team_request = useAPIRequest("create_team", create_team_mutation);
  const onFinish = (values) => {
    create_team_request(
      {
        data: {
          ...values,
          discipline_id: event.discipline_id,
          event_id: event.id,
        },
      },
      true
    );
  };

  return (
    <Form
      name="player-login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        label={t("team_name")}
        name="name"
        rules={[{ required: true, message: t("name_required_ta") }]}
      >
        <Input length={50} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t("submit_ta")}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PlayerHome;
