import React from "react";
import { useTranslation } from "react-i18next";

import { Form, Input } from "antd";

const RegisterAddressOne = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        name={["player", "address1"]}
        label={t("address_one")}
        rules={[{ required: true, message: t("address1_required") }]}
      >
        <Input.TextArea />
      </Form.Item>
    </div>
  );
};

export default RegisterAddressOne;
