import React from "react";
import { Col, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import RegisterName from "./register_name";
import RegisterInstitutionType from "./register_institution_type";
import RegisterEducationSector from "./register_education_sector";
import RegisterDOB from "./register_dob";
import UpdateDobPicker from "../update_form/update_dob_picker";

const BasicDetails = ({ type, form, is_mandatory, update_player_id }) => {
  const { t } = useTranslation();
  return (
    <>
      <Col span={24}>
        <h2>{t("personal_information")}</h2>
      </Col>
      {/* <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
        <RegisterPhoto form={form} is_mandatory={is_mandatory} />
      </Col> */}
      {/* <Col
        span={24}
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "15px",
        }}
      >
        <h5 style={{ color: "red" }}>{t("profile_file_allowed_content")}</h5>
      </Col> */}
      <Col xs={24} sm={12}>
        <RegisterName />
      </Col>

      <Col xs={24} sm={12}>
        <Form.Item
          name={["player", "dob"]}
          label={t("dob")}
          rules={[{ required: true, message: t("dob_required") }]}
        >
          {update_player_id ? (
            <UpdateDobPicker form={form} />
          ) : (
            <RegisterDOB form={form} />
          )}
        </Form.Item>
      </Col>

      <Col xs={24} sm={12}>
        {/* <RegisterMobile /> */}
        <Form.Item
          label={t("mobile_number")}
          name="mobile"
          rules={[{ required: true, message: t("mobile_required_ta") }]}
        >
          <InputNumber
            style={{ width: "100%" }}
            maxLength={10}
            changeOnWheel={false}
            controls={false}
          />
        </Form.Item>
      </Col>
      {/* <Col xs={24} sm={12}>
        <RegisterEmail />
      </Col> */}
      {/* <RegisterPassword /> */}
      {/* </Col> */}
      {type === "institution" && (
        <>
          <Col xs={24} sm={12}>
            <RegisterInstitutionType />
          </Col>
          <Col xs={24} sm={12}>
            <RegisterEducationSector />
          </Col>
        </>
      )}
    </>
  );
};

export default BasicDetails;
