import React, { useEffect, useState } from "react";
import { DatePicker, Form } from "antd";
import moment from "moment";
import dayjs from "dayjs";

const UpdateDobPicker = ({ onChange, value, form }) => {
  const date_of_birth = Form.useWatch(["player", "dob"], form);
  const [age, setAge] = useState(null);
  const disabledDate = (d) => {
    return d.isAfter("2014-12-31");
  };
  const calculateAge = (dob) => {
    onChange(dob);
    if (dob) {
      const today = dayjs();
      const birthDate = dayjs(dob);
      const age = today?.diff(birthDate, "year");
      setAge(age);
    } else {
      setAge(null);
    }
  };
  useEffect(() => {
    if (date_of_birth) {
      calculateAge(date_of_birth);
    }
  }, [date_of_birth]);
  console.log("date_of_birth", date_of_birth);

  return (
    <>
      <DatePicker
        style={{ width: "100%" }}
        format={"DD-MM-YYYY"}
        disabledDate={disabledDate}
        defaultPickerValue={dayjs(moment("2014-12-31"))}
        onChange={calculateAge}
        value={date_of_birth}
      />
      <p style={{ padding: "10px", fontWeight: "bolder" }}>
        {age !== null ? `Age: ${age}` : ""}
      </p>
    </>
  );
};

export default UpdateDobPicker;
