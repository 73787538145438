import React, { useEffect, useState } from "react";

import EventVenueTable from "@views/components/venue/event_venue_table";
import AddEventVenue from "@views/components/venue/add_event_venue";
import VenueAddModal from "@views/components/venue/venue_add_modal";
import { Form } from "antd";
import { get_event_venues_query } from "@services/graphql";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import DeleteEventVenueModal from "@views/components/venue/delete_event_venue_modal";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

const Venue = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [event_modal_type, set_event_modal_type] = useState("add");
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [search_value, set_search_value] = useState("");
  const [event_venue_id, set_event_venue_id] = useState("");
  const [delete_modal_open, set_delete_modal_open] = useState(false);
  const [delete_event_venue_id, set_delete_event_venue_id] = useState("");

  const { status: delete_venue_status, error: delete_venue_error } =
    useDynamicSelector("deleteEventVenue");

  useEffect(() => {
    if (delete_venue_status === "success") {
      set_delete_modal_open(false);
      toast.success("Event Venue Created Successfully");
      dispatch(dynamic_clear("deleteEventVenue"));
      event_venue_list();
    } else if (delete_venue_error) {
      toast.error(delete_venue_error?.message);
      dispatch(dynamic_clear("deleteEventVenue"));
    }
  }, [delete_venue_status, delete_venue_error]);

  const get_event_venues = useAPIRequest(
    "getEventVenues",
    get_event_venues_query
  );

  const event_venue_list = () => {
    get_event_venues({ json: { search_string: search_value } }, true);
  };

  useEffect(() => {
    event_venue_list();
  }, [search_value]);

  return (
    <div style={{ height: "100%", width: "100%", padding: "25px" }}>
      <AddEventVenue
        set_event_modal_type={set_event_modal_type}
        set_is_modal_open={set_is_modal_open}
        set_search_value={set_search_value}
        search_value={search_value}
      />
      <EventVenueTable
        set_event_modal_type={set_event_modal_type}
        set_is_modal_open={set_is_modal_open}
        event_venue_id={event_venue_id}
        set_event_venue_id={set_event_venue_id}
        set_delete_event_venue_id={set_delete_event_venue_id}
        set_delete_modal_open={set_delete_modal_open}
      />

      <VenueAddModal
        set_is_modal_open={set_is_modal_open}
        is_modal_open={is_modal_open}
        event_modal_type={event_modal_type}
        form={form}
        event_venue_id={event_venue_id}
        event_venue_list={event_venue_list}
      />

      <DeleteEventVenueModal
        delete_modal_open={delete_modal_open}
        set_delete_modal_open={set_delete_modal_open}
        delete_event_venue_id={delete_event_venue_id}
      />
    </div>
  );
};

export default Venue;
