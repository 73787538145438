import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login_query } from "@services/graphql";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { store_item } from "@helpers/functions";
import { useDispatch } from "react-redux";
import RegisterTermsContents from "./register_terms";
import toast from "react-hot-toast";

const PlayerLoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, set_open] = useState(false);
  let login_request = useAPIRequest("login", login_query);
  const { status, error, loading, ...rest } = useDynamicSelector("login");
  const onFinish = (values) => {
    login_request(
      {
        username: String(values?.username),
        password: String(values?.password),
      },
      true
    );
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if (rest?.session_id) {
      store_item("login_data", rest);
      store_item("name", rest.name);
      store_item("session_id", rest.session_id);
      navigate("/cmtrophy/player/home");
      dispatch(dynamic_clear("login"));
    } else if (error?.message) {
      toast.error(error?.message);
      dispatch(dynamic_clear("login"));
    }
  }, [rest, navigate, error]);

  const handle_open_model = () => {
    set_open(true);
  };
  const handle_modal_close = () => {
    set_open(false);
  };

  const handle_redirect = (key, url) => {
    if (url) window.open(url, "blank");
    if (key) navigate(`/cmtrophy${key}`);
  };

  return (
    <div className="login-container">
      <div className="player-login-box">
        <div className="player-login-links">
          <span onClick={handle_open_model} className="register-tamil">
            {t("if_you_don_have_an_account")}
          </span>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span onClick={handle_open_model} className="register">
              {t("register")}
            </span>
            <span onClick={handle_open_model} className="click-here">
              {t("click_here")}
            </span>
          </div> */}
        </div>

        {/* <h3 className="player-login-title">{t("play_login")}</h3> */}
        {/* <Form
          name="player-login-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label={t("registered_mobile_number")}
            name="username"
            rules={[{ required: true, message: "" }]}
          >
            <InputNumber
              maxLength={10}
              changeOnWheel={false}
              controls={false}
              style={{ width: "100%" }}
            />
          </Form.Item>

          <Form.Item
            label={t("password")}
            name="password"
            rules={[{ required: true, message: "" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="player-login-button"
              loading={loading}
            >
              {t("login")}
            </Button>
          </Form.Item>
        </Form> */}
        <div className="player-login-links">
          {/* <br /> */}
          {/* <span onClick={() => handle_redirect("/forgot-password")}>
            {t("forgot_password_ta")} / {t("forgot_password")}
          </span> */}
          {/* <br /> */}
          <br />
          <span
            className="player-usermanual-links"
            onClick={() =>
              handle_redirect(
                null,
                "https://v-sdat-dev.blr1.vultrobjects.com/2024/08/1d20bca2-2d0d-4903-8466-df21d4710adf.pdf"
              )
            }
          >
            {t("how_to_use_ta")} / {t("how_to_use")} - Click here
          </span>
          <br />
          <span
            onClick={() =>
              handle_redirect(
                null,
                "https://v-sdat-dev.blr1.vultrobjects.com/2024/08/517b2050-4f71-42c9-920d-6f5175175d31.pdf"
              )
            }
          >
            {t("events_ta")} / {t("events")}
          </span>
          <br />
          <span
            className="player-usermanual-links"
            onClick={() =>
              handle_redirect(
                null,
                "https://v-sdat-dev.blr1.vultrobjects.com/2024/08/65cffa9c-89ed-4535-9c11-4e68401e338b.pdf"
              )
            }
          >
            {t("faq_ta")}/ {t("faq")} - Click here
          </span>
        </div>
      </div>
      <Modal
        id="manage_user"
        open={open}
        onCancel={handle_modal_close}
        title={t("general_information")}
        children={<RegisterTermsContents />}
        okText={t("Agree")}
        style={{
          top: 20,
        }}
        onOk={() => handle_redirect("/register-form/individual")}
        width={800}
      />
    </div>
  );
};

export default PlayerLoginForm;
