import { useDispatch } from "react-redux";
import { useDynamicSelector } from "@services/redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, Form, Input, Space, Select, Typography } from "antd";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_all_positions_query,
  get_event_parameters_query,
  get_individual_participation_query,
  update_performance_mutation,
} from "@services/graphql/performance";
import { get_events_schedule_query } from "@services/graphql";

const PerformanceDetails = (props) => {
  const { initialValues, close, event_schedule_line_item_id, rerender } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const { event_id } = useParams();
  const [actionItem, setActionItem] = useState({});
  const [parameterList, setParameterList] = useState([]);

  const { loading: createLoading } = useDynamicSelector("createEventVenue");
  const { loading: updateLoading } = useDynamicSelector(
    "updateIndividualParticipation"
  );
  const { item: performance } = useDynamicSelector(
    "getIndividualParticipation"
  );
  const { items: positions } = useDynamicSelector("getPositions");
  console.log("positions", positions);

  const { items: parameters } = useDynamicSelector("getEventParameters");

  const get_all_positions_request = useAPIRequest(
    "getPositions",
    get_all_positions_query
  );
  const get_individual_participation_request = useAPIRequest(
    "getIndividualParticipation",
    get_individual_participation_query
  );
  const get_event_parameters_request = useAPIRequest(
    "getEventParameters",
    get_event_parameters_query
  );
  const get_events_schedule_request = useAPIRequest(
    "get_events",
    get_events_schedule_query
  );
  const update_performance_participation_request = useAPIRequest(
    "updateIndividualParticipation",
    update_performance_mutation
  );

  useEffect(() => {
    get_all_positions_request({}, true);
  }, []);

  useEffect(() => {
    if (
      parameters ||
      performance?.individual_participation_parameters?.length
    ) {
      setParameterList(
        performance?.individual_participation_parameters?.length
          ? performance?.individual_participation_parameters
          : parameters
      );
    }
  }, [parameters, performance, rerender]);

  useEffect(() => {
    if (initialValues) {
      get_individual_participation_request(
        {
          json: {
            id: initialValues?.id,
          },
        },
        true
      );
    }
  }, [initialValues, rerender]);

  useEffect(() => {
    if (event_id) {
      get_event_parameters_request({
        json: {
          event_id: event_id,
        },
      });
    }
  }, [event_id, rerender]);

  const formatTimeDisplay = (date) => {
    return moment(new Date(date)).format("mm:ss");
  };

  useEffect(() => {
    if (performance && parameterList?.length) {
      let individual_participation_parameters = [];

      if (performance?.individual_participation_parameters?.length) {
        individual_participation_parameters =
          performance.individual_participation_parameters?.map((x) => ({
            id: x.id,
            event_parameter_id: x.event_parameter_id,
            value: x.value,
            individual_participation_id: x.individual_participation_id,
          }));
      } else {
        individual_participation_parameters = parameterList?.map((x) => ({
          event_parameter_id: x.id,
          id: "",
        }));
      }

      setActionItem({
        individual_participation_parameters,
        position_id: initialValues.position_id,
      });
    }
  }, [performance, parameterList, rerender]);

  const handleSubmit = (values) => {
    values.event_schedule_line_item_id = event_schedule_line_item_id;
    if (initialValues) {
      update_performance_participation_request({
        json: {
          id: initialValues?.id,
          data: values,
        },
      });
    }
  };

  const onValueChange = (values, allValues) => {
    // handle form value change logic here if needed
  };

  return (
    <div
      style={{ overflowX: "hidden", display: "flex", justifyContent: "center" }}
    >
      <div style={{ maxWidth: "600px", width: "100%" }}>
        <div style={{ marginBottom: "10px" }}>
          <Typography.Title level={4}>
            {initialValues?.player?.name} - {initialValues?.player?.player_code}
          </Typography.Title>
        </div>

        <Form
          form={form}
          initialValues={actionItem}
          onFinish={handleSubmit}
          onValuesChange={onValueChange}
        >
          {parameterList?.map((x, index) => (
            <div key={x.id} style={{ marginBottom: "16px" }}>
              <Form.Item
                name={["individual_participation_parameters", index, "value"]}
                label={x.name || x.event_parameter?.name}
                rules={[
                  {
                    required: true,
                    message: `${x.name || x.event_parameter?.name} is required`,
                  },
                ]}
              >
                {x.type === "text" ? (
                  <Input />
                ) : x.type === "int" ? (
                  <Input type="number" />
                ) : x.type === "decimal" ? (
                  <Input type="number" step="0.01" />
                ) : x.type === "time" ? (
                  <Input />
                ) : null}
              </Form.Item>
            </div>
          ))}

          <div style={{ marginBottom: "16px" }}>
            <Form.Item
              name="position_id"
              label={
                <>
                  <span color="black" fontFamily="Marutham" fontSize="16px">
                    நிலை
                  </span>
                  <span color="black" fontFamily="Roboto" fontSize="16px">
                    &nbsp;/ Position
                  </span>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "Position is required",
                },
              ]}
            >
              <Select
                placeholder="Select a position"
                options={positions}
                fieldNames={{
                  label: "name",
                  value: "id",
                }}
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Space size="large" style={{ marginTop: "20px" }}>
              <Button onClick={close}>{t("Cancel")}</Button>
              <Button
                htmlType="submit"
                loading={createLoading || updateLoading}
                type="primary"
              >
                {t("Save")}
              </Button>
            </Space>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PerformanceDetails;
