import { gql } from "@apollo/client";

export const get_category_wise_report_query = gql`
  query get_district_report {
    get_district_report {
      items {
        sl_no
        type
        name
        school_count
        college_count
        differently_abled_count
        government_servants_count
        general_count
        total
      }
      error {
        status
      }
    }
  }
`;

export const query_get_all_report_request = gql`
  query get_all_report_requests {
    get_all_report_requests {
      items {
        id
        report_name
        requested_datetime
        completed_datetime
        status
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_report_request_query = gql`
  query get_report_request($id: String!) {
    get_report_request(id: $id) {
      id
      response
      report_name
    }
  }
`;
