import { AppstoreOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdOutlinePlace } from "react-icons/md";
import { GrArticle } from "react-icons/gr";
import { AiFillDatabase, AiOutlineTeam } from "react-icons/ai";
import { HiOutlineDocumentReport } from "react-icons/hi";

const SecondContainer = () => {
  const navigate = useNavigate();
  const items = [
    {
      label: "Dashboard",
      key: "/cmtrophy/officer/dashboard",
      icon: <AppstoreOutlined />,
    },
    {
      label: "Players",
      key: "/cmtrophy/officer/player",
      icon: <UserOutlined color="#fff" />,
    },

    {
      label: "Venue",
      key: "/cmtrophy/officer/venue",
      icon: <MdOutlinePlace />,
    },
    {
      label: "Event Schedule",
      key: "/cmtrophy/officer/schedules",
      icon: <GrArticle />,
    },

    {
      label: "Teams",
      key: "/cmtrophy/officer/teams",
      icon: <AiOutlineTeam />,
    },
    {
      label: "Report",
      key: "/cmtrophy/officer/reports",
      icon: <HiOutlineDocumentReport />,
    },

    {
      label: "Player Register",
      key: "/cmtrophy/officer/register-form/individual",
      icon: <AiFillDatabase />,
    },
  ];
  const [current, setCurrent] = useState("player");

  const onClick = (e) => {
    setCurrent(e.key);
    navigate(e.key);
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="horizontal"
      items={items}
      className="officer-menu"
      theme="dark"
    />
  );
};

export default SecondContainer;
