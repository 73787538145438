import { gql } from "@apollo/client";

export const create_fixture_mutation = gql`
  mutation createGroupParticipation($json: create_group_participation_input) {
    createGroupParticipation(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const update_fixture_mutation = gql`
  mutation updateGroupParticipation($json: update_group_participation_input) {
    updateGroupParticipation(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const delete_fixture_mutation = gql`
  mutation deleteGroupParticipation($json: get_group_participation_input) {
    deleteGroupParticipation(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_fixtures_query = gql`
  query getGroupParticipations($json: get_all_group_participation_input) {
    getGroupParticipations(json: $json) {
      items {
        id
        event_schedule_line_item_id
        team_one_id
        is_send_to_next_level
        group_participation_parameters {
          id
          group_participation_id
          team_id
          event_parameter_id
          value
          event_parameter {
            id
            name
            type
          }
        }
        team_one {
          id
          name
          event_id
          code
        }
        team_two_id
        team_two {
          id
          name
          event_id
          code
        }
        winner_id
        winner {
          id
          name
          event_id
          code
        }
        referee
        remarks
        error {
          status
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_one_fixture_query = gql`
  query getGroupParticipation($json: get_group_participation_input) {
    getGroupParticipation(json: $json) {
      id
      event_schedule_line_item_id
      group_participation_parameters {
        id
        group_participation_id
        team_id
        event_parameter_id
        value
        event_parameter {
          id
          name
          type
        }
      }
      team_one_id
      team_one {
        id
        name
        event_id
        code
      }
      team_two_id
      team_two {
        id
        name
        event_id
        code
      }
      winner_id
      winner {
        id
        name
        event_id
        code
      }
      referee
      remarks
      error {
        status
        message
      }
    }
  }
`;

export const send_to_next_level_mutation = gql`
  mutation sendGroupParticipationToNextLevel(
    $json: send_to_group_next_level_input
  ) {
    sendGroupParticipationToNextLevel(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_event_schedule_teams_query = gql`
  query getEventScheduleTeams($json: get_all_team_input) {
    getEventScheduleTeams(json: $json) {
      items {
        id
        name
        event_id
        team_code
        gender
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_participated_team_query = gql`
  query getEventScheduleAddedTeams($json: get_all_event_schedule_team_input) {
    getEventScheduleAddedTeams(json: $json) {
      items {
        id
        remarks
        event_schedule_line_item_id
        position_id
        team_id
        team {
          id
          name
          team_code
          gender
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const deleteParticipatedTeamMutation = gql`
  mutation deleteEventScheduleTeams($json: delete_event_schedule_team_input) {
    deleteEventScheduleTeams(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const create_participate_team_mutation = gql`
  mutation createEventScheduleTeams($json: event_schedule_teams_input) {
    createEventScheduleTeams(json: $json) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_all_teams_for_sent_to_next_level = gql`
  query getSendToNextLevelTeams($json: get_all_team_input) {
    getSendToNextLevelTeams(json: $json) {
      items {
        id
        name
        code
      }
    }
  }
`;

export const update_state_team_mutation = gql`
  mutation updateStateTeam(
    $id: String
    $event_schedule_line_item_id: String
    $players: [String]
    $team_name: String
  ) {
    updateStateTeam(
      id: $id
      event_schedule_line_item_id: $event_schedule_line_item_id
      players: $players
      team_name: $team_name
    ) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
