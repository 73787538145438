import React, { useEffect } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { useDynamicSelector } from "@services/redux";
import { get_districts_query } from "@services/graphql/master_data";

const RegisterDistrict = ({ category_id }) => {
  const { t } = useTranslation();
  const get_district_list = useAPIRequest("get_districts", get_districts_query);
  const { items: district_list } = useDynamicSelector("get_districts");

  useEffect(() => {
    get_district_list({}, true);
  }, []);

  const category_dist = {
    school: t("studying_district"),
    college: t("studying_district"),
    government_servent: t("working_district"),
    general: t("native_district"),
    differently_abled: t("native_district"),
  };

  return (
    <Form.Item
      name="district_id"
      label={category_id ? category_dist[category_id] : t("district")}
      rules={[{ required: true, message: t("district_required") }]}
    >
      <Select
        options={district_list?.map((district) => ({
          label: district.name,
          value: district.id,
        }))}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
      />
    </Form.Item>
  );
};

export default RegisterDistrict;
