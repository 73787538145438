import React from "react";
import { Button, Modal } from "antd";

const SentToNextLevel = ({ handleStateLevelModalClose }) => {
  const handleCancel = () => {
    handleStateLevelModalClose();
  };

  const handle_submit = () => {
    // Handle submit action here
  };

  return (
    <>
      <p>Are you sure you want to move to the next level</p>
      <div style={{ textAlign: "right" }}>
        <Button onClick={handleCancel} style={{ marginRight: 8 }}>
          Cancel
        </Button>
        <Button type="primary" onClick={handle_submit}>
          Submit
        </Button>
      </div>
    </>
  );
};

export default SentToNextLevel;
