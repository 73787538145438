import { gql } from "@apollo/client";

export const get_dashboard_count_query = gql`
  query get_dashboard_count {
    get_dashboard_count {
      items {
        discipline_name
        type
        school_count
        college_count
        differently_abled_count
        government_servants_count
        general_count
        total
      }
      error {
        status
      }
    }
  }
`;

export const get_district_report_query = gql`
  query get_district_report {
    get_district_report {
      items {
        sl_no
        type
        name
        school_count
        college_count
        differently_abled_count
        government_servants_count
        general_count
        total
      }
      error {
        status
      }
    }
  }
`;

export const get_players_query = gql`
  query get_players(
    $page_number: Int!
    $page_limit: Int!
    $search_string: String
    $user_type: String
    $discipline_id: String
    $event_id: String
    $category_id: String
    $district_id: String
    $team_id: String
    $is_participated: Boolean
    $gender: String
  ) {
    get_players(
      page_number: $page_number
      page_limit: $page_limit
      search_string: $search_string
      user_type: $user_type
      discipline_id: $discipline_id
      event_id: $event_id
      category_id: $category_id
      district_id: $district_id
      team_id: $team_id
      is_participated: $is_participated
      gender: $gender
    ) {
      items {
        id
        is_captain

        team_id
        name
        player_code
        mobile
        gender
        district_id
        aadhar_no
        is_participated
        dob
        team {
          id
          name
        }
        category {
          id
          name
        }
        disciplines {
          id
          name
        }
        events {
          id
          name
        }
        district {
          id
          name
        }
        error {
          status
          message
        }
      }
      pagination {
        page_number
        page_limit
        total_count
      }
    }
  }
`;

export const get_player_query = gql`
  query get_player($id: String!) {
    get_player(id: $id) {
      id
      is_captain
      team_id
      name
      dob
      player_code
      mobile
      gender
      city
      differently_abled_id
      aadhar_url
      aadhar_no
      department
      address1
      address2
      district_id
      category {
        id
        name
      }
      disciplines {
        id
        name
      }
      events {
        id
        name
        type
      }
      team {
        id
        name
      }
      additional_players {
        id
        name
        mobile
        address1
        gender
        aadhar_no
        dob
        player_code
      }
    }
  }
`;

export const update_player_mutation = gql`
  mutation updatePlayer($id: String!, $data: player_input) {
    updatePlayer(id: $id, data: $data) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
export const get_categorywise_disciplinewise_report_query = gql`
  query {
    get_categorywise_disciplinewise_report {
      items {
        sl_no
        name
        school_count
        college_count
        differently_abled_count
        differently_abled_count
        government_servants_count
        general_count
        total
        error {
          status
          message
        }
      }
    }
  }
`;
export const get_districtwise_categorywise_report_query = gql`
  query {
    get_districtwise_categorywise_report {
      items {
        sl_no
        name
        school_count
        college_count
        college_count
        differently_abled_count
        government_servants_count
        general_count
        total
        error {
          status
          message
        }
      }
    }
  }
`;

export const get_players_without_pagination_query = gql`
  query get_all_players_report(
    $search_string: String
    $discipline_id: String
    $event_id: String
    $category_id: String
    $district_id: String
    $is_participated: Boolean
  ) {
    get_players_without_pagination: get_all_players_report(
      search_string: $search_string
      discipline_id: $discipline_id
      event_id: $event_id
      category_id: $category_id
      district_id: $district_id
      is_participated: $is_participated
    ) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;
