export const LOGO = "";
export const VERTACE_LOGO = "";
export const PROFILE = "";
export const SIDEBAR_IMAGE = "";
export const HOME_AWARD = require("@assets/price_image.jpg");
export const FIRST_SLIDER_IMAGE = require("@assets/images/landing/first_slider_image.jpg");
export const SECOND_SLIDER_IMAGE = require("@assets/images/landing/second_image.jpeg");
export const THIRD_SLIDER_IMAGE = require("@assets/images/landing/third_slider_image.jpg");
export const FOURTH_SLIDER_IMAGE = require("@assets/images/landing/fourth_slider_image.jpg");
export const FIFTH_SLIDER_IMAGE = require("@assets/images/landing/fifth_slider_image.jpg");
