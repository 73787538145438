import React from "react";
import { useTranslation } from "react-i18next";

const HomeMinisterContent = () => {
  const { t } = useTranslation();
  return (
    <div className="minster-main-container">
      <div className="minster-left-side-image">
        <img
          src="https://sdat.tn.gov.in/static/media/MKStalin_16072021_1200_0.1324f1fd55b4abcf0d3f.jpg"
          alt="Logo"
          className=""
        />
      </div>
      <div className="minster-body-content">
        <h2 className="content-title mb-4">{t("minister_content_title")}</h2>
        <p className="content-paragraph-one">{t("minister_content_one")}</p>
        <p className="content-paragraph-two">{t("minister_content_two")}</p>
        <div className="minster-body-footer-main-container">
          <div className="minster-body-footer">
            <strong className="content-signature">
              {t("chief_minister_name")}
            </strong>
            <p className="content-signature-position">{t("chief_minister")}</p>
          </div>
        </div>
      </div>
      <div className="minster-right-side-image">
        <img
          src="https://v-lfa-assets.blr1.vultrobjects.com/01ba9770-6943-450c-9336-0048f0ffa2d4.jpg"
          alt="Logo"
          className=""
        />
      </div>
    </div>
  );
};

export default HomeMinisterContent;
