import { gql } from "@apollo/client";

export const register_user_mutation = gql`
  mutation create_registered_user($data: registered_user_input) {
    create_registered_user(data: $data) {
      id
      status
      error {
        message
        status
      }
    }
  }
`;

export const get_registered_user_query = gql`
  query get_registered_user {
    get_registered_user {
      id
      name
      user_type
      institute_type
      education_sector
      players {
        id
        name
        player_code
        is_captain
        disciplines {
          id
          name
        }
        events {
          id
          name
          type
          discipline_id
        }
        team {
          id
          name
        }
      }
      teams {
        id
        name
      }
    }
  }
`;

export const get_min_max_partcipants_count_query = gql`
  query get_min_max_partcipants_count(
    $json: get_min_max_partcipants_count_input
  ) {
    get_min_max_partcipants_count(json: $json) {
      min_allowed_participants
      max_allowed_participants
    }
  }
`;
