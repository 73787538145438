import React, { useEffect, useState } from "react";
import { Button, Col, message } from "antd";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { get_participated_count_report_query } from "@services/graphql/player";
import { useDynamicSelector } from "@services/redux";
import * as XLSX from "xlsx";

const ParticipantPlayerWiseReport = () => {
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState(false);
  const {
    items: participated_count_report,
    loading: participated_count_report_loading,
  } = useDynamicSelector("get_participated_count_report");

  const get_participated_count_report_request = useAPIRequest(
    "get_participated_count_report",
    get_participated_count_report_query
  );

  useEffect(() => {
    get_participated_count_report_request();
  }, []);

  const handle_participant_click = async () => {
    if (!participated_count_report || participated_count_report.length === 0) {
      message.error("No data available to export.");
      return;
    }

    setIsGenerating(true);

    try {
      // Define sports
      const sports = [
        "Athletics",
        "Badminton",
        "Basketball",
        "Cricket",
        "Football",
        "Hockey",
        "Kabaddi",
        "Silambam",
        "Swimming",
        "Table Tennis",
        "Volleyball",
        "Handball",
        "Carrom",
        "Chess",
        "Kho-Kho",
        "Track Cycling",
        "Gymnastics",
        "Squash",
      ];

      const headerRow1 = [
        "Sl No",
        "District Name",
        ...sports.flatMap((sport) => [sport, sport, sport, sport]),
      ];
      const headerRow2 = [
        "",
        "",
        "Male",
        "Female",
        "Participated Male Count",
        "Participated Female Count",
        ...sports.flatMap(() => [
          "Male",
          "Female",
          "Participated Male Count",
          "Participated Female Count",
        ]),
      ];

      const data = participated_count_report.map((district, index) => {
        const getPlayerCount = (sportName, countType) => {
          const sport = district.player_counts.find(
            (sport) => sport.discipline_name === sportName
          );
          return sport ? sport.count_object[countType] || 0 : 0;
        };

        return [
          index + 1,
          district.district_name,
          ...sports.flatMap((sport) => [
            getPlayerCount(sport, "male_count"),
            getPlayerCount(sport, "female_count"),
            getPlayerCount(sport, "participated_male_count"),
            getPlayerCount(sport, "participated_female_count"),
          ]),
        ];
      });

      // Create Excel file
      const worksheet = XLSX.utils.aoa_to_sheet([
        headerRow1,
        headerRow2,
        ...data,
      ]);

      // Define styles
      const headerStyle = {
        alignment: { horizontal: "center" },
      };
      const dataStyle = {
        alignment: { horizontal: "right" },
      };

      // Apply styles to header cells
      headerRow1.forEach((_, colIndex) => {
        worksheet[XLSX.utils.encode_cell({ r: 0, c: colIndex })].s =
          headerStyle;
      });
      headerRow2.forEach((_, colIndex) => {
        worksheet[XLSX.utils.encode_cell({ r: 1, c: colIndex })].s =
          headerStyle;
      });

      // Apply styles to data cells
      participated_count_report.forEach((_, rowIndex) => {
        data[rowIndex].forEach((_, colIndex) => {
          worksheet[
            XLSX.utils.encode_cell({ r: rowIndex + 2, c: colIndex })
          ].s = dataStyle;
        });
      });

      // Merge cells
      const mergeCells = sports.flatMap((_, index) => [
        { s: { r: 0, c: 2 + index * 4 }, e: { r: 0, c: 2 + index * 4 + 3 } },
      ]);
      worksheet["!merges"] = mergeCells;

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Participants Report");

      XLSX.writeFile(workbook, "participants_report.xlsx");
      message.success("Excel file generated successfully!");
    } catch (error) {
      console.error("Error exporting to Excel:", error);
      message.error("Failed to export the file.");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Col>
      <Button
        type="primary"
        onClick={handle_participant_click}
        loading={isGenerating || participated_count_report_loading}
      >
        {t("Participant Report")}
      </Button>
    </Col>
  );
};

export default ParticipantPlayerWiseReport;
