import React from "react";
import { Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";

const RegisterAadharNo = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name={["player", "aadhar_no"]}
        label={t("aadhar")}
        rules={[{ required: true, message: t("aadhar_number_required") }]}
      >
        <InputNumber
          style={{ width: "100%" }}
          maxLength={12}
          changeOnWheel={false}
          controls={false}
        />
      </Form.Item>
    </div>
  );
};

export default RegisterAadharNo;
