import { useAPIRequest } from "@helpers/hooks";
import { get_event_venues_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleVenueFormItem = () => {
  const { t } = useTranslation();
  const { Option } = Select;

  const {
    items: event_venue_list,
    loading: event_venue_list_loading,
    pagination,
  } = useDynamicSelector("getEventVenues");

  const get_event_venues = useAPIRequest(
    "getEventVenues",
    get_event_venues_query
  );

  useEffect(() => {
    get_event_venues();
  }, []);

  return (
    <Col span={12}>
      <Form.Item
        name="venue"
        label={t("இடம் / Venue")}
        rules={[{ required: true, message: t("Please select venue") }]}
      >
        <Select placeholder={t("Select venue")} allowClear>
          {event_venue_list?.map((venue, index) => (
            <Option value={venue?.id} key={index}>
              {venue?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};

export default EventScheduleVenueFormItem;
