import React from "react";
import TopHeader from "@views/layouts/user_layout/header/top_header";
import SecondContainer from "./header/second_container";

const Header = () => {
  return (
    <div>
      <TopHeader />
      <SecondContainer />
    </div>
  );
};

export default Header;
