import React from "react";

const DashboardCard = ({ discipline, bgImage }) => {
  return (
    <div
      style={{
        backgroundImage: bgImage,
        minHeight: "80px",
        color: "white",
        borderRadius: "10px",
        cursor: "pointer",
      }}
    >
      <h4 style={{ color: "white", padding: "10px" }}>
        {discipline?.discipline_name}
      </h4>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <h2 style={{ color: "white", padding: "10px", fontFamily: "Marutham" }}>
          {discipline?.total?.toLocaleString("en-IN")}
        </h2>
      </div>
    </div>
  );
};

export default DashboardCard;
