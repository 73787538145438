import React from "react";
import RegisterForm from "./register_form";

const Register = () => {
  return (
    <div className="login-page-main-container">
      <RegisterForm />
    </div>
  );
};

export default Register;
