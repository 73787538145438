import React, { useEffect, useState } from "react";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import {
  add_team_player_mutation,
  get_team_players_query,
} from "@services/graphql";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "@views/components/common/loader";
import { useDispatch } from "react-redux";
import { AiFillDelete } from "react-icons/ai";
import {
  delete_player_mutation,
  get_registered_user_query,
} from "@src/services/graphql";

const TeamPlayers = () => {
  const { id } = useParams();
  const { players, name, discipline_max_count, loading } =
    useDynamicSelector("get_team_players");
  const { status: delete_player_status, loading: delete_player_loading } =
    useDynamicSelector("delete_player");
  const { players: captain } = useDynamicSelector("get_registered_user");
  const is_captain = captain?.[0]?.is_captain;

  const dispatch = useDispatch();
  const [open, set_open] = useState(false);
  const [delete_team_modal_open, set_delete_team_modal_open] = useState(false);
  const [player_id, set_player_id] = useState("");
  const [team_id, set_team_id] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  let get_team_players = useAPIRequest(
    "get_team_players",
    get_team_players_query
  );

  let delete_team_player = useAPIRequest(
    "delete_player",
    delete_player_mutation
  );

  let get_registered_user = useAPIRequest(
    "get_registered_user",
    get_registered_user_query
  );

  const delete_player = () => {
    delete_team_player(
      {
        id: player_id,
        team_id: team_id,
      },
      true
    );
  };

  const on_click_open_modal = (data) => {
    set_delete_team_modal_open(true);
    set_player_id(data?.id);
    set_team_id(data?.team_id);
  };

  const on_cancel = () => {
    set_delete_team_modal_open(false);
  };

  const CardComponent = ({ title, onClick }) => {
    return (
      <>
        <div
          className="card"
          style={{ cursor: title.type === "individual" ? "" : "pointer" }}
        >
          <h3 style={{ display: "flex", alignItems: "center", gap: "20px" }}>
            {title?.name}
            {!title?.is_captain && is_captain && (
              <AiFillDelete
                onClick={(e) => {
                  e.stopPropagation();
                  on_click_open_modal(title);
                }}
                style={{
                  marginLeft: "10px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            )}
          </h3>
        </div>
        <Modal
          className="delete_team_modal"
          title={"Confirmation"}
          open={delete_team_modal_open}
          onCancel={on_cancel}
          onOk={() => delete_player(title)}
          confirmLoading={delete_player_loading}
        >
          <span>
            Are you sure you want to remove the player from this team ?
          </span>
        </Modal>
      </>
    );
  };

  const handle_open_model = () => {
    set_open(true);
  };

  const handle_modal_close = () => {
    set_open(false);
  };

  const go_back = () => {
    navigate("/cmtrophy/player/home");
  };

  useEffect(() => {
    get_team_players({ id: id }, true);
    get_registered_user();
  }, []);

  useEffect(() => {
    if (delete_player_status === "success") {
      toast.success("Player Deleted Successfully");
      on_cancel();
      get_team_players({ id: id }, true);
      dispatch(dynamic_clear("delete_player"));
    }
  }, [delete_player_status]);

  let { status, error } = useDynamicSelector("add_team_player");

  useEffect(() => {
    if (status === "success") {
      toast.success("Player Added Successfully");
      handle_modal_close();
      get_team_players({ id: id }, true);
      dispatch(dynamic_clear("add_team_player"));
    } else if (error) {
      toast.error(!error?.message?.includes("prisma") && error?.message);
      dispatch(dynamic_clear("add_team_player"));
    }
  }, [status, error]);
  return (
    <>
      {loading ? (
        <div
          style={{
            minHeight: "600px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h2>{name} - Players</h2>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              paddingRight: "40px",
            }}
          >
            <Button style={{ marginRight: "10px" }} onClick={go_back}>
              Back
            </Button>
            <Button
              disabled={discipline_max_count < players?.length}
              onClick={handle_open_model}
              type="primary"
            >
              Add Player
            </Button>
          </div>
          <div
            style={{
              minHeight: "300px",
              display: "flex",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            <div className="card-container">
              {players?.map((x) => (
                <CardComponent key={x.id} title={x} />
              ))}
            </div>
          </div>
        </>
      )}
      <Modal
        id="manage_user"
        open={open}
        onCancel={handle_modal_close}
        title={t("add_player")}
        destroyOnClose={true}
        children={<TeamForm id={id} onCancel={handle_modal_close} />}
        footer={false}
      />
    </>
  );
};

const TeamForm = ({ id, onCancel }) => {
  const { t } = useTranslation();

  let { loading } = useDynamicSelector("add_team_player");
  let create_team_request = useAPIRequest(
    "add_team_player",
    add_team_player_mutation
  );
  const onFinish = (values) => {
    create_team_request(
      {
        player_code: values.code,
        team_id: id,
      },
      true
    );
  };

  return (
    <Form
      name="player-login-form"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      <Form.Item
        label={t("Mobile number / Player Id")}
        name="code"
        rules={[{ required: true, message: t("code_required_ta") }]}
      >
        {/* <div className="d-flex"> */}
        <Input length={50} />
        {/* </div> */}
      </Form.Item>
      <Row align={"middle"} justify={"end"} gutter={10}>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            // className="player-login-button"
          >
            {t("submit_ta")}
          </Button>
        </Col>
        <Col>
          <Button danger onClick={onCancel}>
            {t("Close")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TeamPlayers;
