import { retrieve_item } from "@helpers/functions";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_events_query,
  get_min_max_partcipants_count_query,
  register_user_mutation,
} from "@services/graphql";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import MobileNumberVerificationForm from "@views/components/login/register_form";
import AddTeamPlayer from "@views/components/register-form/add_team_player";
import Address from "@views/components/register-form/address";
import BankDetails from "@views/components/register-form/bank_details";
import BasicDetails from "@views/components/register-form/basic_registration";
import Department from "@views/components/register-form/department";
import Events from "@views/components/register-form/events";
import Institution from "@views/components/register-form/institution";
import RegisterAadharUrl from "@views/components/register-form/register_aadhar_url";
import RegisterBankPassbook from "@views/components/register-form/register_bank_passbook_url";
import RegisterNative from "@views/components/register-form/register_bonafide_url";
import RegisterIDCard from "@views/components/register-form/register_id_card_url";
import RegisterTeamName from "@views/components/register-form/register_team_name";
import RegistrationType from "@views/pages/registration_type";
import { Button, Col, Form, InputNumber, Row } from "antd";
import { startCase } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PlayerRegisterSuccessModal from "./player_register_success_modal";

const RegisterForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [gender, set_gender] = useState("");
  const [mobile, set_mobile] = useState("");
  const [discipline, set_discipline] = useState("");
  const [category_id, set_category_id] = useState("");
  const [differently_abled, set_differently_abled] = useState("");
  const [is_mandatory, set_is_mandatory] = useState(true);
  const [mobile_verification_status, set_mobile_verification_status] =
    useState(false);
  // const mobile = retrieve_item("mobile");
  const initial_values = { mobile };
  const { type } = useParams();
  const { items: district_list } = useDynamicSelector("get_districts");
  const { status, error, loading } = useDynamicSelector(
    "create_registered_user"
  );
  const dob = Form.useWatch(form, ["player", "dob"]);
  const { status: verify_otp_status } = useDynamicSelector("verify_otp");
  const { status: verify_mobile } = useDynamicSelector("verify_without_otp");
  const is_required = category_id === "school" || category_id === "college";
  const [event_ids, set_event_ids] = useState("");
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [events, set_events] = useState([]);

  console.log("error", error);

  const { items: event_list } = useDynamicSelector("get_events");
  const get_event_list = useAPIRequest("get_events", get_events_query);

  useEffect(() => {
    set_is_mandatory(!is_required);
  }, [is_required]);

  const handle_change = (value) => {
    navigate("/cmtrophy/register-form/" + value);
  };
  const handle_back = () => {
    navigate("/cmtrophy/register-form");
  };
  let register_user = useAPIRequest(
    "create_registered_user",
    register_user_mutation
  );

  const { min_allowed_participants, max_allowed_participants } =
    useDynamicSelector("get_min_max_partcipants_count");

  const get_min_max_partcipants_count_request = useAPIRequest(
    "get_min_max_partcipants_count",
    get_min_max_partcipants_count_query
  );

  useEffect(() => {
    if (discipline) {
      get_min_max_partcipants_count_list();
    }
  }, [discipline]);

  const get_min_max_partcipants_count_list = () => {
    get_min_max_partcipants_count_request(
      {
        json: {
          gender: gender,
          discipline_id: Array.isArray(discipline)
            ? discipline.join(",")
            : discipline,
          // event_id: Array.isArray(event_ids) ? event_ids.join(",") : event_ids,
          category_id: category_id,
        },
      },
      true
    );
  };

  useEffect(() => {
    if (discipline && gender && category_id)
      get_event_list(
        {
          discipline_id: discipline,
          gender: gender,
          category_id: category_id,
          differently_abled_id: differently_abled,
        },
        true
      );
  }, [discipline, gender, category_id, differently_abled]);

  // const handle_submit = (values) => {
  //   debugger;
  //   values.player.dob = moment(new Date(values?.player?.dob));
  //   if (values?.player?.aadhar_url?.file) {
  //     values.player.aadhar_url = values.player.aadhar_url.file.url;
  //   }
  //   // if (values?.player?.bank_passbook_url?.file) {
  //   //   values.player.bank_passbook_url =
  //   //     values.player.bank_passbook_url.file.url;
  //   // }
  //   // if (values?.player?.native_url?.file) {
  //   //   values.player.native_url = values.player.native_url.file.url;
  //   // }
  //   if (values?.player?.id_card_url?.file) {
  //     values.player.id_card_url = values.player.id_card_url.file.url;
  //   }
  //   // if (values?.player?.photo_url?.file) {
  //   //   values.player.photo_url = values.player.photo_url.file.url;
  //   // }
  //   values.user_type = type;
  //   values.mobile = String(mobile);
  //   // if (values?.player?.year_of_graduation)
  //   //   values.player.year_of_graduation = String(
  //   //     values.player.year_of_graduation
  //   //   );
  //   // if (values?.player?.account_no)
  //   //   values.player.account_no = String(values.player.account_no);
  //   if (values?.player?.aadhar_no)
  //     values.player.aadhar_no = String(values.player.aadhar_no);
  //   // if (values?.player?.pincode)
  //   //   values.player.pincode = String(values.player.pincode);
  //   if (values?.player?.registration_no)
  //     values.player.registration_no = String(values.player.registration_no);
  //   if (type === "individual") {
  //     let district = district_list?.find((x) => x.id === values.district_id);
  //     values.player.zone_id = district.zone_id;
  //   }
  //   delete values.confirm;
  //   register_user(
  //     {
  //       data: values,
  //     },
  //     true
  //   );
  // };

  const handle_submit = (values) => {
    const formattedPlayerData = {
      ...values.player,
      dob: moment(new Date(values?.player?.dob)).format("YYYY-MM-DD"),
      aadhar_url: values?.player?.aadhar_url?.file?.url || "",
      native_url: values.player?.native_url?.file?.url || "",
      id_card_url: values?.player?.id_card_url?.file?.url || "",
      aadhar_no: String(values?.player?.aadhar_no),
      registration_no: String(values?.player?.registration_no),
    };

    if (type === "individual") {
      const district = district_list?.find((x) => x.id === values?.district_id);
      if (district) {
        formattedPlayerData.zone_id = district.zone_id;
      }
    }

    // Extract additional players from the dynamically named fields
    const additional_player_list = [];
    const keys = Object.keys(values);

    for (let i = 0; i < max_allowed_participants; i++) {
      if (
        values[`player_name_${i}`] &&
        values[`dob_${i}`] &&
        values[`mobile_no_${i}`] &&
        values[`address_${i}`] &&
        values[`aadhar_no_${i}`]
      ) {
        additional_player_list.push({
          name: values[`player_name_${i}`],
          dob: moment(new Date(values[`dob_${i}`])).format("YYYY-MM-DD"),
          mobile: String(values[`mobile_no_${i}`]),
          aadhar_no: String(values[`aadhar_no_${i}`]),
          address1: values[`address_${i}`],
          city: values[`city_${i}`] || "", // Add any other fields if necessary
        });
      }
    }

    const data = {
      name: values.name || "",
      user_type: type,
      mobile: String(values?.mobile),
      district_id: values?.district_id || "",
      zone_id: formattedPlayerData.zone_id || "",
      // gender: values.gender || "",
      player: formattedPlayerData,
      additional_player_list,
      team_name: values?.team_name || "",
    };

    delete values.confirm; // Remove unwanted fields
    register_user(
      {
        data,
      },
      true
    );
  };

  const handle_on_change = (val) => {
    console.log("val", val);

    if (val?.player?.category_id) {
      set_category_id(val?.player?.category_id);
      if (val?.player?.category_id !== "differently_abled") {
        set_differently_abled("");
        form.setFieldsValue({
          player: {
            differently_abled_id: null, // The ID of the option you want to set
          },
        });
      }
    }
    if (val?.player?.gender) {
      set_gender(val?.player?.gender);
    }
    if (val?.player?.discipline_ids) {
      set_discipline(val?.player?.discipline_ids);
    }
    if (val?.player?.event_ids) {
      set_event_ids(val?.player?.event_ids);
    }
    if (val?.player?.differently_abled_id) {
      set_differently_abled(val?.player?.differently_abled_id);
    }
  };
  useEffect(() => {
    dispatch(dynamic_clear("verify_otp"));
  }, []);
  useEffect(() => {
    if (status === "success") {
      set_is_modal_open(true);
      dispatch(dynamic_clear("create_registered_user"));
      dispatch(dynamic_clear("get_min_max_partcipants_count"));
      // navigate("/cmtrophy/officer/dashboard");
    } else if (error?.message) {
      toast.error(startCase(error?.message));
    } else if (verify_otp_status === "success") {
      toast.success(t("otp_verified_successfully"));
    }
  }, [status, verify_otp_status, error]);
  return (
    <div className="registration-form-container">
      {/* {!type && <RegistrationType onChange={handle_change} />} */}
      {type && (
        <>
          <h1 style={{ textAlign: "center" }}>{"Player Registration"}</h1>
          {/* <MobileNumberVerificationForm
            set_mobile={set_mobile}
            set_mobile_verification_status={set_mobile_verification_status}
            mobile_verification_status={mobile_verification_status}
          />
          <h5 style={{ color: "red" }}> {t("verify_mobile_content")}</h5> */}
          <Form
            layout="vertical"
            style={{ marginTop: "40px" }}
            initialValues={initial_values}
            onValuesChange={handle_on_change}
            onFinish={handle_submit}
            form={form}
            name="register-form"
            id="register-form"
            // disabled={!mobile_verification_status ? true : false}
          >
            <Row gutter={16}>
              <BasicDetails
                type={type}
                form={form}
                is_mandatory={is_mandatory}
              />
              {type === "individual" && (
                <Events
                  category_id={category_id}
                  gender={gender}
                  discipline={discipline}
                  differently_abled={differently_abled}
                  set_events={set_events}
                />
              )}
              {type === "individual" && (
                <>
                  <Address
                    category_id={category_id}
                    // is_mandatory={is_mandatory}
                  />
                  {/* <BankDetails is_mandatory={is_mandatory} /> */}
                  {/* {(category_id === "college" || category_id === "school") && (
                    <Institution
                      category={category_id}
                      is_mandatory={is_mandatory}
                    />
                  )} */}
                  {/* {category_id === "government_servent" && <Department />} */}
                  <Col span={24}>
                    <Row gutter={16} align={"middle"}>
                      <Col>
                        <h2>{t("attachments")} </h2>
                      </Col>
                      <Col>
                        <h5 style={{ color: "red" }}>
                          ( {t("attachment_size")} )
                        </h5>
                      </Col>{" "}
                    </Row>
                  </Col>

                  {/* {(category_id === "college" || category_id === "school") && (
                    <Col xs={24} sm={12}>
                      <RegisterBonafide />
                    </Col>
                  )} */}
                  <Col xs={24} sm={12}>
                    <RegisterAadharUrl />
                  </Col>
                  <Col xs={24} sm={12}>
                    {/* <RegisterBankPassbook is_mandatory={is_mandatory} /> */}
                  </Col>

                  {/* {(category_id === "college" ||
                    category_id === "school" ||
                    category_id === "government_servent") && (
                    <Col xs={24} sm={12}>
                      <RegisterIDCard
                        category_id={category_id}
                        is_mandatory={is_mandatory}
                      />
                    </Col>
                  )} */}
                  {/* {category_id === "general" && (
                    <Col xs={24} sm={12}>
                      <RegisterNative />
                    </Col>
                  )} */}
                </>
              )}
            </Row>
            {console.log("trtrt", events?.[0]?.name)}

            {max_allowed_participants > 1 &&
              (events?.[0]?.name === "Doubles" ||
                events?.[1]?.name === "Doubles" ||
                events?.[0]?.type === "team") && (
                <div>
                  <RegisterTeamName />
                  <AddTeamPlayer handle_submit={handle_submit} />
                </div>
              )}

            {/* {verify_otp_status === "success" && ( */}
            <Row justify={"end"} gutter={30} align={"bottom"}>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>
                  {t("submit")}
                </Button>
              </Col>
            </Row>
            {/* )} */}
          </Form>

          <PlayerRegisterSuccessModal
            set_is_modal_open={set_is_modal_open}
            is_modal_open={is_modal_open}
          />
        </>
      )}
    </div>
  );
};

export default RegisterForm;
