import { gql } from "@apollo/client";

export const login_query = gql`
  query login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      name
      image_url
      mobile
      force_password_change
      username
      session_id
      roles {
        id
        name
      }
      registered_user {
        id
        name
        user_type
        institute_type
        education_sector
        zone_id
        district_id
        gender
      }
      error {
        message
        status
      }
    }
  }
`;

export const get_otp_query = gql`
  query get_otp($mobile: String!) {
    get_otp(mobile: $mobile) {
      id
      status
      error {
        message
        status
      }
    }
  }
`;

export const verify_otp_query = gql`
  query verify_otp($mobile: String!, $otp: String!) {
    verify_otp(mobile: $mobile, otp: $otp) {
      id
      status
      error {
        message
        status
      }
    }
  }
`;

export const officer_login_query = gql`
  query officer_login($username: String!, $password: String!) {
    officer_login(username: $username, password: $password) {
      id
      name
      image_url
      mobile
      force_password_change
      username
      session_id
      roles {
        id
        name
      }
      officer {
        id
        name
        zone_id
        district_id
      }
      error {
        message
        status
      }
    }
  }
`;
export const verify_without_otp_query = gql`
  query verify_without_otp($mobile: String!) {
    verify_without_otp(mobile: $mobile) {
      id
      status
      error {
        message
        status
      }
    }
  }
`;
