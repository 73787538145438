import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// import commonTranslationEN from "@src/assets/locales/en/common.json";
import commonTranslationEN from "../src/assets/locales/en/common.json";
import TableTranslationEN from "../src/assets/locales/en/table.json";
import FormTranslationEN from "../src/assets/locales/en/form.json";

import commonTranslationTA from "../src/assets/locales/ta/common.json";
import TableTranslationTA from "../src/assets/locales/en/table.json";
import FormTranslationTA from "../src/assets/locales/en/form.json";

const resources = {
  ta: {
    common: commonTranslationTA,
    table: TableTranslationTA,
    form: FormTranslationTA,
  },
  en: {
    common: commonTranslationEN,
    table: TableTranslationEN,
    form: FormTranslationEN,
  },
};
let currentLanguage = localStorage.getItem("current_language");

i18n.use(initReactI18next).init({
  resources,
  lng: currentLanguage || "en",
  defaultNS: "common",
  interpolation: {
    escapeValue: false,
  },
});

export const change_language = (lang) => {
  localStorage.setItem("current_language", lang);
  i18n.changeLanguage(lang);
};

export default i18n;
