import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { useDynamicSelector } from "@services/redux";
import { get_categories_query } from "@services/graphql/master_data";

const RegisterCategory = ({ category_ids }) => {
  const { t } = useTranslation();
  const [categories, set_categories] = useState([]);
  const { items: category_list } = useDynamicSelector("get_categories");

  let get_category_list = useAPIRequest("get_categories", get_categories_query);

  useEffect(() => {
    get_category_list({}, true);
  }, []);

  useEffect(() => {
    if (category_ids?.length && category_list?.length) {
      set_categories(category_list?.filter((x) => category_ids.includes(x.id)));
    }
  }, [category_list, category_ids]);

  return (
    <div>
      <Form.Item
        name={["player", "category_id"]}
        label={t("category")}
        rules={[{ required: true, message: t("category_required") }]}
      >
        <Select
          options={categories}
          fieldNames={{ label: "name", value: "id" }}
          filterOption={(input, option) =>
            option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        />
      </Form.Item>
    </div>
  );
};

export default RegisterCategory;
