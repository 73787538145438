import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "./user_layout/footer";
import OfficerHeader from "./officer_layout/officerheader";

const OfficerLayout = () => {
  return (
    <div>
      <OfficerHeader />
      <div style={{ minHeight: "100vh" }}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default OfficerLayout;
