import { useAPIRequest } from "@helpers/hooks";
import { get_event_schedules_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import EventScheduleModal from "@views/components/event_schedule/event_form/event_schedule_modal";
import AddEventSchedule from "@views/components/event_schedule/event_schedule_add_schedyle_event/add_event_schedule";
import EventScheduleFilterForm from "@views/components/event_schedule/event_schedule_filter/event_schedule_filter_form";
import EventScheduleTable from "@views/components/event_schedule/event_schedule_table/event_schedule_table";
import { Form } from "antd";
import React, { useEffect, useState } from "react";

const ViewEventSchedule = () => {
  const [is_event_schedule_modal_open, set_is_event_schedule_modal_open] =
    useState(false);
  const [modal_type, set_modal_type] = useState("add");
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);
  const [filter_form] = Form.useForm();
  const discipline_id = Form.useWatch("discipline_id", filter_form);
  const category_id = Form.useWatch("category_id", filter_form);
  const gender = Form.useWatch("gender", filter_form);
  const district_id = Form.useWatch("district_id", filter_form);
  const event_id = Form.useWatch("event_id", filter_form);
  const round = Form.useWatch("round", filter_form);
  const [selected_schedule_event_id, set_selected_schedule_event_id] =
    useState("");
  const { status: delete_status } = useDynamicSelector("deleteEventSchedule");
  const get_event_schedules_request = useAPIRequest(
    "getEventSchedules",
    get_event_schedules_query
  );

  const get_event_schedules = () => {
    get_event_schedules_request(
      {
        json: {
          page_number: page_number,
          page_limit: page_limit,
          discipline_id: discipline_id,
          category_id: category_id,
          gender: gender,
          district_id: district_id,
          event_id: event_id,
          round: round,
        },
      },
      true
    );
  };

  useEffect(() => {
    get_event_schedules();
  }, [
    page_number,
    page_limit,
    discipline_id,
    category_id,
    gender,
    district_id,
    event_id,
    round,
  ]);

  useEffect(() => {
    if (delete_status === "success") {
      get_event_schedules();
    }
  }, [delete_status]);
  return (
    <>
      <div style={{ height: "100%", width: "100%", padding: "25px" }}>
        <EventScheduleFilterForm form={filter_form} />
        <AddEventSchedule
          set_is_event_schedule_modal_open={set_is_event_schedule_modal_open}
          set_modal_type={set_modal_type}
        />
        <EventScheduleTable
          set_is_event_schedule_modal_open={set_is_event_schedule_modal_open}
          set_modal_type={set_modal_type}
          set_selected_schedule_event_id={set_selected_schedule_event_id}
          selected_schedule_event_id={selected_schedule_event_id}
          set_page_number={set_page_number}
          set_page_limit={set_page_limit}
          page_number={page_number}
          page_limit={page_limit}
        />
        <EventScheduleModal
          set_is_event_schedule_modal_open={set_is_event_schedule_modal_open}
          is_event_schedule_modal_open={is_event_schedule_modal_open}
          modal_type={modal_type}
          selected_schedule_event_id={selected_schedule_event_id}
          get_event_schedules={get_event_schedules}
        />
      </div>
    </>
  );
};

export default ViewEventSchedule;
