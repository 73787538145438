import { HOME_AWARD } from "@helpers/image_constant";
import React from "react";

const HomeAward = () => {
  return (
    <div className="home-award-main-container">
      <div className="home-award-container">
        <img src={HOME_AWARD} alt="award" />
      </div>
    </div>
  );
};

export default HomeAward;
