import React, { useEffect } from "react";
import { Modal, Form, Input, Button, Select } from "antd";
import { useAPIRequest } from "@helpers/hooks";
import {
  create_event_venue_mutation,
  get_districts_query,
  get_event_venues_query,
  update_event_venue_mutation,
} from "@services/graphql";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { retrieve_item } from "@helpers/functions";

const VenueAddModal = ({
  is_modal_open,
  set_is_modal_open,
  event_modal_type,
  form,
  event_venue_id,
  event_venue_list,
}) => {
  const dispatch = useDispatch();
  const role = retrieve_item("role");
  const district_id = retrieve_item("district_id");

  const { items: district_list } = useDynamicSelector("get_districts");
  const event_venue = useDynamicSelector("getEventVenue");

  const {
    status: create_event_venue_status,
    error: create_event_venue_error,
    loading: create_event_venue_loading,
  } = useDynamicSelector("createEventVenue");

  const {
    status: update_event_venue_status,
    error: update_event_venue_error,
    loading: update_event_venue_loading,
  } = useDynamicSelector("updateEventVenue");

  let get_district_list = useAPIRequest("get_districts", get_districts_query);

  let create_event_venue = useAPIRequest(
    "createEventVenue",
    create_event_venue_mutation
  );

  let update_event_venue = useAPIRequest(
    "updateEventVenue",
    update_event_venue_mutation
  );

  useEffect(() => {
    get_district_list({}, true);
  }, []);

  console.log("role", district_id);

  useEffect(() => {
    if (create_event_venue_status === "success") {
      toast.success("Event Venue Created Successfully");
      set_is_modal_open(false);
      event_venue_list();
      dispatch(dynamic_clear("createEventVenue"));
      form.resetFields();
    } else if (update_event_venue_status === "success") {
      toast.success("Event Venue Created Successfully");
      set_is_modal_open(false);
      event_venue_list();
      dispatch(dynamic_clear("updateEventVenue"));
    } else if (create_event_venue_error) {
      toast.error(create_event_venue_error?.message);
      dispatch(dynamic_clear("createEventVenue"));
    } else if (update_event_venue_error) {
      toast.error(update_event_venue_error?.message);
      dispatch(dynamic_clear("updateEventVenue"));
    }
  }, [
    create_event_venue_status,
    create_event_venue_error,
    update_event_venue_error,
    update_event_venue_status,
  ]);

  useEffect(() => {
    if (event_venue && event_modal_type === "edit") {
      form.setFieldsValue({
        place: event_venue.name,
        address: event_venue.address,
        district: event_venue.district_id,
      });
    }
  }, [event_venue]);
  const mutation_update_event_venue = (values) => {
    update_event_venue({
      json: {
        id: event_venue_id,
        data: {
          name: values?.place,
          address: values?.address,
          district_id: values?.district,
        },
      },
    });
  };

  const mutation_event_venue = (values) => {
    create_event_venue({
      json: {
        data: {
          name: values?.place,
          address: values?.address,
          district_id: values?.district,
        },
      },
    });
  };

  const district_options = district_list?.map((district) => ({
    value: district.id,
    label: district.name,
  }));

  const on_change = (value) => {
    console.log("Selected:", value);
  };

  const on_search = (value) => {
    console.log("Search:", value);
  };

  const on_finish = (values) => {
    if (event_modal_type === "add") {
      mutation_event_venue(values);
    } else {
      mutation_update_event_venue(values);
    }
  };

  const on_cancel = () => {
    set_is_modal_open(false);
  };

  return (
    <Modal
      title={event_modal_type === "add" ? "Add Venue" : "Edit Venue"}
      visible={is_modal_open}
      onCancel={on_cancel}
      footer={[
        <Button key="cancel" onClick={on_cancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => form.submit()}
          loading={
            event_modal_type === "add"
              ? create_event_venue_loading
              : update_event_venue_loading
          }
        >
          {event_modal_type === "add" ? "Submit" : "Update"}
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={on_finish}>
        <Form.Item
          name="place"
          label="Place"
          rules={[{ required: true, message: "Please enter the place name" }]}
        >
          <Input placeholder="Enter the place name" />
        </Form.Item>

        <Form.Item
          name="address"
          label="Address"
          rules={[{ required: true, message: "Please enter the address" }]}
        >
          <Input.TextArea placeholder="Enter the address" />
        </Form.Item>
        {!district_id && (
          <Form.Item
            name="district"
            label="District"
            rules={[{ required: true, message: "Please Select the District" }]}
          >
            <Select
              showSearch
              placeholder="Select a district"
              optionFilterProp="label"
              onChange={on_change}
              onSearch={on_search}
              options={district_options}
              allowClear
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default VenueAddModal;
