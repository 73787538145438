import React, { useState, useEffect } from "react";
import { Button, Form, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { UploadOutlined } from "@ant-design/icons";
import {
  action,
  handle_before_upload,
  handle_file_changed,
  handle_upload,
} from "@helpers/file_upload";

const UpdateAadharUrl = ({ initialFileList }) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState(initialFileList || []);

  useEffect(() => {
    if (initialFileList) {
      setFileList(initialFileList);
    }
  }, [initialFileList]);

  const onFileChange = (info) => {
    let newFileList = [...info.fileList];

    // Limit the number of uploaded files to 1
    newFileList = newFileList.slice(-1);

    // Update the state with the new file list
    setFileList(newFileList);
    handle_file_changed(info); // Your custom change handler
  };

  return (
    <div>
      <Form.Item
        name={["player", "aadhar_url"]}
        label={t("aadhar_attachment")}
        rules={[{ required: true, message: t("aadhar_required") }]}
      >
        <Upload
          className="thumbnail_image_container"
          maxCount={1}
          action={action}
          customRequest={(e) => handle_upload(e)}
          beforeUpload={(args) => handle_before_upload(args)}
          onChange={onFileChange}
          fileList={fileList}
          showUploadList={true}
        >
          <Button icon={<UploadOutlined />}>{t("click_to_upload")}</Button>
        </Upload>
      </Form.Item>
    </div>
  );
};

export default UpdateAadharUrl;
