import React from "react";
import { Col, Form, InputNumber } from "antd";
import { useTranslation } from "react-i18next";
import RegisterAddressOne from "./register_addres1";
import RegisterAddressTwo from "./register_addres2";
import RegisterCity from "./register_city";
import RegisterPinCode from "./register_pincode";
import RegisterDistrict from "./register_district_id";

const Address = ({ category_id, is_mandatory }) => {
  const { t } = useTranslation();
  return (
    <>
      <Col span={24}>
        <h2>{t("address")}</h2>
      </Col>
      <Col xs={24} sm={12}>
        <RegisterAddressOne />
      </Col>
      {/* <Col xs={24} sm={12}>
        <RegisterAddressTwo />
      </Col> */}
      {/* <Col xs={24} sm={12}>
        <RegisterCity />

      
      </Col> */}
      <Col xs={24} sm={12}>
        <RegisterDistrict category_id={category_id} />
      </Col>
      <Col xs={24} sm={12}>
        {/* <RegisterPinCode is_mandatory={is_mandatory} /> */}
      </Col>
    </>
  );
};

export default Address;
