import { useAPIRequest } from "@helpers/hooks";
import {
  get_disciplines_team_filter_query,
  get_teams_query,
} from "@services/graphql";
import TeamListFilters from "@views/components/team/team_list_filters";
import TeamListTable from "@views/components/team/team_list_table";
import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Team = () => {
  const [form] = Form.useForm(); // Use array destructuring for form initialization
  const [search_value, set_search_value] = useState("");
  const { t } = useTranslation();
  const [page_number, set_page_number] = useState(1);
  const [page_limit, set_page_limit] = useState(10);

  const team_filter_category_id = Form.useWatch("category", form);
  const team_filter_discipline_id = Form.useWatch("discipline", form);
  const team_filter_event_id = Form.useWatch("event", form);
  const team_filter_gender = Form.useWatch("gender", form);
  const team_filter_district = Form.useWatch("district", form);
  const is_participated = Form.useWatch("is_participated", form);
  const get_team_list_request = useAPIRequest("getTeams", get_teams_query);
  const get_disciplines_schedule_request = useAPIRequest(
    "get_disciplines_team_filter",
    get_disciplines_team_filter_query
  );
  const get_team_list = () => {
    get_team_list_request(
      {
        page_number: page_number,
        page_limit: page_limit,
        search_string: search_value,
        category_id: team_filter_category_id,
        discipline_id: team_filter_discipline_id,
        event_id: team_filter_event_id,
        gender: team_filter_gender,
        district_id: team_filter_district,
        is_participated: is_participated,
      },
      true
    );
  };

  useEffect(() => {
    if (team_filter_gender && team_filter_category_id) {
      get_disciplines_schedule_request(
        {
          gender: team_filter_gender,
          category_id: team_filter_category_id,
          // differently_abled_id: schedule_differently_abled,
        },
        true
      );
    }
  }, [team_filter_gender, team_filter_category_id]);
  useEffect(() => {
    get_team_list();
  }, [
    page_number,
    page_limit,
    team_filter_district,
    team_filter_event_id,
    team_filter_discipline_id,
    team_filter_category_id,
    search_value,
    is_participated,
    team_filter_gender,
  ]);

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex", gap: "10px" }}>
        <h2 className="registered_player_title">{t("teams")}</h2>
      </div>
      <TeamListFilters
        form={form}
        search_value={search_value}
        set_search_value={set_search_value}
      />
      <TeamListTable
        set_page_limit={set_page_limit}
        page_limit={page_limit}
        page_number={page_number}
        set_page_number={set_page_number}
        get_team_list={get_team_list}
      />
    </div>
  );
};

export default Team;
