import {
  FIFTH_SLIDER_IMAGE,
  FIRST_SLIDER_IMAGE,
  FOURTH_SLIDER_IMAGE,
  SECOND_SLIDER_IMAGE,
  THIRD_SLIDER_IMAGE,
} from "@helpers/image_constant";
import React from "react";
import Carousel from "react-multi-carousel";

const HomeSlider = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 650 },
      items: 1,
    },
    small_tablet: {
      breakpoint: { max: 650, min: 550 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 550, min: 0 },
      items: 1,
    },
  };
  let images = [
    SECOND_SLIDER_IMAGE,
    THIRD_SLIDER_IMAGE,
    FOURTH_SLIDER_IMAGE,
    FIFTH_SLIDER_IMAGE,
  ];
  return (
    <div>
      <Carousel
        responsive={responsive}
        infinite={true}
        draggable={false}
        swipeable={false}
        slidesToShow={1}
        showDots={true}
      >
        {images.map((list) => {
          return (
            <div className="home-slider-item">
              <img src={list} className="home-slider-image" alt="" />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default HomeSlider;
