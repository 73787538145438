import { useAPIRequest } from "@helpers/hooks";
import { get_event_venue_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Pagination, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";
import Loader from "../common/loader";

const EventVenueTable = ({
  set_event_modal_type,
  set_is_modal_open,
  set_event_venue_id,
  event_venue_id,
  set_delete_event_venue_id,
  set_delete_modal_open,
}) => {
  const { t } = useTranslation();
  const [page_limit, set_page_limit] = useState(3);
  const [page_number, set_page_number] = useState(1);

  const {
    items: event_venue_list,
    loading: event_venue_list_loading,
    pagination,
  } = useDynamicSelector("getEventVenues");

  const get_event_venue = useAPIRequest("getEventVenue", get_event_venue_query);

  useEffect(() => {
    get_event_venue({ json: { id: event_venue_id } }, true);
  }, [event_venue_id]);

  const handle_edit_venue = (value) => {
    set_event_venue_id(value);
    set_event_modal_type("edit");
    set_is_modal_open(true);
  };

  const handleDelete = (value) => {
    set_delete_event_venue_id(value);
    set_delete_modal_open(true);
  };

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",
      key: "sno",
      render: (text, record, index) => {
        return (page_number - 1) * page_limit + index + 1;
      },
    },

    { title: t("place"), dataIndex: "name", key: "name" },
    {
      title: t("address"),
      dataIndex: "address",
      key: "address",
    },
    {
      title: t("actions"),
      key: "actions",
      width: 200,
      render: (_, record) => (
        <div>
          <AiOutlineEdit
            className="action-icon"
            title={t("Edit Event Venue")}
            onClick={() => handle_edit_venue(record.id)}
          />
          <AiOutlineDelete
            className="action-icon delete-icon"
            title={t("Delete Event Venue")}
            onClick={() => handleDelete(record.id)}
          />
        </div>
      ),
    },
  ];

  const fetch_data = (val, value) => {
    set_page_number(val);
    set_page_limit(value);
  };

  return (
    <div>
      {/* {loading ? (
        <div
          style={{
            minHeight: "400px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : ( */}
      <div>
        <Table
          dataSource={event_venue_list}
          columns={columns}
          pagination={false}
          rowClassName={(record, index) => (index % 2 === 0 ? "even-row" : "")}
          className="custom-table-header"
          loading={event_venue_list_loading ? <Loader /> : ""}
          bordered={true}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px",
          }}
        >
          <Pagination
            total={pagination?.total_count}
            showSizeChanger={true}
            current={page_number}
            hideOnSinglePage={false}
            onChange={fetch_data}
          />
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
export default EventVenueTable;
