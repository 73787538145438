import React from "react";
import { Form, Select } from "antd";
import { useTranslation } from "react-i18next";

const RegisterInstitutionType = () => {
  const { t } = useTranslation();
  const institution_type_list = [
    {
      id: "government",
      name: "Government",
    },
    {
      id: "private",
      name: "Private",
    },
  ];
  return (
    <div>
      <Form.Item
        name="institute_type"
        label={t("institution_type")}
        rules={[{ required: true, message: t("institution_type_required") }]}
      >
        <Select
          options={institution_type_list}
          fieldNames={{ label: "name", value: "id" }}
          filterOption={(input, option) =>
            option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
          }
        />
      </Form.Item>
    </div>
  );
};

export default RegisterInstitutionType;
