import React from "react";
import { useAPIRequest } from "@helpers/hooks";
import { delete_event_venue_mutation } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Button, Modal } from "antd";

const DeleteEventVenueModal = ({
  delete_modal_open,
  set_delete_modal_open,
  delete_event_venue_id,
}) => {
  const { loading: delete_venue_loading } =
    useDynamicSelector("deleteEventVenue");
  const delete_event_venue = useAPIRequest(
    "deleteEventVenue",
    delete_event_venue_mutation
  );

  const handle_delete_click = () => {
    delete_event_venue(
      {
        json: {
          id: delete_event_venue_id,
        },
      },
      true
    );
  };

  const on_cancel = () => {
    set_delete_modal_open(false);
  };

  return (
    <Modal
      title={"Delete Venue"}
      visible={delete_modal_open}
      onCancel={on_cancel}
      footer={[
        <Button key="cancel" onClick={on_cancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={handle_delete_click}
          loading={delete_venue_loading}
        >
          Submit
        </Button>,
      ]}
    >
      {"Are Sure to Delete Event Venue"}
    </Modal>
  );
};

export default DeleteEventVenueModal;
