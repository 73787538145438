import { gql } from "@apollo/client";

export const update_many_player_participant = gql`
  mutation updateManyPlayersParticipation(
    $ids_list: [String]!
    $is_participated: Boolean!
  ) {
    updateManyPlayersParticipation(
      ids_list: $ids_list
      is_participated: $is_participated
    ) {
      id
      status
      error {
        status
        message
      }
    }
  }
`;

export const get_participated_count_report_query = gql`
  query {
    get_participated_count_report {
      items {
        district_name
        player_counts {
          discipline_name
          count_object {
            male_count
            female_count
            participated_male_count
            participated_female_count
          }
        }
      }
      error {
        message
      }
    }
  }
`;
