import { useAPIRequest } from "@helpers/hooks";
import { get_disciplines_schedule_query } from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const EventScheduleDisciplineFormItem = ({ form }) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const schedule_gender = Form.useWatch("gender", form);
  const schedule_category_id = Form.useWatch("category", form);
  const schedule_differently_abled = Form.useWatch("differently_abled", form);

  const { items: discipline_list } = useDynamicSelector(
    "get_disciplines_schedule"
  );

  const get_disciplines_schedule_request = useAPIRequest(
    "get_disciplines_schedule",
    get_disciplines_schedule_query
  );

  useEffect(() => {
    if (schedule_gender && schedule_category_id) {
      get_disciplines_schedule_request(
        {
          gender: schedule_gender,
          category_id: schedule_category_id,
          differently_abled_id: schedule_differently_abled,
        },
        true
      );
    }
  }, [schedule_gender, schedule_category_id, schedule_differently_abled]);

  return (
    <Col span={12}>
      <Form.Item
        name="discipline"
        label={t("discipline")}
        rules={[{ required: true, message: t("Please enter events") }]}
      >
        <Select placeholder={t("Select category")} allowClear>
          {discipline_list?.map((discipline, index) => (
            <Option value={discipline?.id} key={index}>
              {discipline?.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Col>
  );
};
export default EventScheduleDisciplineFormItem;
