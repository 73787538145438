import { event_round_options, gender_options } from "@helpers/constants";
import { retrieve_item } from "@helpers/functions";
import { useAPIRequest } from "@helpers/hooks";
import {
  get_categories_query,
  get_disciplines_query,
  get_disciplines_schedule_query,
  get_districts_query,
  get_events_query,
} from "@services/graphql";
import { useDynamicSelector } from "@services/redux";
import { Col, Form, Row, Select } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const EventScheduleFilterForm = (props) => {
  const { form } = props;
  const { t } = useTranslation();
  const { items: discipline_list } = useDynamicSelector("get_disciplines");
  const { items: district_list } = useDynamicSelector("get_districts");
  const { items: event_list } = useDynamicSelector("get_events");
  const { items: category_list } = useDynamicSelector("get_categories");
  const session_id = retrieve_item("session_id");
  const role_name = retrieve_item("name");
  const get_disciplines_schedule_request = useAPIRequest(
    "get_disciplines",
    get_disciplines_query
  );
  const get_district_list = useAPIRequest("get_districts", get_districts_query);
  const get_event_list = useAPIRequest("get_events", get_events_query);
  const get_category_list = useAPIRequest(
    "get_categories",
    get_categories_query
  );

  console.log("session_id", session_id);

  const on_change_values = (value, values) => {
    if (values?.discipline_id && values?.category_id && values?.gender) {
      get_event_list({
        discipline_id: values?.discipline_id,
        category_id: values?.category_id,
        gender: values?.gender,
      });
    } else {
      form.setFieldValue("event_id", null);
    }
    if (values?.category_id && values?.gender) {
      get_disciplines_schedule_request();
    } else {
      form.setFieldValue("discipline_id", null);
    }
  };

  useEffect(() => {
    get_district_list();
    get_category_list();
  }, []);

  return (
    <div>
      <Form layout="vertical" onValuesChange={on_change_values} form={form}>
        <Row gutter={[16, 16]}>
          {role_name === "Admin" && (
            <Col span={8}>
              <Form.Item name="district_id" label={t("form:district")}>
                <Select
                  options={district_list}
                  fieldNames={{ label: "name", value: "id" }}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option?.name
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
          )}

          <Col span={8}>
            <Form.Item name="category_id" label={t("form:category")}>
              <Select
                options={category_list}
                fieldNames={{ label: "name", value: "id" }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="gender" label={t("form:gender")}>
              <Select options={gender_options} allowClear showSearch />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="discipline_id" label={t("form:Discipline")}>
              <Select
                options={discipline_list}
                fieldNames={{ label: "name", value: "id" }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="event_id" label={t("form:events")}>
              <Select
                options={event_list}
                fieldNames={{ label: "name", value: "id" }}
                allowClear
                showSearch
                filterOption={(input, option) =>
                  option?.name?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
                  0
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="round" label={t("form:Round")}>
              <Select options={event_round_options} allowClear showSearch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EventScheduleFilterForm;
