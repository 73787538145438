import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useTranslation } from "react-i18next";
import { Button, Pagination, Table, Card, Row, Col, Typography } from "antd";
import {
  create_individual_performance_mutation,
  get_event_schedule_players_query,
  get_individual_fixture_query,
} from "@services/graphql/performance";
import toast from "react-hot-toast";
import { get_one_event_query } from "@services/graphql";
import { useAPIRequest } from "@helpers/hooks";

const { Text, Title } = Typography;

const ParticipantsDetails = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    event_id,
    event_schedule_line_item_id,
    schedule,
    participantList,
    close,
    event_schedule_id,
    rerender,
  } = props;
  const [removeList, setRemoveList] = useState(false);
  const [playersList, setPlayersList] = useState([]);

  const { t } = useTranslation();
  const [eventId, setEventId] = useState([]);
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);

  const {
    loading: createLoading,
    status,
    error,
  } = useDynamicSelector("createIndividualParticipations");
  const { items: participants } = useDynamicSelector(
    "getIndividualParticipations"
  );
  const [current, setCurrent] = useState(1);

  const { loading, items, total_items } = useDynamicSelector(
    "getEventSchedulePlayers"
  );
  const { item: event } = useDynamicSelector("get_event");

  const get_event_schedule_players_request = useAPIRequest(
    "getEventSchedulePlayers",
    get_event_schedule_players_query
  );
  const create_individual_performance_request = useAPIRequest(
    "createIndividualParticipations",
    create_individual_performance_mutation
  );

  const get_individual_fixture_request = useAPIRequest(
    "getIndividualParticipations",
    get_individual_fixture_query
  );
  const get_event_request = useAPIRequest("get_event", get_one_event_query);

  useEffect(() => {
    if (error === "Failure") {
      toast.error(error);

      close();
      dispatch(dynamic_clear("createIndividualParticipations"));
    } else if (status === "success") {
      toast.success("Participants Added successfully");

      get_individual_fixture_request({
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
          page_number: 1,
          page_limit: 10,
          search_string: "",
        },
      });

      close();
      dispatch(dynamic_clear("createIndividualParticipations"));
    }
  }, [error, status]);

  useEffect(() => {
    if (event_id) {
      get_event_request({
        json: {
          id: event_id,
        },
      });
    }
  }, [event_id, rerender]);

  useEffect(() => {
    if (event_id) {
      get_event_schedule_players_request(
        {
          json: {
            event_id: event_id,
            event_schedule_id: event_schedule_id,
            page_number: 1,
            page_limit: 10,
          },
        },
        true
      );
    }
    setAlreadySelectedRows([]);
  }, [event_id, rerender]);

  useEffect(() => {
    if (items && participants?.length) {
      let temp = [];
      items?.filter((x) => {
        if (!participants.some((y) => y?.player_id === x.id)) temp.push(x);
      });
      setPlayersList(temp);
    } else {
      setPlayersList(items);
    }
  }, [participants, items]);

  useEffect(() => {
    if (event_schedule_line_item_id) {
      get_individual_fixture_request({
        json: {
          event_schedule_line_item_id: event_schedule_line_item_id,
        },
      });
    }
  }, [event_schedule_line_item_id]);
  console.log("schedule", schedule);

  useEffect(() => {
    let temp = [];
    if (schedule) {
      temp = schedule?.[0]?.event_schedule_line_items?.map((x) => {
        return x.event?.id;
      });
    }
    setEventId(temp);
  }, [props]);

  const fetchData = (value, t) => {
    get_event_schedule_players_request({
      json: {
        event_id: event_id,
        event_schedule_id: event_schedule_id,
        page_number: value,
        page_limit: t,
      },
    });

    setCurrent(value);
  };

  let columns = [
    {
      title: t("Player Name"),
      dataIndex: "name",
      key: "name",
      align: "left",
    },
    {
      title: t("Player Code"),
      dataIndex: "player_code",
    },
  ];

  const formatDisplay = (date) => {
    if (date) {
      date = new Date(Number(date));
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }
  };

  const handleSubmit = () => {
    create_individual_performance_request(
      {
        json: {
          players: alreadySelectedRows,
          remove_players_and_add_to_new_schedule: removeList,
          event_schedule_line_item_id: event_schedule_line_item_id,
        },
      },
      true
    );
  };

  return (
    <>
      <Card style={{ width: "100%", padding: "20px" }}>
        {/* <Row>
          <Col span={24}>
            <Title level={4}>{t("Add Participant")}</Title>
          </Col>
        </Row> */}
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Text strong>{t("Schedule Name")}:</Text>
              </Col>
              <Col span={16}>
                <Text>{schedule.map((x) => x.name).join(", ")}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Text strong>{t("Venue")}:</Text>
              </Col>
              <Col span={16}>
                <Text>
                  {schedule.map((x) => x?.event_venue?.name).join(", ")}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Text strong>{t("Discipline")}:</Text>
              </Col>
              <Col span={16}>
                <Text>
                  {schedule
                    .map(
                      (x) =>
                        x.event_schedule_line_items[0]?.event?.discipline?.name
                    )
                    .join(", ")}
                </Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Text strong>{t("Event")}:</Text>
              </Col>
              <Col span={16}>
                <Text>{event?.name}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Text strong>{t("Round")}:</Text>
              </Col>
              <Col span={16}>
                <Text>{schedule.map((x) => x.round?.name).join(", ")}</Text>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={8}>
                <Text strong>{t("Level")}:</Text>
              </Col>
              <Col span={16}>
                <Text>{schedule.map((x) => x.level?.name).join(", ")}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <Text strong>{t("Date")}:</Text>
          </Col>
          <Col span={16}>
            <Text>
              {schedule.map((x) => formatDisplay(x?.date_time)).join(", ")}
            </Text>
          </Col>
        </Row>

        <Table
          rowKey="id"
          rowSelection={{
            selectedRowKeys: alreadySelectedRows,
            onChange: setAlreadySelectedRows,
          }}
          loading={loading}
          dataSource={playersList}
          columns={columns}
          pagination={false}
        />

        <Pagination
          total={total_items}
          showSizeChanger
          current={current}
          hideOnSinglePage={false}
          onChange={fetchData}
        />

        <Row justify="end" style={{ marginTop: "20px" }}>
          <Col>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={createLoading}
            >
              {t("Submit")}
            </Button>
          </Col>
          <Col>
            <Button danger onClick={props.close} style={{ marginLeft: "10px" }}>
              {t("Close")}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ParticipantsDetails;
