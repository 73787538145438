import React, { useEffect, useState } from "react";
import { Form, InputNumber, Button, Modal, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAPIRequest } from "@helpers/hooks";
import { dynamic_clear, useDynamicSelector } from "@services/redux";
import { useDispatch } from "react-redux";
import RegisterTermsContents from "../login/register_terms";
import { get_otp_for_forgot_password_query } from "@services/graphql/forgot_password";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, set_open] = useState(false);
  const [mobile, set_mobile] = useState(""); // State to store mobile number
  let otp_request = useAPIRequest(
    "get_otp_for_forgot_password",
    get_otp_for_forgot_password_query
  );
  const {
    status: get_otp,
    loading,
    error,
    ...rest
  } = useDynamicSelector("get_otp_for_forgot_password");

  const onFinish = (values) => {
    set_mobile(values?.mobile);
    otp_request(
      {
        mobile: String(values?.mobile),
      },
      true
    );
  };

  const onFinishFailed = (errorInfo) => {};

  useEffect(() => {
    if (get_otp === "success") {
      navigate("/cmtrophy/verify-otp", { state: { mobile } });
      dispatch(dynamic_clear("get_otp_for_forgot_password"));
    } else if (error?.message) {
      toast.error(error?.message);
      dispatch(dynamic_clear("login"));
    }
  }, [get_otp, navigate, mobile, dispatch, error]);
  const handle_click = () => {
    navigate("/cmtrophy/player-login");
  };
  return (
    <div className="login-container">
      <div className="player-login-box">
        <h3 className="forgot-password-title">{t("forgot_password")}</h3>
        <Form
          name="forgot-password-form"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <Form.Item
            label={t("registered_mobile_number")}
            name="mobile"
            rules={[
              { required: true, message: t("please_input_your_mobile_number") },
            ]}
          >
            <InputNumber
              maxLength={10}
              changeOnWheel={false}
              controls={false}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Row align={"middle"} justify={"center"} gutter={10}>
            <Col>
              <Button danger htmlType="button" onClick={handle_click}>
                {t("back")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                className="forgot-password-button"
                loading={loading}
              >
                {t("get_otp")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default ForgotPassword;
