export const YOUTUBE_LINK =
  "https://www.youtube.com/channel/UCPaKRND02sjBvclUdi5-77A";
export const TWITTER_LINK =
  "https://x.com/SportsTN_";
export const VERTACE_LINK = "https://vertace.com/";
export const ministry_of_Youth_Affairs_and_sports_link = "https://yas.nic.in/";
export const sports_authority_of_India_link =
  "https://sportsauthorityofindia.gov.in/sai/";
export const goverment_of_tamil_nadu_link = "https://www.tn.gov.in/";
export const tamil_nadu_physical_education_and_sports_university_link =
  "https://www.tnpesu.org/";
